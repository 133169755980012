import type { ReactNode } from 'react';
import { useMemo, useState } from 'react';
import cn from 'classnames';
import type {
  ColumnDef,
  PaginationState,
  Row,
  RowData,
  RowSelectionState,
  SortingState,
  TableMeta,
} from '@tanstack/react-table';
import {
  flexRender,
  functionalUpdate,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import type { TestProps } from '@els/biomed-ui';
import { Icon, Progress } from '@els/biomed-ui';

import { Down, Up } from 'assets/icons';
import PagePanel from 'components/PagePanel';
import type { RowActionProps, SelectionScope } from './Columns';
import { createIndexColumn } from './Columns';
import { createRowActionsColumn, createSelectionColumn } from './Columns';
import type {
  GlobalActionDefinition,
  RenderGlobalActionsProps,
  RowFragmentProps,
} from './Fragments';
import { GlobalActionsFragment, RowFragment } from './Fragments';
import type { RenderFunction } from './utils';
import { composeOverridableRenderer } from './utils';

import styles from './Table.module.scss';

// Extending interface required to use Meta
// https://tanstack.com/table/v8/docs/api/core/column-def#meta
declare module '@tanstack/table-core' {
  interface ColumnMeta<TData extends RowData, TValue> extends TestProps {
    /** className to be applied to the whole column */
    className?: string;
    /** helper function used to determine if we need to disable global action if row action is disabled */
    getDisabledRowActionTags?: (item: TData, value?: TValue) => string[];
  }

  interface TableMeta<TData extends RowData> {
    // TODO: actually we can get rid of "onDataUpdate" and use "onAction" for everything.
    onDataUpdate: (item: TData, columnId: string, patch: Partial<TData>) => void;
    onAction: (item: TData, actionType: string) => void;
  }
}

type TablePaginationProps = {
  /**
   * True if we need to render pagination component in the footer of the table
   */
  pagination?: boolean;
  /** Specifies controllable pagination index */
  pageIndex?: number;
  /** Specifies default page size, if it is not within pageSizeOptions array it would override it */
  pageSize?: number;
  /** Specifies possible page sizes to be selected from */
  pageSizeOptions?: number[];
  /** onPageChanged is fired after user has selected another page size or page.
   * WARNING: on current implementation if we need to fetch data using this event
   * we need to fetch previous pages as well or implement controllable behavior for the table
   */
  onPageChanged?: (pageNumber: number, pageSize: number) => void;
  /** Total pages count provided, use this if you want to render more pages than in dataset
   * and fetch items from backend only on page changed event.*/
  pageCount?: number;
  /** Enables manual pagination.
   * - True if the data is fetched from server side
   * - False if the data is handled in client side
   */
  manualPagination?: boolean;
};

type TableSortingProps = {
  /**
   * If provided, will force table to use manual (i.e. back-end) sorting.
   */
  sorting?: SortingState;
  onSortingChange?: (sorting: SortingState) => void;
  /**
   * Initial sorting for the client-side sorting behavior.
   */
  initialSorting?: SortingState;
};

type TableSelectionProps<TData> = {
  /** Selection algorithm
   * true or false - to enable or disable selection
   * if function is specified - will determine if the row can be selected */
  selection?: boolean | ((item: TData) => boolean);
  /**
   * Selection scope is responsible for algorithm to be used for selecting items in the table
   *
   * page - selection will be applied to all items on the page only
   * all - selection will be applied to all items in the data set
   */
  selectionScope?: SelectionScope;
  /**
   * Selection state (controlled mode)
   */
  selectionState?: RowSelectionState;
  /**
   * onSelectionChange is triggered when there is a change in the row selection model
   */
  onSelectionChange?: (state: RowSelectionState) => void;
  /**
   * Keeps the initial row selection state (uncontrolled mode)
   */
  initialSelectionState?: RowSelectionState;
};

type TableCustomRenderProps<TData> = {
  /** Callback to override the default loading indicator. */
  onRenderLoading?: RenderFunction;
  /** Callback to override the default row rendering. */
  onRenderRow?: RenderFunction<RowFragmentProps<TData>>;
  /** Callback to override the default global actions rendering. */
  onRenderGlobalActions?: RenderFunction<RenderGlobalActionsProps<TData>>;
};

type TableCustomActionProps<TData> = {
  /**
   * If row actions are provided table with add new column at the end of the column definitions.
   * If you want row action to block some global action you need to provide same tag string in action definition
   */
  rowActions?: RowActionProps<TData>[];
  /**
   *  Global table actions rendered in the table header
   *  If you want row action to block some global action you need to provide same tag string in action definition
   */
  globalActions?: GlobalActionDefinition<TData>[];
};

export type TableProps<TData extends RowData, TValue> = {
  /** Column definitions */
  columns: readonly ColumnDef<TData, TValue>[];
  /** Items to be rendered */
  items: TData[];
  /** Indicate if we need to show loader instead of empry table */
  isLoading?: boolean;
  /** The text which will be displayed in case of no data provided */
  emptyText?: ReactNode;
  /** Optional CSS class to be applied to the table root */
  className?: string;
  /** Handler to be called on row click */
  onRowClick?: (index: number, data: TData) => void;
  /** If the columns' headers should be rendered */
  renderHeader?: boolean;
  /** If the row should be highlighted on hover */
  highlightRowOnHover?: boolean;
  /** Specify row id. Specifically useful when selection enabled. */
  getRowId?: (originalRow: TData, index: number, parent?: Row<TData>) => string;
  /** If header should be sticky */
  stickyHeader?: boolean;
  /** If to show row indexes. */
  index?: boolean;
} & Partial<TableMeta<TData>> &
  TablePaginationProps &
  TableSortingProps &
  TableSelectionProps<TData> &
  TableCustomRenderProps<TData> &
  TableCustomActionProps<TData>;

/**
 * React table is headless library. This component adds UI: renders the actual table using provided table instance.
 *
 * TODO:
 *  - add virtualization support
 *  - add infinite loading support
 *  - improve performance
 *  - add unit tests
 */
export const Table = <TData extends object, TValue>({
  selection = false,
  selectionScope = 'page',
  pageCount = undefined,
  pageIndex = 0,
  pageSize = 10,
  pagination = false,
  manualPagination = false,
  pageSizeOptions = [10, 20, 30],
  onPageChanged,
  columns: originalColumnDefinitions,
  items = [],
  globalActions = [],
  isLoading,
  emptyText = 'No data',
  className,
  onRowClick,
  rowActions,
  renderHeader = true,
  highlightRowOnHover = true,
  onRenderGlobalActions,
  onRenderLoading,
  onRenderRow,
  onDataUpdate,
  onAction,
  sorting: sortingFromProps,
  onSortingChange,
  initialSorting = [],
  selectionState: selectionStateFromProps,
  onSelectionChange,
  initialSelectionState = {},
  getRowId,
  stickyHeader,
  index = false,
}: TableProps<TData, TValue>) => {
  const [rowSelection, setRowSelection] = useState(initialSelectionState);
  const [sorting, setSorting] = useState<SortingState>(initialSorting);
  const [paging, setPaging] = useState<PaginationState>({
    pageIndex,
    pageSize,
  });

  const manualSorting = !!sortingFromProps;
  const sortingState = manualSorting ? sortingFromProps : sorting;

  const manualSelection = !!selectionStateFromProps;
  const selectionState = manualSelection ? selectionStateFromProps : rowSelection;

  // here we update column definitions according to selection, and row actions provided
  const columnDefs = useMemo<ColumnDef<TData, TValue>[]>(() => {
    const cols: ColumnDef<TData, TValue>[] = [];

    // Note that row index is shown in selection column if it presents.
    // This is done to allow use to select the row also by clicking teh index.
    if (selection) {
      cols.push(createSelectionColumn(selectionScope, index));
    } else if (index) {
      cols.push(createIndexColumn());
    }

    cols.push(...originalColumnDefinitions);
    if (rowActions?.length) {
      cols.push(createRowActionsColumn(rowActions));
    }

    return cols;
  }, [originalColumnDefinitions, rowActions, selection, selectionScope, index]);

  const table = useReactTable({
    data: items,
    columns: columnDefs,
    state: {
      rowSelection: selectionState,
      sorting: sortingState,
      pagination: paging,
    },
    pageCount,
    enableRowSelection: typeof selection === 'boolean' ? selection : row => selection(row.original),
    onRowSelectionChange: updater => {
      const nextSelection = functionalUpdate(updater, selectionState);
      if (!manualSelection) {
        setRowSelection(nextSelection);
      }
      onSelectionChange?.(nextSelection);
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: updater => {
      const nextSorting = functionalUpdate(updater, sortingState);
      if (!manualSorting) {
        setSorting(nextSorting);
      }
      onSortingChange?.(nextSorting);
    },
    getSortedRowModel: getSortedRowModel(),
    meta: {
      onDataUpdate: (item, columnId, value) => onDataUpdate?.(item, columnId, value),
      onAction: (item, actionType) => onAction?.(item, actionType),
    },
    manualPagination,
    manualSorting,
    enableSortingRemoval: false,
    getRowId,
    // debugTable: true,
    defaultColumn: {
      size: undefined,
    },
  });

  function handlePageChanged(pageNumber: number, pageSize: number) {
    onPageChanged?.(pageNumber, pageSize);
    setPaging({ pageIndex: pageNumber - 1, pageSize });
  }

  const allColumns = table.getAllColumns();
  const { rows } = table.getRowModel();

  const globalActionProps: RenderGlobalActionsProps<TData> = {
    actions: globalActions,
    selectedItems: table.getSelectedRowModel().rows.map(r => r.original),
    table,
  };

  const finalRenderGlobalHeader = composeOverridableRenderer(
    globalActionProps,
    GlobalActionsFragment,
    onRenderGlobalActions
  );

  const loader = composeOverridableRenderer({}, Progress, onRenderLoading);

  return (
    <>
      {!isLoading ? (
        <table
          className={cn(
            styles.table,
            onRowClick && styles.rowClickable,
            highlightRowOnHover && styles.highlightRowOnHover,
            stickyHeader && styles.stickyHeader,
            className
          )}
        >
          <colgroup className={styles.colgroup}>
            {allColumns.map(({ id, columnDef: { size } }) => (
              <col key={id} width={size} />
            ))}
          </colgroup>
          {renderHeader && (
            <thead>
              {!!globalActions.length && finalRenderGlobalHeader}

              {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id} className={styles.columnNames}>
                  {headerGroup.headers.map(header => {
                    return (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        className={cn(
                          header.column.getCanSort() && styles.headerSortable,
                          header.column.columnDef?.meta?.className
                        )}
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {header.isPlaceholder ? null : (
                          <>
                            {flexRender(header.column.columnDef.header, header.getContext())}
                            {{
                              asc: <Icon name={Up} size='xs' className={styles.sortIcon} />,
                              desc: <Icon name={Down} size='xs' className={styles.sortIcon} />,
                            }[header.column.getIsSorted() as string] ?? null}
                          </>
                        )}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
          )}
          <tbody>
            {rows.length ? (
              rows.map(row =>
                composeOverridableRenderer(
                  { row, onRowClick, renderHeader },
                  RowFragment,
                  onRenderRow
                )
              )
            ) : (
              <tr data-testid='table-empty-row' className={styles.empty}>
                <td colSpan={allColumns.length}>{emptyText}</td>
              </tr>
            )}
          </tbody>
          {pagination && (
            <tfoot>
              <tr>
                <td colSpan={allColumns.length}>
                  <PagePanel
                    pageNumber={table.getState().pagination.pageIndex + 1}
                    totalPages={table.getPageCount()}
                    pageSize={paging.pageSize}
                    pageSizeOptions={pageSizeOptions}
                    onPageChanged={handlePageChanged}
                  />
                </td>
              </tr>
            </tfoot>
          )}
        </table>
      ) : (
        <div className={styles.loadingContainer}>{loader}</div>
      )}
    </>
  );
};
