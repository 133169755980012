import type { ComponentProps, ReactNode, VFC } from 'react';
import type { FontSizeProps } from '@els/biomed-ui';
import { LinkButton } from '@els/biomed-ui';
import { Tooltip, TooltipContent } from '@els/biomed-ui';

import { Info } from 'assets/icons';

import styles from './InfoTooltip.module.scss';

interface Props extends FontSizeProps, ComponentProps<typeof Tooltip> {
  content: ReactNode;
  className?: string;
}

const InfoTooltip: VFC<Props> = ({ size = 'sm', className, content, ...otherProps }) => (
  <Tooltip
    offset={({ placement, reference }) => {
      if (placement.includes('-start')) {
        return [reference.width / 2, 16];
      }

      if (placement.includes('-end')) {
        return [-reference.width / 2, 16];
      }

      return [0, 16];
    }}
    interactive
    content={
      <TooltipContent size='sm' width='md' className={styles.content}>
        {content}
      </TooltipContent>
    }
    {...otherProps}
  >
    <LinkButton
      iconRight={Info}
      size={size}
      className={className}
      inline
      colored
      aria-label='information tooltip'
    />
  </Tooltip>
);

export default InfoTooltip;
