import type { Row, RowData } from '@tanstack/react-table';
import { flexRender } from '@tanstack/react-table';

export type RowFragmentProps<TData extends RowData> = {
  row: Row<TData>;
  onRowClick?: (index: number, data: TData) => void;
  renderHeader?: boolean;
};

export const RowFragment = <TData extends RowData>({
  row,
  onRowClick,
  renderHeader,
}: RowFragmentProps<TData>) => (
  <tr
    key={row.id}
    data-testid={`table-row-${row.id}`}
    onClick={() => onRowClick?.(row.index, row.original)}
  >
    {row.getVisibleCells().map(cell => {
      const {
        id,
        column: { columnDef },
      } = cell;
      return (
        <td
          key={id}
          // Apply custom class name from the column config
          className={columnDef.meta?.className}
          data-testid={columnDef.meta?.['data-testid']}
          data-header={
            renderHeader && ['string', 'number'].includes(typeof columnDef.header)
              ? columnDef.header
              : // TODO: do we need to handle "else" case? Seems odd to me atm.
                undefined
          }
        >
          {flexRender(columnDef.cell, cell.getContext())}
        </td>
      );
    })}
  </tr>
);
