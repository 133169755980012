import type { Entry } from './concepts';
import type { PageInfo, PaginatedRequest, ServerEvent, SortedRequest } from './models';

export enum UploadStatus {
  UPLOADING = 'UPLOADING',
  UPLOADED = 'UPLOADED',
  COMPLETED = 'COMPLETED',
  PAUSED = 'PAUSED',
  ANALYSING = 'ANALYSING',
  FAILED = 'FAILED',
}

export interface Project {
  id?: number;
  title: string;
  updatedDate: string;
  status: UploadStatus;
  description?: null | string;
  progressValue?: number;
}

export interface ProjectDetails extends Project {
  id: number;
  documentRowsCount: number;
}

export interface Experiment extends Project {}
export interface ExperimentDetails extends ProjectDetails {}
export interface Network extends Project {}
export interface NetworkDetails extends ProjectDetails {}

export interface ProjectsSSE<T extends Project> extends ServerEvent {
  experimentStatuses: T[];
  terminate: boolean;
}

export enum ProjectActions {
  OPEN_ANALYSIS = 'OPEN_ANALYSIS',
  CANCEL_UPLOAD = 'CANCEL_UPLOAD',
  DELETE_FILE = 'DELETE_FILE',
}

export type ProjectSortedProps = 'title' | 'updateDate' | 'experimentStatus';

export interface GetProjectsRequest extends PaginatedRequest, SortedRequest<ProjectSortedProps> {
  filterByStatus?: UploadStatus | UploadStatus[];
}

export interface UpdateProjectRequest {
  id: number;
  name: string;
  description?: string | null;
}

export interface ProjectInfo<T extends ProjectDetails> {
  experiment: T;
  countMatchedByName: number;
  countMatchedBySynonym: number;
  duplicates: string[];
  unmatched: string[];
  page: PageInfo;
  entries: Entry[];
}

export interface ExperimentInfo extends ProjectInfo<ExperimentDetails> {}
export interface NetworkInfo extends ProjectInfo<NetworkDetails> {}

export interface Workbook<T extends Project> {
  name: string;
  description: string;
  updatedDate: string;
  page: PageInfo;
  experiments: T[];
}
