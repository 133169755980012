import type { VFC } from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useMeasure } from 'react-use';
import cn from 'classnames';
import type { Simulation, ZoomBehavior } from 'd3';
import {
  drag,
  event,
  forceCenter,
  forceCollide,
  forceLink,
  forceManyBody,
  forceRadial,
  forceSimulation,
  scaleLinear,
  select,
  zoom,
  zoomIdentity,
} from 'd3';
import type { DraggedElementBaseType } from 'd3-drag';
import type { SimulationNodeDatum } from 'd3-force';
import { Tooltip, TooltipContent } from '@els/biomed-ui';
import { useId, useStableCallback } from '@els/biomed-ui/utils/hooks';

import Zoom from 'components/Zoom';
import type { Entry } from 'utils/models';
import type { NetworkFacets } from '../../services/facets';
import { checkConcept, checkFilteredNetworkRelation } from '../../services/facets';
import type { ConceptNode, NetworkRelation, RelationLink } from '../../services/models';
import { formatData } from '../../services/models';
import { RelationData } from '../RelationData';
import { chartOptions } from './constants';
import { calculateCirclesIntersections, calculateConnectionCircle } from './utils';

import styles from './NetworkChart.module.scss';

const elements = {
  nodeContainer: 'node-container',
  node: 'node',
  linkContainer: 'link-container',
  focusRing: 'focus-ring',
};
const dataId = 'data-id';
const byDataId = (elementId: string) => `[${dataId}="${elementId}"]`;

interface Props {
  selectedEntries?: Entry[];
  relations: NetworkRelation[];
  facets?: NetworkFacets;
  selectedConcept?: string;
  filteredRelations?: NetworkRelation[];
  onSelectConcept?: (urn: string) => void;
  width?: number;
  height?: number;
  className?: string;
}

export const NetworkChart: VFC<Props> = ({
  selectedEntries,
  relations,
  facets,
  filteredRelations,
  selectedConcept,
  onSelectConcept,
  className,
}) => {
  const [rootRef, { width, height }] = useMeasure<HTMLDivElement>();
  const id = useId(undefined, id => `network-chart-${id}`);
  const arrowId = `${id}-arrow`;
  const ref = useRef<SVGSVGElement>(null);
  const containerRef = useRef<SVGGElement>(null);

  const data = useMemo(() => {
    const data = formatData(relations);
    // If some nodes do not have relations they are missing in back-end response.
    // Need to return them to render nodes.
    if (selectedEntries) {
      for (const entry of selectedEntries) {
        if (!data.nodes.some(node => node.urn === entry.concept.urn)) {
          data.nodes.push({ ...entry.concept, links: [] });
        }
      }
    }
    return data;
  }, [selectedEntries, relations]);

  const [selectedLink, setSelectedLink] = useState<[element: SVGElement, link: RelationLink]>();

  const toggleConceptNode = useStableCallback(onSelectConcept);

  const simulationRef = useRef<Simulation<ConceptNode, RelationLink>>();
  const zoomRef = useRef<ZoomBehavior<SVGSVGElement, unknown>>();

  const [defaultZoom, setDefaultZoom] = useState(100);
  const [zoomValue, setZoomValue] = useState(100);

  useEffect(() => {
    // Reset simulation when data changes.
    // Important to not reset it when e.g. facets changed, because it will cause re-positioning of nodes.
    simulationRef.current = undefined;
    data.nodes.forEach(node => {
      node.x = undefined;
      node.y = undefined;
    });
  }, [data]);

  useEffect(() => {
    if (!width || !height || !ref.current) return;

    const sizeScale = scaleLinear()
      .domain([data.minLinks, data.maxLinks])
      .rangeRound(chartOptions.sizeScale)
      .clamp(true);

    const distanceScale = scaleLinear()
      .domain([data.maxCommonLocalizations, 0])
      .rangeRound(chartOptions.lengthScale)
      .clamp(true);

    const forceScale = scaleLinear()
      .domain([data.minLinks, data.maxLinks])
      // Nodes with more links have weaker repulsion.
      .rangeRound(chartOptions.repulsionScale)
      .clamp(true);

    const svg = select(ref.current);
    const container = select(containerRef.current);

    // Zoom functionality
    const zoomFn = (zoomRef.current = zoom<SVGSVGElement, unknown>()
      .scaleExtent(chartOptions.zoomScale)
      .filter(() => event.ctrlKey || !(event instanceof WheelEvent))
      .on('zoom', () => {
        console.debug('zoom', event.transform);
        container.attr('transform', event.transform);
        setZoomValue(Math.floor(event.transform.k * 100));
      }));
    svg.call(zoomFn);

    // Initialize links
    const links = container
      .selectAll<SVGGElement, RelationLink>(byDataId(elements.linkContainer))
      .data(data.links, d => d.urn)
      .join(
        enter => {
          const g = enter
            .append('g')
            .attr(dataId, elements.linkContainer)
            .attr('data-testid', 'link')
            .attr('class', d => `${styles.linkContainer} ${styles[d.effect]}`);

          g.append('path').attr('class', styles.link).attr('marker-end', `url(#${arrowId})`);
          // Will be positioned in the middle of link path and used as reference for link tooltip.
          g.append('circle').attr('r', 1).style('opacity', 0);

          return g;
        },
        update => update,
        exit => exit.remove()
      )
      .style('opacity', d =>
        !checkFilteredNetworkRelation(d, filteredRelations) ||
        (selectedConcept &&
          ![getConceptUrn(d.source), getConceptUrn(d.target)].includes(selectedConcept))
          ? 0.25
          : 1
      )
      .on('click', function (d) {
        // Prevent side-panel close when clicking link.
        event.stopPropagation();

        const middle = select(this).select<SVGCircleElement>('circle').node();
        if (middle) {
          setSelectedLink([middle, d]);
        }
      });

    // Initialize nodes
    const nodes = container
      .selectAll<SVGGElement, ConceptNode>(byDataId(elements.nodeContainer))
      .data(data.nodes, d => d.urn)
      .join(
        enter => {
          const g = enter
            .append('g')
            // Allow to focus on node.
            .attr('tabindex', 0)
            .attr('role', 'button')
            .attr(dataId, elements.nodeContainer)
            .attr('class', styles.nodeContainer);

          g.append('circle').attr(dataId, elements.focusRing).attr('class', styles.focusRing);
          g.append('circle')
            .attr(dataId, elements.node)
            .attr('class', d => `${styles.node} ${styles[d.type]}`);

          // Initialize labels for nodes
          g.append('text')
            .attr('text-anchor', 'middle')
            .attr('alignment-baseline', 'central')
            .style('font-size', 14)
            .text(d => d.name)
            .attr('data-testid', d => `node-${d.name}`);

          g.append('text')
            .attr('y', 14)
            .style('font-size', 8)
            .attr('text-anchor', 'middle')
            .text(d => d.primaryCellLocalization?.join('; ') ?? '')
            .attr('class', styles.localizations)
            .attr('data-testid', d => `localization-${d.primaryCellLocalization}`);

          return g;
        },
        update => update,
        exit => exit.remove()
      )
      .each(function (d) {
        const g = select(this);
        const radius = getSize(d);
        g.select(byDataId(elements.focusRing))
          .attr('r', radius + 4)
          .classed(styles.visible, d.urn === selectedConcept);
        g.select(byDataId(elements.node)).attr('r', radius);
      })
      .style('opacity', d =>
        // Node is inactive if:
        // 1. it has links and all of them are inactive OR
        (d.links.length &&
          d.links.every(l => !checkFilteredNetworkRelation(l, filteredRelations))) ||
        // 2. it has no links and does not correspond to the selected filters OR
        (!d.links.length && !checkConcept(d, facets)) ||
        // 3. there is selected node and it's not connected to this node.
        (selectedConcept &&
          d.urn !== selectedConcept &&
          d.links.every(
            l => ![getConceptUrn(l.source), getConceptUrn(l.target)].includes(selectedConcept)
          ))
          ? 0.25
          : 1
      )
      // Keep nodes always on top.
      .raise()
      .on('click keypress', d => {
        event.stopPropagation();

        if (!(event instanceof KeyboardEvent) || event.key === 'Enter') {
          toggleConceptNode?.(d.urn);
        }
      })
      .call(draggable());

    // Do not rerun simulation when focus node (effect runs).
    // It's reset in separate effect above when data changes.
    if (!simulationRef.current) {
      // List the forces we want to apply on the network.
      simulationRef.current = forceSimulation(data.nodes)
        .force(
          'link',
          // Provides links between nodes.
          forceLink<ConceptNode, RelationLink>(data.links)
            .distance(d => distanceScale(d.commonLocalizations)!)
            .id(d => d.urn)
            .strength(1)
        )
        // Adds repulsion between nodes.
        .force('charge', forceManyBody<ConceptNode>().strength(getRepulsion))
        // Attracts nodes to the center of the svg area.
        .force('center', forceCenter(width / 2, height / 2))
        // Do not overlap nodes
        .force('collide', forceCollide(getSize).strength(10))
        .force(
          'radial',
          forceRadial(Math.min(width, height) / 2, width / 2, height / 2).strength(3)
        )
        .tick(500)
        .on('tick', position)
        .on('end', () => {
          // Calculate initial zoom value to fit all nodes.
          const box = container.node()!.getBBox();
          const scale = Math.min(width / box.width, height / box.height);
          const zoom = Math.floor(scale * 100);
          setZoomValue(zoom);
          setDefaultZoom(zoom);
          svg.call(
            zoomFn.transform,
            zoomIdentity
              .translate(width / 2, height / 2)
              .scale(scale)
              .translate(-box.x - box.width / 2, -box.y - box.height / 2)
          );
        });
    }

    // Drag functions
    function draggable<
      GElement extends DraggedElementBaseType,
      Datum extends SimulationNodeDatum,
    >() {
      return drag<GElement, Datum>().on('drag', d => {
        d.x = event.x;
        d.y = event.y;

        position();
      });
    }

    function getSize(d: ConceptNode) {
      return d.links.length ? sizeScale(d.links.length)! : chartOptions.sizeScale[0];
    }

    function getRepulsion(d: ConceptNode) {
      return d.links.length ? forceScale(d.links.length)! : chartOptions.repulsionScale[1] / 15;
    }

    function getConceptUrn(data: ConceptNode | string | number): string {
      return typeof data === 'object' ? data.urn : String(data);
    }

    // Updating the nodes position.
    function position() {
      links.each(function (d) {
        if (typeof d.source !== 'object' || typeof d.target !== 'object') return;

        const source = d.source;
        const target = d.target;

        const sourceX = source.x!;
        const sourceY = source.y!;
        const sourceRadius = getSize(source);
        const targetX = target.x!;
        const targetY = target.y!;
        const targetRadius = getSize(target);

        const sign = d.clockwise ? 1 : 0;

        const min = 0.05;
        const max = 0.2;
        const steepness = (max - min) * (d.order / d.total) + min;

        const [r, ...circles] = calculateConnectionCircle(
          [sourceX, sourceY],
          [targetX, targetY],
          steepness
        );
        const [[cx, cy], [mx, my]] = circles[sign ? 0 : 1];

        const [x1, y1] = calculateCirclesIntersections(
          [sourceRadius, [sourceX, sourceY]],
          [r, [cx, cy]]
        )[sign ? 1 : 0];
        const [x2, y2] = calculateCirclesIntersections(
          [targetRadius, [targetX, targetY]],
          [r, [cx, cy]]
        )[sign ? 0 : 1];

        const g = select(this);

        g.select('path').attr(
          'd',
          // Need to render 2 arcs (have a midpoint) to properly align arrow for directed relations.
          `
            M${x1},${y1}
            A${r},${r} 0 0,${sign} ${mx},${my}
            A${r},${r} 0 0,${sign} ${x2},${y2}
          `
        );
        // Will be used as reference for link tooltip.
        g.select('circle').attr('transform', `translate(${mx}, ${my})`);
      });

      nodes.attr('transform', d => `translate(${d.x}, ${d.y})`);
    }
  }, [data, facets, selectedConcept, toggleConceptNode, width, height, arrowId, filteredRelations]);

  function setZoom(zoomValue: number) {
    setZoomValue(zoomValue);

    if (!ref.current || !zoomRef.current) return;

    const svg = select(ref.current);
    const container = select(containerRef.current);

    const box = container.node()!.getBBox();
    const scale = zoomValue / 100;

    svg
      .transition()
      .duration(300)
      .call(
        zoomRef.current.transform,
        zoomIdentity
          .translate(width / 2, height / 2)
          .scale(scale)
          .translate(-box.x - box.width / 2, -box.y - box.height / 2)
      );
  }

  return (
    <div data-testid='network-diagram' ref={rootRef} className={cn(styles.root, className)}>
      <svg ref={ref} width={width} height={height}>
        <defs>
          {/* Template for link arrow */}
          <marker
            id={arrowId}
            viewBox='0 -5 10 10'
            refX={10}
            refY={0}
            markerWidth='6'
            markerHeight='6'
            orient='auto'
          >
            <path d='M0,-5L10,0L0,5' className={styles.arrow} />
          </marker>
        </defs>
        <g ref={containerRef} />
      </svg>

      <Zoom
        defaultValue={defaultZoom}
        value={zoomValue}
        onChange={setZoom}
        min={chartOptions.zoomScale[0] * 100}
        max={chartOptions.zoomScale[1] * 100}
        className={styles.zoom}
      />

      {selectedLink && (
        <Tooltip
          visible
          interactive
          appendTo={document.body}
          reference={selectedLink[0]}
          content={() => (
            <TooltipContent size='sm' className={styles.linkTooltip}>
              <RelationData link={selectedLink[1]} />
            </TooltipContent>
          )}
          onClickOutside={() => setSelectedLink(undefined)}
        />
      )}
    </div>
  );
};
