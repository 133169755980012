import { useCallback } from 'react';
import { useState } from 'react';

export interface AnimatedContainerProps {
  isOpen: boolean;
  onOpened: () => void;
  onClose: () => void;
  onClosed: () => void;
}

interface State<T> {
  // Controls is panel should be opened. Based on this prop open/close animation starts.
  isOpen: boolean;
  // True only when panel is opened and no in-progress animation, i.e.
  // - becomes TRUE only after open animation finished
  // - become FALSE as soon as close animation starts
  isOpened: boolean;
  // Optional data, necessary to render the panel. Resets only when
  // close animation ends, allowing to show panel content during it.
  data?: T;
}

interface Result<T> extends State<T> {
  open: (data?: T) => void;
  close: () => void;
  setData: (data: T) => void;
  containerProps: AnimatedContainerProps;
}

export function useAnimatedContainer<T>(): Result<T> {
  const [state, setState] = useState<State<T>>({ isOpen: false, isOpened: false });

  const open = useCallback((data?: T) => setState({ isOpen: true, isOpened: false, data }), []);
  // When closing, do not reset data - it will be reset only on close end
  // to avoid showing empty panel during close animation.
  const close = useCallback(
    () => setState(prev => ({ ...prev, isOpen: false, isOpened: false })),
    []
  );

  const setData = useCallback((data: T) => {
    setState(prev => ({ ...prev, data }));
  }, []);

  return {
    ...state,
    open,
    close,
    setData,
    containerProps: {
      isOpen: state.isOpen,
      onOpened: () => setState(prev => ({ ...prev, isOpened: true })),
      onClose: close,
      onClosed: () => setState({ isOpen: false, isOpened: false }),
    },
  };
}
