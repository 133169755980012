import { useState } from 'react';
import type { RowSelectionState } from '@tanstack/react-table';

import { networkDataKey } from 'constants/constant';
import { getSessionData, setSessionData } from 'utils/storage';

export interface NetworkSessionData {
  id: number;
  selection?: RowSelectionState;
}

export function useNetworkSessionData(networkId: number) {
  const [network, setNetwork] = useState(() => getNetworkSessionData(networkId));

  if (network && network.id !== networkId) {
    setNetwork(getNetworkSessionData(networkId));
  }

  return network;
}

export function getNetworkSessionData(networkId: number): NetworkSessionData | null {
  const data = getSessionData<NetworkSessionData>(networkDataKey);
  return data?.id === networkId ? data : null;
}

export function setNetworkSessionData(data: NetworkSessionData): void {
  setSessionData(networkDataKey, data);
}
