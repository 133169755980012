import type { VFC } from 'react';
import { Link } from '@els/biomed-ui';

import type { Concept } from 'utils/models';
import DList from '../../DescriptionList';

import styles from './ConceptDetail.module.scss';

interface Props {
  concept: Concept;
}

/**
 * Displays concept information from database.
 *
 * The list of props are back-end driven in terms of we're just showing all
 * not-empty information from response.
 * And it's back-end who fills the props based on concept type.
 */
const ConceptDetails: VFC<Props> = ({ concept }) => {
  return (
    <DList data-testid='concept-details' className={styles.root}>
      <DList.Item title='Name'>
        <Link
          data-testid='concept-name'
          size='sm'
          href={`/search?name=${concept.name}&urn=${concept.urn}`}
          colored
          underlined
          external
        >
          {concept.name}
        </Link>
      </DList.Item>
      <DList.Item data-testid='concept-description' title='Description'>
        {concept.description}
      </DList.Item>
      <DList.Item data-testid='concept-mesh-heading' title='MeSH heading'>
        {concept.meSHHeading?.join('; ')}
      </DList.Item>
      <DList.Item data-testid='concept-primary-cell-localization' title='Primary cell localization'>
        {concept.primaryCellLocalization?.join(', ')}
      </DList.Item>
      <DList.Item data-testid='concept-ec-number' title='Enzyme commission number'>
        {concept.ecNumber?.join('; ')}
      </DList.Item>
      <DList.Item data-testid='concept-molecular-weight' title='Molecular weight'>
        {concept.molecularWeight?.join('; ')}
      </DList.Item>
      <DList.Item data-testid='concept-molecular-formula' title='Molecular formula'>
        {concept.molecularFormula?.join('; ')}
      </DList.Item>
      <DList.Item data-testid='concept-organism' title='Organism'>
        {concept.organism?.join('; ')}
      </DList.Item>
      {concept.type === 'Protein' && (
        <DList.Item data-testid='concept-protein-class' title='Protein class'>
          {concept.termClass?.join(', ') || 'Protein'}
        </DList.Item>
      )}
      <DList.Item data-testid='concept-references' title='Total references' inline>
        {(concept.totalRelations ?? 0).toLocaleString()}
      </DList.Item>
    </DList>
  );
};

export default ConceptDetails;
