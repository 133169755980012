import type { FC } from 'react';
import { useMemo } from 'react';
import { Link } from '@els/biomed-ui';
import { pluralize } from '@els/biomed-ui/utils/format';

import { getRelDesc } from 'utils/Result';
import HighlightText from '../../HighlightText';
import type { RelationItem, RelationSearchHit } from '../../RelationInfoPanel';

import styles from './RelationInfoHeader.module.scss';

const formQueryString = (relation: RelationItem) => {
  const params = new URLSearchParams({
    query: relation.target?.name,
    urn: relation.target?.urn,
    entityName: relation.source?.name,
    entityType: relation.source?.type,
    type: relation.type,
    effect: relation.effect,
  });

  return params.toString();
};

interface Props extends RelationSearchHit {
  index: number;
  highlightTokens?: boolean;
  isHighlightingEnabled?: boolean;
}

const RelationInfoHeader: FC<Props> = ({
  index,
  relation,
  children,
  highlightTokens = false,
  isHighlightingEnabled = true,
}) => {
  const queryString = useMemo(() => formQueryString(relation), [relation]);
  const relationTitle = `Relation #${index + 1}`;

  const relationDesc = useMemo(
    () => getRelDesc(relation.source.name, relation.effect, relation.type, relation.target.name),
    [relation]
  );

  const highlightedSnippetClass =
    isHighlightingEnabled && relation.freeTextSearch?.isRelationContainsTokens
      ? styles.highlightedTitle
      : '';

  return (
    <div>
      {children && (
        <div className={`${styles.expander} ${highlightedSnippetClass}`}>{children}</div>
      )}

      <p data-testid={`relation-info-title-${index}`} className={`${styles.title}`}>
        {relationTitle}
      </p>
      <p data-testid={`relation-info-description-${index}`} className={styles.description}>
        {highlightTokens ? <HighlightText text={relationDesc} /> : <span>{relationDesc}</span>}
      </p>

      <Link
        data-testid={`relation-info-reference-${index}`}
        href={`/reference?${queryString}`}
        size='sm'
        colored
        external
      >
        {pluralize('Supported by (?#) reference(?s)', relation.referenceCount)}
      </Link>
    </div>
  );
};
export default RelationInfoHeader;
