import { urlConfig } from 'constants/UrlConfig';
import { get } from 'utils/axios';
import { UrlBuilder } from 'utils/queryBuilder';

export interface QueryRequest {
  term: string;
  limit?: number;
  entityType?: string;
}

export interface SuggestionsResponse {
  synonym: string;
  useTerm: string;
  termUrn: string;
  hits: number;
  type: string;
}

export const autoCompleteSuggestions = ({ term, limit = 10, entityType }: QueryRequest) => {
  const urlBuilder = new UrlBuilder(urlConfig.autoCompleteUrl).appendParamMap({
    term,
    limit,
    entityType,
  });
  return get<SuggestionsResponse[]>(urlBuilder.getUrl());
};
