import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import type { PaginationState } from '@tanstack/react-table';

import { convertFacetsToRequestedFacets } from 'utils/facets';
import type {
  Entity,
  FreeText,
  GetArticlesRequest,
  Operator,
  ResultList,
  SavedFilters,
} from 'utils/models';
import { fetchNetworkArticles } from '../api';
import { networksQueries } from './queries';

interface NetworkFetchArgs {
  entity: Entity[];
  filters: SavedFilters | null;
  pagination: PaginationState;
  searchTerms: string[];
  operator: Operator;
}

export function useFetchNetworkArticles({
  entity = [],
  filters = null,
  pagination,
  searchTerms = [],
  operator,
}: NetworkFetchArgs) {
  const lastSuccessfulResponseRef = useRef<ResultList | null>(null);
  const [isPreviousResponse, setIsPreviousResponse] = useState(false);

  const request = useMemo<GetArticlesRequest | undefined>(() => {
    if (!entity?.length) {
      return;
    }
    const requestedFacets = convertFacetsToRequestedFacets(filters || null, null);
    let freeTermTokens: FreeText | undefined;
    if (searchTerms.length) {
      freeTermTokens = {
        tokens: searchTerms,
        operator,
      };
    }

    return {
      page: { page: pagination.pageIndex, size: pagination.pageSize },
      searchParameters: {
        entity,
        facets: {
          ...requestedFacets,
          freeTextSearch: freeTermTokens,
        },
      },
    };
  }, [entity, filters, pagination, searchTerms, operator]);

  const { data, isFetching, error } = useQuery({
    queryKey: networksQueries.articles(request),
    queryFn: async () => {
      if (!request) {
        return null;
      }
      return fetchNetworkArticles(request);
    },
    // Keeping prev data to not show blank screen when applying sorting or changing page.
    keepPreviousData: true,
  });

  const resetPrevSuccessResponse = useCallback(() => {
    setIsPreviousResponse(false);
    lastSuccessfulResponseRef.current = null;
  }, []);

  useEffect(() => {
    if (data?.articles?.length) {
      lastSuccessfulResponseRef.current = data;
      setIsPreviousResponse(false);
    } else if (searchTerms.length > 0 && !data?.articles && lastSuccessfulResponseRef.current) {
      setIsPreviousResponse(true);
    }
  }, [data, searchTerms.length]);

  const resultData =
    searchTerms.length > 0 && !data?.articles ? lastSuccessfulResponseRef.current : data;

  return { data: resultData, isFetching, error, isPreviousResponse, resetPrevSuccessResponse };
}
