import { type FunctionComponent } from 'react';
import cn from 'classnames';
import { Col, Grid, Link, LinkButton, Row } from '@els/biomed-ui';

import Footer from 'assets/Images/footer.svg';
import { elsevierLink, privacyPolicy, termsAndConditions, useOfCookies } from 'constants/constant';

import styles from './LeydenFooter.module.scss';

const LeydenFooter: FunctionComponent = () => {
  const oneTrustSettings = 'ot-sdk-show-settings';
  return (
    <Grid fluid>
      <Row className={styles.leydenFooter} data-testid='leyden-footer'>
        <Col xs={24} className={styles.footerContainer}>
          <div className={styles.logo}>
            <a href={elsevierLink} aria-label='elsevier-link'>
              <img src={Footer} alt='footer' data-testid='footer-image' />
            </a>
          </div>
          <div className={styles.footerContent}>
            <div className={styles.footerRow}>
              <div className={styles.footerTextContainer}>
                <div className={styles.cookieText}>
                  <p className={styles.footerText} data-testid='footer-text'>
                    We use cookies to help provide and enhance our service and tailor content. By
                    continuing you agree to the&nbsp;
                  </p>

                  <Link
                    href={useOfCookies}
                    className={styles.useofCookies}
                    aria-label='use-of-cookies'
                    data-testid='use-of-cookies'
                    target='_blank'
                  >
                    use of cookies.
                  </Link>
                </div>
                <div className={styles.footerBottomRow}>
                  <p className={styles.footerText}>
                    Copyright © 2025 Elsevier B.V., its licensors, and contributors. All rights are
                    reserved, including those for text and data mining, AI training, and similar
                    technologies.
                  </p>
                </div>
              </div>

              <div className={styles.linkContainer}>
                <LinkButton
                  className={cn(styles.linkButton, styles.cookieSettings, oneTrustSettings)}
                >
                  Cookie Settings
                </LinkButton>

                <Link
                  className={styles.linkButton}
                  href={termsAndConditions}
                  aria-label='Terms-and-conditions'
                  data-testid='footer-terms-and-conditions'
                  target='_blank'
                >
                  Terms & conditions
                </Link>
                <Link
                  className={`${styles.linkButton} ${styles.privacyButton}`}
                  href={privacyPolicy}
                  aria-label='privacy-policy'
                  data-testid='footer-privacy-policy'
                  target='_blank'
                >
                  Privacy
                </Link>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Grid>
  );
};

export default LeydenFooter;
