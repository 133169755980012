import type { VFC } from 'react';
import cn from 'classnames';
import { Button } from '@els/biomed-ui';

import { Expand, ZoomIn, ZoomOut } from 'assets/icons';

import styles from './Zoom.module.scss';

interface Props {
  /** default value */
  defaultValue?: number;
  /** value */
  value?: number;
  /** callback on change */
  onChange?: (value: number) => void;
  /** step value on zoom in/out */
  step?: number;
  /** minimum value, default is 0 */
  min?: number;
  /** maximum value, default is max integer */
  max?: number;
  /** optional class name for the container */
  className?: string;
}

const Zoom: VFC<Props> = ({
  defaultValue = 100,
  value = 100,
  onChange,
  step = 10,
  min = 0,
  max = Number.MAX_VALUE,
  className,
}) => {
  function changeScale(scale: number) {
    onChange?.(Math.max(min, Math.min(scale, max)));
  }

  return (
    <div className={cn(styles.root, className)}>
      <Button
        variant='secondary'
        title='Full size'
        className={cn(styles.reset, value !== defaultValue && styles.visible)}
        onClick={() => changeScale(defaultValue)}
      >
        <Expand />
      </Button>
      <Button title={`Zoom in ${step}%`} onClick={() => changeScale(value + step)}>
        <ZoomIn />
      </Button>
      <Button title={`Zoom out ${step}%`} onClick={() => changeScale(value - step)}>
        <ZoomOut />
      </Button>
    </div>
  );
};

export default Zoom;
