import type { FunctionComponent } from 'react';
import { useLocation } from 'react-router-dom';
import { ToastContainer } from '@els/biomed-ui';

import Footer from 'containers/Footer';
import Header from 'containers/Header';
import LeydenFooter from 'containers/LeydenFooter';
import { MessageBoxProvider } from 'containers/MessageBox';

import styles from './Layout.module.scss';

const Layout: FunctionComponent = ({ children }) => {
  const { pathname } = useLocation();
  return (
    <div className={styles.elsNbsLayout}>
      <MessageBoxProvider>
        <ToastContainer />
        <div className={styles.headerHolder}>
          <Header />
        </div>
        <div className={styles.mainContent}>{children}</div>
        <div className={styles.footerHolder}>
          {pathname === '/login' ? <LeydenFooter /> : <Footer />}
        </div>
      </MessageBoxProvider>
    </div>
  );
};

export default Layout;
