import type { ColumnDef, RowData } from '@tanstack/react-table';

import { getRowIndex } from './getRowIndex';

export function createIndexColumn<TData extends RowData, TValue>(
  options?: Partial<ColumnDef<TData, TValue>>
): ColumnDef<TData, TValue> {
  return {
    header: () => <span className='sr-only'>index</span>,
    id: 'rowNum',
    size: 20,
    cell: cell => `${getRowIndex(cell).toLocaleString()}.`,
    enableSorting: false,
    ...options,
  };
}
