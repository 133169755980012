import type { ColumnDef, RowData } from '@tanstack/react-table';
import { Checkbox } from '@els/biomed-ui';

import { getRowIndex } from './getRowIndex';
import { PredefinedColumnIds } from './PredefinedColumnIds';

/**
 * Selection scope is responsible for algorithm to be used for selecting items in the table
 *
 * page - selection will be applied to all items on the page only
 * all - selection will be applied to all items in the data set
 */
export type SelectionScope = 'page' | 'all';

export function createSelectionColumn<TData extends RowData, TValue>(
  selectionScope: SelectionScope,
  showIndex = false,
  options?: Partial<ColumnDef<TData, TValue>>
): ColumnDef<TData, TValue> {
  return {
    id: PredefinedColumnIds.Selection,
    size: 70,
    header: ({ table }) => (
      <Checkbox
        className='d-flex'
        checked={
          selectionScope === 'all' ? table.getIsAllRowsSelected() : table.getIsAllPageRowsSelected()
        }
        indeterminate={
          selectionScope === 'all'
            ? table.getIsSomeRowsSelected()
            : table.getIsSomePageRowsSelected()
        }
        onChange={
          selectionScope === 'all'
            ? table.getToggleAllRowsSelectedHandler()
            : table.getToggleAllPageRowsSelectedHandler()
        }
      >
        <span className='sr-only'>select all rows</span>
      </Checkbox>
    ),
    cell: cell => {
      const { row } = cell;
      return (
        <Checkbox
          className='d-flex'
          checked={row.getIsSelected()}
          disabled={!row.getCanSelect()}
          indeterminate={row.getIsSomeSelected()}
          onChange={row.getToggleSelectedHandler()}
          // Prevent row click when checkbox clicked
          wrapperProps={{ onClick: e => e.stopPropagation() }}
          label={showIndex ? `${getRowIndex(cell).toLocaleString()}.` : undefined}
        />
      );
    },
    ...options,
  };
}
