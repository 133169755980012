import { forwardRef, useState } from 'react';
import { Button } from '@els/biomed-ui';

import { Delete, Search } from 'assets/icons';
import AutocompleteWrapper from 'containers/AutocompleteWrapper';
import { addSuffixInWord } from './utils';

import styles from './FilterSuggestionWrapper.module.scss';

interface Props {
  title: string;
  entityType: string;
  onItemSelect?: (item: string) => void;
  appliedSearchTerm?: string;
  onClear?: () => void;
}

const FilterSuggestionWrapper = forwardRef(
  ({ title, entityType, onItemSelect, appliedSearchTerm = '', onClear }: Props, ref) => {
    const [currentAutocompleteValue, setAutocompleteValue] = useState<string>('');

    const handleOnClear = () => {
      onItemSelect?.('');
      setAutocompleteValue('');
      onClear?.();
    };

    return (
      <div>
        <h3 className={styles.filterSuggestion__title} data-testid='suggestion-wrapper-title'>
          Search for a {addSuffixInWord(title)}
        </h3>
        <div className={styles.filterSuggestion__container}>
          <AutocompleteWrapper
            id={entityType}
            entityType={entityType}
            renderFullSuggestion={false}
            onItemSelect={item => {
              onItemSelect?.(item.name);
            }}
            onValueChange={setAutocompleteValue}
            className={styles.filterSuggestion__autocomplete}
            ref={ref}
            clearAction={false}
          />
          {appliedSearchTerm ? (
            <Button
              title='Clear'
              data-testid={`filterModal-suggest-clear-button`}
              className={styles.filterSuggestion__button}
              iconRight={Delete}
              variant='secondary'
              onClick={handleOnClear}
            >
              Clear
            </Button>
          ) : (
            <Button
              title='Search'
              data-testid={`filterModal-suggest-search-button`}
              className={styles.filterSuggestion__button}
              iconRight={Search}
              disabled={!(currentAutocompleteValue.trim().length > 1)}
              onClick={() => currentAutocompleteValue && onItemSelect?.(currentAutocompleteValue)}
            >
              Search
            </Button>
          )}
        </div>
      </div>
    );
  }
);

export default FilterSuggestionWrapper;
