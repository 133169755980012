import type { VFC } from 'react';

import type { ArticleContent } from 'utils/models';
import HighlightText from '../HighlightText';

import styles from './AbstractPanel.module.scss';

interface Props {
  abstractData: ArticleContent;
  highlightTokens?: boolean;
}

const AbstractPanel: VFC<Props> = ({ abstractData, highlightTokens = false }) => (
  <div data-testid='abstract-panel' className={styles.root}>
    <div data-testid='abstract-panel-text'>
      {abstractData.abstract.length ? (
        abstractData.abstract.map((item, index) => {
          return highlightTokens ? <HighlightText key={index} text={item} /> : <span>{item}</span>;
        })
      ) : (
        <p>No abstract data available</p>
      )}
    </div>
    <footer data-testid='abstract-panel-copyright' className={styles.footer}>
      {abstractData.copyright}
    </footer>
  </div>
);

export default AbstractPanel;
