import { type FC } from 'react';
import cn from 'classnames';

import styles from './Mark.module.scss';

interface Props {
  className?: string;
  text: string;
}

const Mark: FC<Props> = ({ text, className = '' }) => {
  return (
    <mark className={cn(styles.accessibleMark, className)} data-testid={`highlighted-${text}`}>
      {text}
    </mark>
  );
};
export default Mark;
