import type { GetArticlesRequest, GetProjectsRequest, GetRelationsRequest } from 'utils/models';

export const networksQueries = {
  all: ['networks'] as const,
  lists: () => [...networksQueries.all, 'list'] as const,
  list: (request: GetProjectsRequest) => [...networksQueries.lists(), request] as const,
  details: () => [...networksQueries.all, 'detail'] as const,
  detail: (id: number) => [...networksQueries.details(), id] as const,
  allRelations: () => [...networksQueries.all, 'relations'] as const,
  relations: (request: GetRelationsRequest) =>
    [...networksQueries.allRelations(), request] as const,
  allArticles: () => [...networksQueries.all, 'articles'] as const,
  articles: (request?: GetArticlesRequest) => [...networksQueries.allArticles(), request] as const,
};
