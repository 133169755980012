import { useEffect, useMemo } from 'react';
import { Navigate, Outlet, useLocation, useOutletContext } from 'react-router-dom';

import useAuthData from 'hooks/useAuthData';

interface OutletProps {
  isAuthorized?: boolean;
}
function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}
const AuthLayout = () => {
  const { isAuthorized }: OutletProps = useOutletContext();
  const { signinRedirect } = useAuthData();
  const query = useQuery();
  useEffect(() => {
    const loginHint = query.get('login_hint');
    const loginConnection = query.get('connection');
    const loginOrg = query.get('organization');

    if (loginHint || loginConnection || loginOrg) {
      signinRedirect({
        login_hint: loginHint!,
        extraQueryParams: {
          connection: loginConnection!,
          organization: loginOrg!,
        },
      });
    }
  }, [signinRedirect, query]);
  return isAuthorized ? <Outlet /> : <Navigate to='/login' />;
};

export default AuthLayout;
