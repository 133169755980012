import he from 'he';
import { castArray } from 'lodash';

import type { Uploader } from 'components/DropZoneUploader';
import { networkMaxConceptsCount } from 'constants/constant';
import { urlConfig } from 'constants/UrlConfig';
import { get, post, put, remove } from 'utils/axios';
import type {
  GetArticlesRequest,
  GetProjectsRequest,
  GetRelationsRequest,
  Network,
  NetworkInfo,
  ResultList,
  UpdateProjectRequest,
  Workbook,
} from 'utils/models';
import { UrlBuilder } from 'utils/queryBuilder';
import type { NetworkRelationsResponse } from './models';

export const uploadNetwork: Uploader<Network> = (file, options) => {
  const formData = new FormData();
  formData.append('file', file);

  return post<Network>(urlConfig.networkBuilder, formData, options);
};

export async function fetchNetworks({ filterByStatus, ...request }: GetProjectsRequest = {}) {
  const urlBuilder = new UrlBuilder(urlConfig.networkBuilder).appendParamMap({
    ...request,
    filterByStatus: castArray(filterByStatus).join(','),
  });
  const response = await get<Workbook<Network>>(urlBuilder.getUrl());
  response.experiments.forEach(network => {
    network.title = he.decode(network.title);
  });
  return response;
}

export async function fetchNetwork(id: number, pageIndex = 0, pageSize = networkMaxConceptsCount) {
  const urlBuilder = new UrlBuilder(`${urlConfig.networkBuilder}/${id}`).appendParamMap({
    page: pageIndex,
    size: pageSize,
  });
  const response = await get<NetworkInfo>(urlBuilder.getUrl());
  response.experiment.title = he.decode(response.experiment.title);
  return response;
}

export function updateNetwork({ id, name, description }: UpdateProjectRequest) {
  const url = `${urlConfig.networkBuilder}/${id}`;
  return put<Network>(url, { name, description });
}

export function deleteNetwork(id: number) {
  return remove(`${urlConfig.networkBuilder}/${id}`);
}

export async function fetchNetworkRelations(request: GetRelationsRequest) {
  return post<NetworkRelationsResponse>(urlConfig.networkRelations, request);
}

export const fetchNetworkArticles = (request: GetArticlesRequest) => {
  return post<ResultList>(urlConfig.networkArticlesUrl, request);
};
