import type { FunctionComponent } from 'react';
import classNames from 'classnames';
import { Checkbox, Icon, LinkButton } from '@els/biomed-ui';

import { Delete } from 'assets/icons';

import styles from './FilterOption.module.scss';

export interface OptionConfig {
  disabled?: boolean;
  checked?: boolean;
  remove?: boolean;
}
export interface Option extends OptionConfig {
  label: string;
  value?: string | number;
}

type Props = {
  id: string;
  item: Option;
  onCheck: (checked: boolean, option: Option) => void;
  onRemove?: (option: Option) => void;
};

const FilterOption: FunctionComponent<Props> = ({ id, item, onCheck, onRemove }) => {
  const labelId = `label-${id}`;

  return (
    <li className={styles.filterOption} key={`item-${id}`} data-testid={`item-${id}`}>
      <div className={styles.filterRecord}>
        <div className={classNames(styles.filterItem, { 'w-100': !item.value })}>
          <Checkbox
            id={`checkbox-${id}`}
            size='sm'
            checked={item.checked || false}
            className={styles.filterCheck}
            onChange={event => {
              onCheck(event.target.checked, item);
            }}
            data-testid={`checkbox-${id}`}
            aria-labelledby={labelId}
          />
          <div id={labelId} data-testid={labelId} className={styles.filterRecordLabel}>
            {item.label}
          </div>

          {item.remove && (
            <LinkButton
              className={styles.removeBtn}
              data-testid={`cross-${id}`}
              title='Remove'
              iconLeft={<Icon size='xxs' name={Delete} />}
              onClick={() => onRemove?.(item)}
            />
          )}
        </div>
        {item.value && (
          <div data-testid={`value-${id}`} className={styles.filterValue}>
            {item.value.toLocaleString()}
          </div>
        )}
      </div>
    </li>
  );
};

export default FilterOption;
