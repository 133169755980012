import type { ColumnDef } from '@tanstack/react-table';
import { LinkButton } from '@els/biomed-ui';

import { NavigateRight } from 'assets/icons';
import type { Entry } from 'utils/models';
import { ConceptActions } from 'utils/models';
import { MappingStatus } from 'utils/models';

export function createNameColumn<T extends Entry>(
  selectedCount: number,
  matchedCount: number,
  options?: Partial<ColumnDef<T>>
): ColumnDef<T> {
  return {
    header: `Proteins (${[selectedCount, matchedCount].filter(Boolean).join('/')})`,
    accessorKey: 'name',
    cell: ({ row: { original: entry }, table }) => {
      const { name, mappingStatus } = entry;
      const matchedBySynonym = MappingStatus.MATCHED_BY_SYNONYM === mappingStatus;
      return (
        <LinkButton
          data-testid='concept-name'
          iconRight={NavigateRight}
          onClick={e => {
            e.stopPropagation();
            table.options.meta?.onAction(entry, ConceptActions.OPEN_DETAILS);
          }}
        >
          <span className={matchedBySynonym ? 'es-color-theme2' : undefined}>{name}</span>
        </LinkButton>
      );
    },
    ...options,
  };
}
