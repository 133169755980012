import type { FunctionComponent, ReactElement } from 'react';
import { LinkButton } from '@els/biomed-ui';

import { Download, NavigateDown } from 'assets/icons';
import SelectPopup from 'components/SelectPopup';
import { maxExportArticles, maxSelectedArticles } from 'constants/constant';
import { useResultFragmentContext } from '../ResultFragmentContext';
import { EXPORT_OPTIONS } from './services';

import styles from './ExportControl.module.scss';

interface Props {
  selectedArticles: string[];
}

const ExportControl: FunctionComponent<Props> = ({ selectedArticles }: Props): ReactElement => {
  const { isExporting, exportArticles } = useResultFragmentContext();

  const length = selectedArticles.length;

  return (
    <div className={styles.root}>
      <SelectPopup
        options={EXPORT_OPTIONS}
        onClick={value => exportArticles?.(selectedArticles, value)}
        offset={[0, -7]}
        trigger={
          <LinkButton
            size='sm'
            iconLeft={Download}
            iconRight={NavigateDown}
            inline
            data-testid='export-select-control'
            className={styles.trigger}
            disabled={isExporting}
          >
            {isExporting
              ? 'Exporting...'
              : length === 0
                ? `Export (First ${maxExportArticles.toLocaleString()})`
                : length >= maxExportArticles
                  ? `Export selection (${maxSelectedArticles.toLocaleString()} MAX)`
                  : `Export selection (${length.toLocaleString()})`}
          </LinkButton>
        }
      />
    </div>
  );
};

export default ExportControl;
