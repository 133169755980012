import type { VFC } from 'react';
import { isFunction } from 'lodash';
import { Heading, Tag, TagButton, TagGroup } from '@els/biomed-ui';

import { Delete } from 'assets/icons';
import { ActiveFilterOperation } from 'components/ActiveFilterPanel/constants';
import type { Option } from 'components/FilterOption';
import type { SelectionStatus } from 'utils/models';

import styles from './ActiveFilterPanel.module.scss';

export type Category = {
  name: string;
  type: string;
  filters: CategoryValues[];
};
export type CategoryValues = {
  name: string;
  categoryId: string;
  value: string;
  filterOptions?: Option[];
  filterSelection?: string[] | SelectionStatus.ALL;
};

interface Props {
  categoryList: Category[];
  onFilterTagClick?: (
    categoryId: string,
    operation: ActiveFilterOperation,
    updateOptions?: string[]
  ) => void;
  canOpen?: boolean | ((category: Category) => boolean);
}

const ActiveFilterPanel: VFC<Props> = ({
  categoryList,
  onFilterTagClick,
  canOpen = category => category.type === 'conceptFilters',
}) => {
  const renderFilterCategory = (categoryItem: Category) =>
    categoryItem.filters.length > 0 ? (
      <div
        className={styles.filterCategoryRow}
        key={categoryItem.name}
        data-testid={`category-${categoryItem.name.toLowerCase().replace(/\s+/g, '-')}`}
      >
        <div className={styles.filterLabel}>{categoryItem.name}:</div>

        <div className={styles.filterValues}>
          {categoryItem.filters.map(item => {
            const tagLabel = `${item.name} (${item.value})`;
            return (
              <TagGroup
                className={styles.tagPill}
                tagClassName={styles[categoryItem.type]}
                size='xs'
                gap
                container
                key={item.name}
              >
                {(isFunction(canOpen) ? canOpen(categoryItem) : canOpen) ? (
                  <TagButton
                    data-testid={`filter-tag-${item.categoryId}`}
                    onClick={() => {
                      onFilterTagClick?.(item.categoryId, ActiveFilterOperation.OPEN);
                    }}
                  >
                    {tagLabel}
                  </TagButton>
                ) : (
                  <Tag data-testid={`filter-tag-${item.categoryId}`}>{tagLabel}</Tag>
                )}
                <TagButton
                  iconRight={<Delete />}
                  title='Remove'
                  data-testid={`filter-tag-delete-${item.categoryId}`}
                  onClick={() => onFilterTagClick?.(item.categoryId, ActiveFilterOperation.DELETE)}
                />
              </TagGroup>
            );
          })}
        </div>
      </div>
    ) : null;
  return (
    <div data-testid={`active-filter-panel`} className={styles.activeFilterPanelWrapper}>
      <Heading level='h4'>Applied Filters</Heading>
      {categoryList.map(item => renderFilterCategory(item))}
    </div>
  );
};

export default ActiveFilterPanel;
