import type { VFC } from 'react';
import { LinkButton } from '@els/biomed-ui';

import { InsertBulletedList } from 'assets/icons';
import { DEFAULT_LIST } from 'constants/constant';
import type { SelectedArticles } from 'utils/models';
import { useResultFragmentContext } from '../ResultFragmentContext';

export interface Props {
  selectedArticles: SelectedArticles;
}

const SaveArticlesControl: VFC<Props> = ({ selectedArticles }) => {
  const { saveArticles } = useResultFragmentContext();

  const selectedArticlesIds = Object.keys(selectedArticles);

  return (
    <LinkButton
      iconLeft={InsertBulletedList}
      data-testid='save-list-button'
      size='sm'
      inline
      disabled={!selectedArticlesIds.length}
      onClick={() => saveArticles?.(DEFAULT_LIST, selectedArticles)}
    >
      Save to list{' '}
      {selectedArticlesIds.length ? `(${selectedArticlesIds.length.toLocaleString()})` : ''}
    </LinkButton>
  );
};

export default SaveArticlesControl;
