import type { FC } from 'react';
import { useInView } from 'react-intersection-observer';

import ShowMore from '../../ShowMore';

import styles from './ActionsFooter.module.scss';

/**
 * Sticky footer for long tables (e.g. list of concepts).
 * Displays "Scroll to view more" icon-text if there is a space to scroll.
 */
const ActionsFooter: FC = ({ children }) => {
  const { ref, inView: noMoreScroll } = useInView({ initialInView: true });

  return (
    <>
      <div ref={ref} />

      <div className={styles.actionsFooter}>
        <div className={styles.actionsContainer}>
          <ShowMore visible={!noMoreScroll} />

          <div className={styles.actions}>{children}</div>
        </div>
      </div>
    </>
  );
};

export default ActionsFooter;
