/*  
    mapping ignores special characters
    *this function extends it with a prefix or postfix in the form of special characters*
*/
export function expandMatchIndex(
  mapping: number[],
  currentIndex: number,
  direction: 'start' | 'end'
): number {
  if (direction === 'start') {
    //prev index is skipped due to regexp ignorance
    if (currentIndex === 0) {
      return 0;
    } else if (mapping[currentIndex] - 1 > mapping[currentIndex - 1]) {
      const numberOfSkippedChars = mapping[currentIndex] - mapping[currentIndex - 1];
      return mapping[currentIndex] - numberOfSkippedChars;
    }
    return mapping[currentIndex];
  } else if (direction === 'end') {
    //next index is skipped due to regexp ignorance
    if (mapping[currentIndex] + 1 > mapping[currentIndex + 1]) {
      const numberOfSkippedChars = mapping[currentIndex + 1] - mapping[currentIndex];
      return mapping[currentIndex] + numberOfSkippedChars;
    }
    return mapping[currentIndex];
  } else {
    return mapping[currentIndex];
  }
}

/*
    remove special characters from result text and creates mapping for each character
    (skipped characters in mapping array equals special characters)
*/
export function normalizeText(input: string): { normalized: string; mapping: number[] } {
  const mapping = [];
  let normalized = '';

  for (let i = 0; i < input.length; i++) {
    const char = input[i];
    if (/[a-zA-Z0-9\s]/.test(char)) {
      normalized += char.toLowerCase();
      mapping.push(i);
    }
  }

  return { normalized, mapping };
}

export function escapeSpecialChars(term: string): string {
  return term.replace(/[.*+?^${}()|[\]\\]/g, '\\$&').replace(/-/g, '[-\\s]*');
}

/*
Two options for regexp:
- exact match with escaped special characters
- clear core term and post/prefix as any special characters
*/
export function regexpFromSearchTerms(searchTerms: string[]) {
  const regexPatterns = searchTerms.map(term => {
    // Extract core term by removing non-alphanumeric characters
    const coreTerm = term.replace(/[^a-zA-Z0-9\\s]/g, '');

    const escapedTerm = escapeSpecialChars(term);

    return `(${escapedTerm}|([^\\s\\w]*?${coreTerm}[^\\s\\w]*?))`;
  });

  return new RegExp(`${regexPatterns.join('|')}`, 'gi');
}
