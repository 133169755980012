import type { AuthProviderProps } from 'react-oidc-context';
import { WebStorageStateStore } from 'oidc-client-ts';

const onRedirectCallback = () => {
  window.history.replaceState({}, document.title, window.location.pathname);
};

export const oidcConfig: AuthProviderProps = {
  authority: `https://${window.__ENV.REACT_APP_NEOID_DOMAIN}`,
  client_id: window.__ENV.REACT_APP_NEOID_CLIENT_ID,
  /**
   * Adding forward slash(/) to be in sync
   * with keycloak server configuration
   */
  redirect_uri: `${window.location.origin}/`,
  response_type: 'code',
  scope: 'openid profile email',
  automaticSilentRenew: true,
  loadUserInfo: true,
  post_logout_redirect_uri: `${window.location.origin}/`,
  filterProtocolClaims: true,
  onSigninCallback: onRedirectCallback,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  monitorSession: true,
};
