import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { Network, NetworkInfo, Workbook } from 'utils/models';
import { updateNetwork } from '../api';
import { networksQueries } from './queries';

export function useUpdateNetwork() {
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation({
    mutationFn: updateNetwork,
    onSuccess: (updatedNetwork, payload) => {
      queryClient.setQueriesData<Workbook<Network>>(networksQueries.lists(), prev => {
        if (prev) {
          return {
            ...prev,
            experiments: prev.experiments.map(e =>
              e.id === updatedNetwork.id ? updatedNetwork : e
            ),
          };
        }
        return prev;
      });
      queryClient.setQueryData<NetworkInfo>(networksQueries.detail(payload.id), prev => {
        if (prev) {
          if (prev.experiment.id === updatedNetwork.id) {
            return { ...prev, experiment: { ...prev.experiment, ...updatedNetwork } };
          }
        }
        return prev;
      });
    },
  });

  return [isLoading, mutate] as const;
}
