import type { ComponentProps, VFC } from 'react';
import { LinkButton, Sidebar as BiomedSidebar } from '@els/biomed-ui';

import { NavigateLeft } from 'assets/icons';

interface Props extends Omit<ComponentProps<typeof BiomedSidebar>, 'closeButton'> {}

const Sidebar: VFC<Props> = ({ children, ...props }) => (
  <BiomedSidebar data-testid='side-panel' closeButton={false} overlayTheme='transparent' {...props}>
    <div className='mb-4'>
      <LinkButton
        data-testid='closeButton'
        iconLeft={NavigateLeft}
        inline
        size='sm'
        onClick={() => props.onClose()}
      >
        Close
      </LinkButton>
    </div>
    {children}
  </BiomedSidebar>
);

export default Sidebar;
