import type { FunctionComponent } from 'react';
import { Button, ButtonLink, Col, LinkButton, Row } from '@els/biomed-ui';

import { contactSalesTeamUrl, contactUsUrl, learnMoreUrl } from 'constants/constant';

import styles from './TopSection.module.scss';

interface Props {
  isRegistered?: boolean;
  isAuthorized?: boolean;
  onRegister: () => void;
  onSignIn: () => void;
}

const TopSection: FunctionComponent<Props> = ({
  isRegistered,
  isAuthorized,
  onRegister,
  onSignIn,
}) => {
  if (isRegistered && !isAuthorized) {
    return (
      <Row className={`${styles.sectionSpacing} ${styles.backGroundImage} ${styles.topSection}`}>
        <Col xs={24}>
          <h1 className={styles.sectionHeader} data-testid='section-header'>
            Sorry,
            <br />
            it seems you don't have permission <br />
            to access EmBiology.
          </h1>
          <p className={styles.sectionParagraph}>To be able to login, you need:</p>
          <ul className={styles.featureList} data-testid='section-list'>
            <li>An active subscription</li>
            <li>Valid login credentials</li>
            <li>Proper authentication</li>
          </ul>
          <p className={styles.sectionParagraph}>
            Please contact your institution's administrator or send us a<br /> support request.
          </p>
          <div className={styles.container}>
            <ButtonLink
              size='md'
              variant='secondary'
              className={styles.contactButton}
              aria-label=' Contact Support'
              data-testid='contact-support'
              href={contactUsUrl}
              target='_blank'
            >
              Contact Support
            </ButtonLink>
            <ButtonLink
              size='md'
              variant='secondary'
              className={styles.learnMoreButton}
              aria-label='Learn more about EmBiology'
              data-testid='learn-more'
              href={learnMoreUrl}
              target='_blank'
            >
              Learn More about Embiology
            </ButtonLink>
          </div>
        </Col>
      </Row>
    );
  }

  return (
    <Row className={`${styles.sectionSpacing} ${styles.backGroundImage}`} data-testid='top-section'>
      <Col xs={24}>
        <h1 className={styles.sectionHeader} data-testid='section-header'>
          EmBiology,
          <br />
          Deep biological evidence to support <br />
          critical insights and decisions
        </h1>
        <p className={styles.sectionParagraph} data-testid='section-paragraph'>
          Visualize millions of biological relationships to gain a fast
          <br /> understanding of disease biology, how to modulate the <br />
          disease process and how to interpret experimental results.
        </p>
        <div className={styles.container}>
          <ButtonLink
            size='md'
            variant='secondary'
            className={styles.learnMoreButton}
            aria-label='Contact Sales Team'
            href={contactSalesTeamUrl}
            target='_blank'
            data-testid='contact-sales-button'
          >
            Contact Sales Team
          </ButtonLink>
          <Button
            className={`${styles.contactButton} ${styles.registerButton}`}
            aria-label='Register'
            onClick={onRegister}
            data-testid='register-button'
          >
            Register
          </Button>
        </div>
        <div className='d-flex' data-testid='account-message-container'>
          <p className={styles.accountMessage} data-test='account-message'>
            Already have an account?&nbsp;
          </p>
          {!isRegistered && (
            <LinkButton
              size='sm'
              onClick={onSignIn}
              aria-label='Sign in'
              className={styles.signInButton}
              data-testid='sign-in-button'
            >
              Sign in
            </LinkButton>
          )}
        </div>
      </Col>
    </Row>
  );
};

export default TopSection;
