import { type ComponentProps, forwardRef, type FunctionComponent } from 'react';
import { useInView } from 'react-intersection-observer';
import cn from 'classnames';
import { Collapsible, LinkButton } from '@els/biomed-ui';

import { NavigateDown } from 'assets/icons';
import type { Option } from 'components/FilterOption';
import FilterOption from 'components/FilterOption';

import styles from './SelectedFiltersCollapsible.module.scss';

export type SelectedFiltersCollapsibleProps = {
  isAllSelected: boolean;
  selectedFilterOptions: Option[];
  onChange: (checked: boolean, item: Option) => void;
  onCollapseChange?: (e: boolean) => void;
  onScroll: (inView: boolean) => void;
};

const intersectionOptions = {
  threshold: 0,
  initialInView: false,
};

const TriggerComponent: FunctionComponent<ComponentProps<typeof LinkButton>> = props => (
  <LinkButton
    inline
    iconRight={NavigateDown}
    className={styles.collapsibleTitle}
    data-testid='collapseHeader'
    {...props}
  >
    Selected filters
  </LinkButton>
);

const SelectedFiltersCollapsible = forwardRef<HTMLUListElement, SelectedFiltersCollapsibleProps>(
  ({ selectedFilterOptions, onChange, isAllSelected = false, onCollapseChange, onScroll }, ref) => {
    const [intersectionRef] = useInView({
      ...intersectionOptions,
      onChange: inView => onScroll(inView),
    });

    return (
      <Collapsible
        data-testid='trigger-button'
        defaultExpanded
        trigger={TriggerComponent}
        size='sm'
        onChange={onCollapseChange}
      >
        <ul className={cn(styles.selectedFilters, isAllSelected && styles.fullHeight)} ref={ref}>
          {selectedFilterOptions.map(({ label, value, ...rest }, index) => (
            <FilterOption
              item={{ label, value, checked: true, ...rest }}
              id={`${label}-${index}`}
              key={`${label}-selected`}
              onCheck={onChange}
            />
          ))}
          {isAllSelected && (
            <li ref={intersectionRef} className={styles.endOfList}>
              end of list
            </li>
          )}
        </ul>
      </Collapsible>
    );
  }
);

export default SelectedFiltersCollapsible;
