import { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import type { RowSelectionState } from '@tanstack/react-table';

import type { Entry, NetworkInfo } from 'utils/models';

export function useSelectedEntries(networkInfo?: NetworkInfo, selectionState?: RowSelectionState) {
  return useMemo<Entry[]>(() => {
    if (networkInfo && !isEmpty(selectionState)) {
      return networkInfo.entries.filter(e => selectionState[e.rowNum]);
    }
    return [];
  }, [networkInfo, selectionState]);
}
