import type { FC } from 'react';
import { useState } from 'react';
import cn from 'classnames';
import { Heading, Icon, LinkButton } from '@els/biomed-ui';

import { Delete, Info } from 'assets/icons';

import styles from './InfoBanner.module.scss';

type Props = {
  header?: string;
  closable?: boolean;
  icon?: boolean;
  show?: boolean;
  onClose?: () => void;
  className?: string;
};

const InfoBanner: FC<Props> = ({
  header,
  closable = true,
  icon = true,
  show: showFromProps,
  onClose,
  children,
  className,
}) => {
  const [show, setShow] = useState(true);

  const visible = showFromProps === undefined ? show : showFromProps;
  const close = () => {
    if (showFromProps === undefined) {
      setShow(false);
    }
    onClose?.();
  };

  if (!visible) {
    return null;
  }

  return (
    <div role='alert' className={cn(styles.root, className)}>
      <div className={styles.container}>
        {icon && <Icon name={Info} className={styles.icon} size='lg' />}
        <div className={styles.textContainer}>
          {header && (
            <Heading level='h1' className='mb-8 es-color-grey6'>
              {header}
            </Heading>
          )}
          <div className={styles.content}>{children}</div>
        </div>
        {closable && (
          <LinkButton size='sm' iconRight={Delete} onClick={close}>
            Close
          </LinkButton>
        )}
      </div>
    </div>
  );
};

export default InfoBanner;
