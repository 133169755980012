import { useMemo, useRef } from 'react';
import { uniqBy } from 'lodash';

import type { Concept, Entity, Entry, FacetsResponse, SavedFilters } from 'utils/models';
import type { NetworkFilters } from './facets';
import { cleanupFacets, type NetworkFacets } from './facets';
import type { NetworkRelation } from './models';

interface Params {
  activeConceptUrn?: string;
  relations?: NetworkRelation[];
  availableFacets?: FacetsResponse;
  activeFacets?: NetworkFilters;
  selectedEntries?: Entry[];
}

interface Result {
  entity?: Entity[];
  availableFacets?: NetworkFacets;
  facets?: SavedFilters;
}

export function useSearchEntities({
  activeConceptUrn,
  relations,
  availableFacets,
  activeFacets,
}: Params) {
  const activeFacetsRef = useRef(activeFacets);
  activeFacetsRef.current = activeFacets;

  return useMemo<Result>(() => {
    const cleanFacets = cleanupFacets(availableFacets, activeFacetsRef.current);

    if (!relations?.length) {
      return { facets: cleanFacets };
    }

    let entity: Entity[];
    let relatedConcepts: Concept[];

    if (activeConceptUrn) {
      relatedConcepts = uniqBy(
        relations.reduce<Concept[]>((result, r) => {
          if ([r.sourceTerm.urn, r.targetTerm.urn].includes(activeConceptUrn)) {
            result.push(r.sourceTerm);
            result.push(r.targetTerm);
          }
          return result;
        }, []),
        c => c.urn
      );
      entity = relatedConcepts.length ? [{ urn: activeConceptUrn }] : [];
    } else {
      relatedConcepts = uniqBy(
        relations.reduce<Concept[]>((result, r) => {
          result.push(r.sourceTerm);
          result.push(r.targetTerm);
          return result;
        }, []),
        c => c.urn
      );
      entity = relatedConcepts.map<Entity>(c => ({ urn: c.urn }));
    }

    const facets: SavedFilters = {
      ...cleanFacets,
      ...getConceptFacets(availableFacets),
    };
    return { entity, facets };
  }, [availableFacets, relations, activeConceptUrn]);
}

const getConceptFacets = (availableFacets?: FacetsResponse) => {
  return availableFacets?.entityType?.reduce(
    (conceptFacets, { name, entityNames }) => ({ ...conceptFacets, [name]: entityNames }),
    {}
  );
};
