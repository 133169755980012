import type { VFC } from 'react';
import { useState } from 'react';
import type { RequiredKeys } from '@tanstack/react-table';
import type { TestProps } from '@els/biomed-ui';
import { LinkButton, Switch } from '@els/biomed-ui';
import type { Option } from '@els/biomed-ui/components/Select/utils';
import { useId } from '@els/biomed-ui/utils/hooks';

import InfoTooltip from '../InfoTooltip';

import styles from './SwitchList.module.scss';

interface Props extends TestProps {
  id?: string;
  options: Array<RequiredKeys<Option, 'value'>>;
  selectedValues?: Array<string>;
  onChange?: (values: string[], value: string, isChecked: boolean) => void;
  /** The maximum number of visible options before showing "Show more" link */
  maxCollapsedOptions?: number;
}

const SwitchList: VFC<Props> = ({
  id: idFromProps,
  options,
  selectedValues,
  onChange,
  maxCollapsedOptions = 5,
  'data-testid': testId,
}) => {
  const id = useId(idFromProps, id => `switch-list-${id}`);
  testId ||= id;

  const [expanded, expand] = useState(false);

  const expandable = options.length > maxCollapsedOptions;
  const displayedOptions =
    expandable && !expanded ? options.slice(0, maxCollapsedOptions) : options;

  return (
    <ul id={id} className={styles.root} data-testid={testId}>
      {displayedOptions.map(({ value, label, description }, i) => {
        const optionId = `${id}-option-${i}`;
        return (
          <li key={i} className='d-flex justify-content-between align-items-center'>
            <div id={optionId} className='d-flex'>
              {description ? (
                <>
                  {label}
                  <InfoTooltip size='xs' placement='right' content={description} />
                </>
              ) : (
                label
              )}
            </div>
            <Switch
              data-testid={`switch-${value}`}
              aria-labelledby={optionId}
              size='xxs'
              className='pt-1'
              checked={selectedValues?.includes(value) ?? false}
              onChange={e => {
                onChange?.(
                  e.target.checked
                    ? [...(selectedValues ?? []), value]
                    : selectedValues?.filter(v => v !== value) ?? [],
                  value,
                  e.target.checked
                );
              }}
            />
          </li>
        );
      })}
      {expandable && (
        <li>
          <LinkButton
            data-testid={`${testId}-toggle-more`}
            colored
            expanded={expanded}
            size='xs'
            onClick={() => expand(!expanded)}
          >
            {expanded ? 'Show less' : 'Show more'}
          </LinkButton>
        </li>
      )}
    </ul>
  );
};

export default SwitchList;
