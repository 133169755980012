import { useInfiniteQuery } from '@tanstack/react-query';

import type { ApiError } from 'utils/axios';
import { convertFacetsToRequestedFacets } from 'utils/facets';
import type { Entity, FreeText, SavedFilters, SearchParameters } from 'utils/models';
import { fetchArticleRelations } from './api';

interface Props {
  facets?: SavedFilters | null;
  size?: number;
  entity?: Entity[];
  primary?: boolean;
  searchTerms?: string[];
}

export function useArticleRelations(
  articleId: string,
  { size = 10, facets = null, entity, primary, searchTerms = [] }: Props
) {
  const { data, isLoading, error, fetchNextPage } = useInfiniteQuery(
    ['article', articleId, 'relations', { size, facets, entity, primary }],
    async ({ pageParam }) => {
      let searchParameters: SearchParameters | null = null;
      let freeTermTokens: FreeText | undefined;

      if (searchTerms.length) {
        freeTermTokens = {
          tokens: searchTerms,
        };
      }
      if (entity?.length) {
        const convertedFacets = convertFacetsToRequestedFacets(facets, null);
        searchParameters = {
          entity,
          facets: { ...convertedFacets, freeTextSearch: freeTermTokens },
        };
      }

      return fetchArticleRelations(
        articleId,
        {
          searchParameters,
          page: { position: pageParam, size },
        },
        primary
      ).catch((e: ApiError) => Promise.reject(e.message));
    },
    {
      getNextPageParam: lastPage => lastPage.nextToken ?? undefined,
    }
  );

  return [isLoading, data, error, fetchNextPage] as const;
}

export default useArticleRelations;
