import type { FunctionComponent, RefObject } from 'react';
import { useMemo, useState } from 'react';
import { LinkButton } from '@els/biomed-ui';
import { useStableCallback } from '@els/biomed-ui/utils/hooks';

import { Alert, Info, Refresh } from 'assets/icons';
import LoaderOverlay from 'components/LoaderOverlay';
import PagePanel from 'components/PagePanel';
import Sidebar from 'components/Sidebar';
import { useAnimatedContainer } from 'hooks/useAnimatedContainer';
import { useMarginOnScroll } from 'hooks/useMarginOnScroll';
import type {
  Article,
  Entity,
  EntityAndArticleIds,
  Operator,
  SavedFilters,
  SelectedArticles,
} from 'utils/models';
import type { ArticleInfoPanelContent } from './ArticleInfoSidePanel';
import ArticleInfoSidePanel from './ArticleInfoSidePanel';
import QueryModal from './QueryModal/QueryModal';
import type { ListData, ResultFragmentApis } from './ResultFragmentContext';
import { ResultFragmentContext } from './ResultFragmentContext';
import ResultList from './ResultList';

import styles from './ResultFragment.module.scss';

export type ArticlesInfo = {
  articles?: Article[];
  totalPages: number;
  currentPage: number;
  currentPageSize: number;
  totalResultCounts: number;
  articleIdsEntityMapping?: EntityAndArticleIds[];
};

type Props = {
  articlesInfo: ArticlesInfo;
  showInfoBanner?: boolean;
  entity?: Entity;
  facets?: SavedFilters | null;
  maxItemsCount?: number;
  subTitle?: string;
  childRef?: RefObject<HTMLDivElement>;
  loading?: boolean | string;
  clearSearchOptions?: () => void;
  setClearSearchOptions?: (clearSearchOptions: () => void) => void;
  title?: string;
  freeTextTerms?: string[];
  hideFreeTextSearch?: boolean;
  resultType?: string;
  forceResetSelection?: boolean;
  onPageChanged?: (newPageNumber: number, newSize: number) => void;
  onSave?: (listData: ListData, articles: SelectedArticles) => void;
  onRemove?: (articles: SelectedArticles) => void;
  onExport?: (articles: string[], fileType: string) => void;
  onTextSearch?: (searchOptions: string[]) => void;
  onOperatorChange?: (value: string) => void;
  onClear?: () => void;
  isExporting?: boolean;
  freeTextOperator?: Operator;
  hideSearchStructure?: boolean;
  isFocusModeApplied?: boolean;
};

const ResultFragment: FunctionComponent<Props> = ({
  articlesInfo,
  entity,
  facets = null,
  maxItemsCount,
  title,
  showInfoBanner = false,
  loading = false,
  resultType,
  onPageChanged,
  hideFreeTextSearch,
  clearSearchOptions,
  setClearSearchOptions,
  onSave,
  forceResetSelection = false,
  freeTextTerms,
  onRemove,
  onClear,
  onExport,
  onTextSearch,
  isExporting = false,
  childRef,
  onOperatorChange,
  freeTextOperator,
  subTitle,
  hideSearchStructure = false,
  isFocusModeApplied = false,
}: Props) => {
  const {
    articles,
    totalPages,
    currentPage,
    currentPageSize,
    totalResultCounts,
    articleIdsEntityMapping,
  } = articlesInfo;

  const searchQuery = entity?.name;
  const {
    isOpen: isArticleInfoOpened,
    data: articleInfo,
    setData: setArticleInfo,
    open: openArticleInfo,
    containerProps: articleInfoSidebar,
  } = useAnimatedContainer<ArticleInfoPanelContent>();

  const marginTop = useMarginOnScroll(childRef, isArticleInfoOpened);
  const [isQueryModalOpen, setIsQueryModalOpen] = useState(false);
  const saveArticles = useStableCallback(onSave);
  const removeArticles = useStableCallback(onRemove);
  const exportArticles = useStableCallback(onExport);
  const freeTextSearchArticles = useStableCallback(onTextSearch);
  const isHighlightingEnabled = !showInfoBanner;

  const api = useMemo<ResultFragmentApis>(
    () => ({
      selectedArticleInfo: articleInfo,
      saveArticles,
      removeArticles,
      isExporting,
      searchTerms: freeTextTerms,
      exportArticles,
      freeTextSearchArticles,
      isHighlightingEnabled,
    }),
    [
      articleInfo,
      saveArticles,
      removeArticles,
      isExporting,
      freeTextTerms,
      exportArticles,
      freeTextSearchArticles,
      isHighlightingEnabled,
    ]
  );

  return (
    <>
      <div className={styles.root}>
        <LoaderOverlay
          on={!!loading}
          legend={typeof loading === 'string' ? loading : `Loading ${resultType}s...`}
        >
          {(!!articles?.length || (!articles?.length && isFocusModeApplied)) && (
            <ResultFragmentContext.Provider value={api}>
              <div className={styles.container}>
                {title && (
                  <h2 className={styles.title} data-testid='result-hit-count'>
                    {title}
                  </h2>
                )}
                {!!freeTextTerms?.length && (
                  <>
                    {!hideSearchStructure && (
                      <LinkButton
                        iconLeft={Info}
                        onClick={() => freeTextTerms?.length && setIsQueryModalOpen(true)}
                        className='es-font-size-sm'
                      >
                        Search Structure
                      </LinkButton>
                    )}
                    <LinkButton
                      size='md'
                      iconLeft={Refresh}
                      onClick={() => {
                        clearSearchOptions?.();
                        onClear?.();
                      }}
                      className='pl-5'
                    >
                      Back to initial results
                    </LinkButton>
                  </>
                )}
              </div>
              {subTitle && <p className='es-font-size-lg'>{subTitle}</p>}
              {maxItemsCount &&
                totalResultCounts >= maxItemsCount &&
                totalPages === currentPage + 1 && (
                  <h3 className={styles.lastPageHeader}>
                    <Alert className={styles.lastPageIcon} />
                    <span className='es-font-size-sm'>
                      To browse all results, please refine your filter selection to include less
                      than {maxItemsCount} relations
                    </span>
                  </h3>
                )}
              <ResultList
                resultItems={articles || []}
                hideFreeTextSearch={hideFreeTextSearch}
                setClearSearchOptions={setClearSearchOptions}
                showInfoBanner={showInfoBanner}
                total={totalResultCounts}
                operator={freeTextOperator}
                searchQuery={searchQuery}
                searchTerms={freeTextTerms}
                forceResetSelection={forceResetSelection}
                facets={facets}
                itemsOffset={currentPage * currentPageSize}
                contentType={resultType}
                onActionClick={(article, tab) => {
                  let selectedTab = tab;
                  if (article.content || article.clinicalTrialData) {
                    const entities = articleIdsEntityMapping?.length
                      ? articleIdsEntityMapping
                          .filter(item => item.articleIds.includes(article.id))
                          .map(item => item.entity)
                      : entity
                        ? [entity]
                        : undefined;

                    if (tab === 'relation') {
                      selectedTab =
                        !article.freeTextSearch?.isPrimaryRelationHasToken &&
                        article.freeTextSearch?.isSecondaryRelationHasToken
                          ? 'additional-relations'
                          : 'relation';
                    }

                    openArticleInfo({
                      article,
                      tab: selectedTab,
                      facets,
                      entity: entities,
                    });
                  }
                }}
                onSelectOperator={val => onOperatorChange?.(val)}
                onClear={onClear}
              />
              <PagePanel
                totalPages={totalPages}
                pageSize={currentPageSize}
                pageNumber={currentPage + 1}
                onPageChanged={onPageChanged}
              />
              <Sidebar
                data-testid='article-side-bar'
                style={{ top: marginTop, paddingBottom: marginTop }}
                {...articleInfoSidebar}
              >
                {articleInfo && (
                  <ArticleInfoSidePanel
                    {...articleInfo}
                    onTabChange={tab => setArticleInfo({ ...articleInfo, tab })}
                  />
                )}
              </Sidebar>
            </ResultFragmentContext.Provider>
          )}
        </LoaderOverlay>
      </div>
      {isQueryModalOpen && freeTextTerms && searchQuery && (
        <QueryModal
          appliedFilters={facets}
          searchTerms={freeTextTerms}
          searchQuery={searchQuery}
          operator={freeTextOperator}
          onClose={() => setIsQueryModalOpen(false)}
        />
      )}
    </>
  );
};

export default ResultFragment;
