import type { ConceptTypes } from 'constants/concepts';

export interface Concept {
  urn: string;
  type: ConceptTypes;
  name: string;
  shortDescription?: null | string;
  description?: null | string;
  termClass?: null | string[];
  primaryCellLocalization?: null | string[];
  organism?: null | string[];
  totalRelations?: null | number;
  meSHHeading?: null | string[];
  molecularWeight?: null | string[];
  molecularFormula?: null | string[];
  // Enzyme Commission Number
  ecNumber?: null | string[];
}

export enum MappingStatus {
  MATCHED_BY_NAME = 'MATCHED_BY_NAME',
  MATCHED_BY_SYNONYM = 'MATCHED_BY_SYNONYM',
}

export interface Entry {
  name: string;
  rowNum: number;
  mappingStatus: MappingStatus;
  concept: Concept;
}

export enum ConceptActions {
  OPEN_DETAILS = 'OPEN_DETAILS',
}
