import type { HTMLAttributes, VFC } from 'react';
import cn from 'classnames';
import type { TestProps } from '@els/biomed-ui';

import styles from './DescriptionList.module.scss';

interface Props extends TestProps, HTMLAttributes<HTMLDivElement> {
  row?: true;
  inline?: true;
}

export const DescriptionListItem: VFC<Props> = ({
  title,
  className,
  children,
  row,
  inline,
  ...props
}) => {
  if (!children) {
    return null;
  }

  return (
    <div
      className={cn(styles.item, { [styles.row]: row, [styles.inline]: inline }, className)}
      {...props}
    >
      {title && <dt className={styles.heading}>{title}</dt>}
      <dd>{children}</dd>
    </div>
  );
};
