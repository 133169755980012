import type { ColumnDef } from '@tanstack/react-table';
import { Button } from '@els/biomed-ui';

import type { Project } from 'utils/models';
import { ProjectActions, UploadStatus } from 'utils/models';

export function createActionsColumns<T extends Project>(
  options?: Partial<ColumnDef<T>>
): ColumnDef<T> {
  return {
    header: () => <span className='sr-only'>upload status</span>,
    id: 'actions',
    size: 70,
    enableSorting: false,
    cell: ({ row: { original: project }, table }) => {
      switch (project.status) {
        case UploadStatus.UPLOADING:
          return (
            <Button
              variant='secondary'
              onClick={e => {
                e.stopPropagation();
                table.options.meta?.onAction(project, ProjectActions.CANCEL_UPLOAD);
              }}
              data-testid='cancel-upload'
            >
              Cancel Upload
            </Button>
          );
        case UploadStatus.COMPLETED:
          return (
            <Button
              variant='primary'
              onClick={e => {
                e.stopPropagation();
                table.options.meta?.onAction(project, ProjectActions.OPEN_ANALYSIS);
              }}
              data-testid='open-project'
            >
              Open analysis
            </Button>
          );
        default:
          return null;
      }
    },
    ...options,
  };
}
