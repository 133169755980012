import { createContext, useContext } from 'react';

import type { SelectedArticles } from 'utils/models';
import type { ArticleInfoPanelContent } from './ArticleInfoSidePanel';

export type ListData = {
  id: string;
  name: string;
};

export interface ResultFragmentApis {
  selectedArticleInfo?: ArticleInfoPanelContent;
  saveArticles?: (listData: ListData, articles: SelectedArticles) => void;
  removeArticles?: (articles: SelectedArticles) => void;
  isExporting?: boolean;
  exportArticles?: (articles: string[], fileType: string) => void;
  freeTextSearchArticles?: (searchOptions: string[]) => void;
  searchTerms?: string[];
  isHighlightingEnabled?: boolean;
}

export const ResultFragmentContext = createContext<ResultFragmentApis | null>(null);

export function useResultFragmentContext() {
  const context = useContext(ResultFragmentContext);

  if (!context) {
    throw new Error('Missing "ResultFragmentContext.Provider" in the components tree.');
  }

  return context;
}
