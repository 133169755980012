import { generatePath } from 'react-router-dom';

import { Folder, Upload } from 'assets/icons';
import { type VerticalNavItem } from 'components/VerticalNavigationBar';

export const baseNetworksPath = '/networks';

export const uploadNetworkPath = `upload`;
export const savedNetworksPath = `saved`;
export const networkDetailsPath = `saved/:networkId`;
export const networkResultsPath = `results`;

export const absoluteNetworkResultPath = `${baseNetworksPath}/${networkDetailsPath}/${networkResultsPath}`;

export const verticalNavItems: VerticalNavItem[] = [
  {
    id: uploadNetworkPath,
    icon: Upload,
    label: 'New project',
  },
  {
    id: savedNetworksPath,
    icon: Folder,
    label: 'Saved networks',
  },
];

export function getNetworkDetailsPath(networkId: string): string {
  return generatePath(`${baseNetworksPath}/${networkDetailsPath}`, { networkId });
}

export function getNetworkResultsPath(networkId: string): string {
  return generatePath(`${baseNetworksPath}/${networkDetailsPath}/${networkResultsPath}`, {
    networkId,
  });
}
