import type { FC, HTMLAttributes } from 'react';
import cn from 'classnames';
import type { BaseProps, IconSource } from '@els/biomed-ui';
import { Icon } from '@els/biomed-ui';
import { useStyles } from '@els/biomed-ui';

import styles from './StatusCue.module.scss';

type Props = BaseProps<HTMLAttributes<HTMLDivElement>> & {
  theme?: 'info' | 'warn' | 'confirm';
  icon?: IconSource;
};

/**
 * The component to indicate some status
 * TODO: LIBRARY-CANDIDATE
 */
const StatusCue: FC<Props> = ({ icon, theme, className, size, children, ...props }) => {
  const { css } = useStyles({ className, size }, styles);

  return (
    <div className={cn(css.root, theme && styles[theme], className)} {...props}>
      {icon && <Icon name={icon} />}
      {children && <div className={styles.text}>{children}</div>}
    </div>
  );
};

export default StatusCue;
