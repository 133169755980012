import type { ReactNode, VFC } from 'react';
import { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';
import { Icon } from '@els/biomed-ui';

import { NavigateRight } from 'assets/icons';

import styles from './Breadcrumb.module.scss';

export interface BreadcrumbItem {
  name: string;
  path: string;
  disable?: boolean;
}

interface Props {
  /** List of breadcrumbs. */
  list: BreadcrumbItem[];
  /** Separator to be used between items. */
  separator?: ReactNode;
}

/**
 * Breadcrumbs navigation.
 */
const Breadcrumb: VFC<Props> = ({ list, separator = <Icon name={NavigateRight} size='xxs' /> }) => {
  if (!list.length) {
    return null;
  }

  return (
    <nav aria-label='Breadcrumb'>
      <ol className={styles.container}>
        {list.map(({ name, path, disable }, index) => (
          <Fragment key={path}>
            <li>
              <NavLink
                to={path}
                className={({ isActive }) =>
                  cn({ [styles.disabled]: disable, [styles.active]: isActive && !disable })
                }
                onClick={e => {
                  if (disable) {
                    e.preventDefault();
                  }
                }}
                end
              >
                {name}
              </NavLink>
            </li>
            {index < list.length - 1 && <li data-testid='separator'>{separator}</li>}
          </Fragment>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
