interface NetworkArrowIconProps {
  color?: string;
}

const NetworkArrowIcon: React.VFC<NetworkArrowIconProps> = ({ color = '#939595' }) => (
  <svg width='49' height='12' viewBox='0 0 49 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7V5ZM49 6L39 0.226497V11.7735L49 6ZM1 7H40V5H1V7Z'
      fill={color}
    />
  </svg>
);

export default NetworkArrowIcon;
