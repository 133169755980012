import type { ConceptFilterTypes } from 'constants/constant';
import type { Article } from './articles';

//TODO: change data model once result api is refactored and remove unused data types
export interface ResultList {
  number: number;
  numberOfElements: number;
  size: number;
  totalPages: number;
  itemsType: string;
  totalElements: number;
  entity?: EntityData;
  articles?: Article[];
  articleIdsAndEntityURNMapping?: ArticleIdsAndUrnMapping[];
}

export interface ArticleIdsAndUrnMapping {
  urn: string;
  entityAndArticleIdsDto: EntityAndArticleIds;
}

export interface EntityAndArticleIds {
  entity: Entity;
  articleIds: string[];
}

export interface PublicationFacets {
  years?: FacetData[];
  refNum?: FacetData[];
}

export interface FacetResponse extends PublicationFacets {
  direction: FacetData[];
  effect: FacetData[];
  relationType: FacetData[];
  entityType: FacetData[];
}

export interface FacetData {
  key: string | number;
  hits: number;
  items?: FacetData[];
}

export enum SelectionStatus {
  ALL = 'all',
  NONE = 'none',
}

export interface PublicationFacetSavedData {
  years?: YearRange;
  refNum?: ReferenceCountRange;
}

export interface FacetSavedData extends PublicationFacetSavedData {
  direction?: Direction[] | SelectionStatus.ALL;
  effect?: string[] | SelectionStatus.ALL;
  relationType?: string[] | SelectionStatus.ALL;
}

export type SavedFilters = FacetSavedData & {
  [Property in ConceptFilterTypes]?: string[] | SelectionStatus.ALL;
};

export type AppliedFilterObject = SavedFilters | null;

export interface FacetRequestData extends Omit<FacetSavedData, 'direction'> {
  direction?: Direction;
  entityType?: EntityType[];
  freeTextSearch?: FreeText;
}
export type Operator = 'AND' | 'OR';
export interface FreeText {
  tokens: string[];
  operator?: Operator;
}
export interface EntityType {
  name: string;
  entityNames?: string[];
  entityUrns?: string[];
}

export enum Direction {
  UPSTREAM = 'upstream',
  DOWNSTREAM = 'downstream',
}
export interface YearRange {
  start?: number;
  end?: number;
}
export interface ReferenceCountRange {
  minimum?: number;
  maximum?: number;
}

export interface SankeyData {
  entity: EntityData;
  relations: RelationData[];
}

export interface EntityData {
  name: string;
  type: string;
  urn: string;
}

export type RelationEffect = 'undefined' | 'unknown' | 'positive' | 'negative';

export interface RelationData {
  direction: Direction;
  effect: string;
  entityType: string;
  relationType: string;
  relationNumber: number;
}

export type SearchResultRequestData = {
  searchParameters: SearchParameters | null;
  page?: PaginationInfo;
};

export type SearchParameters = {
  entity: Entity | Entity[] | null;
  query?: string;
  facets: FacetRequestData | null;
};

export type Entity = {
  name?: string;
  urn?: string;
};

export type PaginationInfo = {
  page?: number;
  size?: number;
  position?: string | null;
};

export type Signal = {
  signal: AbortSignal;
};

export type Facets = {
  refNum: FacetData[];
  years: FacetData[];
  direction: FacetData[];
  effect: FacetData[];
  relationType: FacetData[];
} & {
  [Property in ConceptFilterTypes]?: FacetData[];
};

export interface PageInfo {
  number: number;
  numberOfElements: number;
  size: number;
  totalPages: number;
  totalElements: number;
  itemsType: string;
}

export interface ServerEvent {
  terminate: boolean;
}

export interface PaginatedRequest {
  page?: number;
  size?: number;
}

export interface SortedRequest<T extends string = string> {
  sortBy?: T;
  sortOrder?: 'asc' | 'desc';
}

export interface ExportExperimentRequest {
  urns: string[];
  limit?: number;
  fileName: string;
}

export interface ExportExperimentPayload {
  questionId: string;
  exportExperimentRequest: ExportExperimentRequest;
}

export interface GetRelationsRequest {
  entities: string[];
  facets?: FacetRequestData;
}

export interface FacetsResponse extends PublicationFacets {
  entityType?: EntityType[];
  effect?: RelationEffect[];
  relationType?: string[];
  relationTissue?: any[];
  termClass?: string[];
  localization?: string[];
}
