import { useMemo } from 'react';
import { pluralize } from '@els/biomed-ui/utils/format';

import { getSearchTerm } from 'pages/Search';
import type { Entity } from 'utils/models';

export const getEffectForNetworkPanel = (effect: string) =>
  effect && (effect.toLocaleLowerCase() === 'undefined' || effect.toLocaleLowerCase() === 'unknown')
    ? `${effect} effect`
    : effect;

export const getEffect = (effect: string) =>
  effect && (effect.toLocaleLowerCase() === 'positive' || effect.toLocaleLowerCase() === 'negative')
    ? `${effect} `
    : '';

export const getRelDesc = (source: string, effect: string, relationType: string, target: string) =>
  `${source} has a ${getEffect(effect)}"${relationType}" relationship with ${target}.`;

export const getListTitle = (
  totalResultCounts: number,
  contentType: string,
  maxItemsCount?: number
) =>
  `${!maxItemsCount || totalResultCounts < maxItemsCount ? '' : `+ `}${pluralize(
    `(?#) ${contentType}(?s)`,
    !maxItemsCount || totalResultCounts < maxItemsCount ? totalResultCounts : maxItemsCount
  )}${!maxItemsCount || totalResultCounts < maxItemsCount ? '' : ` (Maximum)`}`;

export function useListTitle(
  totalResultCounts: number,
  contentType = 'Result',
  maxItemsCount?: number
) {
  return useMemo(
    () => getListTitle(totalResultCounts, contentType, maxItemsCount),
    [contentType, maxItemsCount, totalResultCounts]
  );
}

export const getEntityData = (query: string) => {
  const urn = getSearchTerm()?.urn;
  const entity: Entity = {
    name: query,
  };
  if (urn) entity.urn = urn;
  return entity;
};
