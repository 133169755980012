import { useCallback, useEffect, useState } from 'react';

import { useMessageBox } from 'containers/MessageBox';
import type { ApiError } from 'utils/axios';
import { convertFacetsToRequestedFacets } from 'utils/facets';
import type { SankeyData, SavedFilters } from 'utils/models';
import { getEntityData } from 'utils/Result';
import { GroupingList } from '../utils';
import { fetchSankeyData } from './api';

const commonSankeyFilters = [
  GroupingList.ENTITYTYPE,
  GroupingList.RELATIONTYPE,
  GroupingList.EFFECT,
  'direction',
];

export type Props = {
  query: string;
  facets?: SavedFilters | null;
};

const useFetchSankey = ({ query, facets = null }: Props) => {
  const [sankeyData, setSankeyData] = useState<SankeyData | null>(null);
  const [filteredSankeyRelations, setFilteredSankeyRelations] = useState<SankeyData | null>(null);
  const [sankeyLoading, setSankeyLoading] = useState(false);

  const { showMessage } = useMessageBox();

  const fetchSankey = useCallback(() => {
    if (facets) {
      const savedFilterKeys = Object.keys(facets);
      const onlyCommonFilters = !savedFilterKeys.some(item => !commonSankeyFilters.includes(item));
      if (onlyCommonFilters) {
        setFilteredSankeyRelations(null);
        return;
      }
    }
    const convertedFacets = convertFacetsToRequestedFacets(facets, null);
    setSankeyLoading(true);
    const entity = getEntityData(query);
    fetchSankeyData({ entity, facets: convertedFacets })
      .then(content => {
        if (!facets) {
          setSankeyData(content as SankeyData);
          setFilteredSankeyRelations(null);
        } else {
          setFilteredSankeyRelations(content as SankeyData);
        }
      })
      .catch(({ message, ...rest }: ApiError) => showMessage?.(message, { ...rest }))
      .finally(() => setSankeyLoading(false));
  }, [query, facets, showMessage]);

  useEffect(() => {
    if (query) fetchSankey();
  }, [fetchSankey, query]);

  return {
    sankeyData,
    filteredSankeyRelations,
    sankeyLoading,
    isChartData: sankeyData && sankeyData.relations.length > 0,
  };
};

export default useFetchSankey;
