import type { FunctionComponent } from 'react';
import { useCallback, useEffect, useState } from 'react';
import type { Bin } from 'd3';
import { Select } from '@els/biomed-ui';
import { fillArray } from '@els/biomed-ui/utils/collection';
import { useId } from '@els/biomed-ui/utils/hooks';

import HistogramChart from 'components/HistogramChart';
import Slider from '../Slider';

import styles from './SliderHistoChart.module.scss';

export type Range = [min: number, max: number];

interface Props {
  /** array of any data */
  data: number[];
  /** data array for second histogram - to compare them e.t.c. */
  originalDataSet?: number[];
  /** limits for histogram if different when [min,max] of data values */
  limitRange?: Range;
  /** the component title */
  title?: string;
  /** callback on change selected range */
  onChange?: (data: Range) => void;
  /** callback on updating selected range */
  onUpdate?: (data: Range) => void;
  /** title before the number of objects accumulated for all highlighted bars */
  selectedCountTitle?: string;
  /** defines selected (highlighted) range */
  range?: Range;
  /** defines color of histogram chart*/
  color?: string;
  /** true to place on top number of objects accumulated for all highlighted bars */
  showHighlightedCount?: boolean;
  /** ticks on slider scale */
  ticksList?: number[];
  /** hide or show tick list */
  isTickList?: boolean;
  /** same as for Slider component */
  tickAliases?: { [key: string]: string };
  /** same as for Slider: left side is colored if true */
  isLeft?: boolean;
  /** same as for Slider: right side is colored if true */
  isRight?: boolean;
  /** true if needed to show input fields under slider */
  showInputs?: boolean;
  /** maximum number of rects on histogram */
  maxTicksHisto?: number;
  /** signature before input filed(s) if needed */
  inputTextLabel?: string;
  /** true if disabled */
  disabled?: boolean;
  /** unique id */
  id?: string;
}

const SliderHistoChart: FunctionComponent<Props> = ({
  data,
  originalDataSet,
  limitRange,
  title,
  onChange,
  onUpdate,
  selectedCountTitle,
  range,
  color = '#0056d6',
  showHighlightedCount = false,
  ticksList,
  tickAliases = {},
  isTickList = false,
  isLeft,
  isRight,
  showInputs = false,
  maxTicksHisto = 10,
  inputTextLabel,
  disabled = false,
  id: idFromProps,
}) => {
  const id = useId(idFromProps, id => `${id}_slider`);

  const from = limitRange?.[0] ?? Math.min(...data);
  const to = limitRange?.[1] ?? Math.max(...data);

  const getValues = useCallback(
    (left: number, right: number) => {
      // For slider it is necessary to give a [number] for single handler and [number,number] for both
      if (!isLeft && isRight) {
        const value: [number] = [left];
        return value;
      } else if (isLeft && !isRight) {
        const value: [number] = [right];
        return value;
      } else {
        const value: Range = [left, right];
        return value;
      }
    },
    [isLeft, isRight]
  );

  const [highlightedRange, setHighlightedRange] = useState<Range>([from, to]);
  const [sliderRange, setSliderRange] = useState<Range | [number]>(getValues(from, to));
  const [valueLeft, setValueLeft] = useState(0);
  const [valueRight, setValueRight] = useState(0);

  useEffect(() => {
    const rangeLeft = range && range[0] && !isLeft ? Math.max(Math.min(range[0], to), from) : from;
    const rangeRight = range && range[1] && !isRight ? Math.max(Math.min(range[1], to), from) : to;

    setHighlightedRange([rangeLeft, rangeRight]);
    setValueLeft(rangeLeft);
    setValueRight(rangeRight);
    setSliderRange(getValues(rangeLeft, rangeRight));
  }, [from, to, range, getValues, isLeft, isRight]);

  function getSelectOptions(from: number, to: number) {
    return fillArray(from, to).map(value => ({
      value,
      label: tickAliases[value] ?? value.toString(),
    }));
  }

  return (
    <div className={styles.root} id={id}>
      {title && <div className={styles.title}>{title}</div>}
      <HistogramChart
        data={data}
        originalDataSet={originalDataSet}
        limitRange={limitRange}
        ticks={maxTicksHisto}
        tipGenerator={(d: Bin<number, number>) =>
          d.x1 && d.x0 && d.x1 - d.x0 > 1
            ? `${d.x0}-${d.x1 - 1}: ${d.length}`
            : `${d.x0}: ${d.length}`
        }
        highlightedRange={highlightedRange || [from, to]}
        showHighlightedCount={showHighlightedCount}
        selectedCountTitle={selectedCountTitle}
        color={color}
        disabled={disabled}
        useMat={false}
      >
        {from < to && (
          <Slider
            className={styles.slider}
            domain={[from, to]}
            values={sliderRange}
            ticksList={isTickList ? ticksList || [from, to] : []}
            tickAliases={tickAliases}
            isLeft={isLeft}
            isRight={isRight}
            disabled={disabled}
            step={1}
            mode={1}
            onChange={value => onChange?.(value as Range)}
            onUpdate={value => {
              const nextValue: Range =
                isRight === isLeft ? (value as Range) : isRight ? [value[0], to] : [from, value[0]];
              setHighlightedRange(nextValue);
              setValueLeft(nextValue[0]);
              setValueRight(nextValue[1]);
              onUpdate?.(nextValue);
            }}
          />
        )}
      </HistogramChart>
      {showInputs && (
        <div className={styles.inputBlock}>
          {inputTextLabel && <div className={styles.label}>{inputTextLabel}</div>}
          {(isLeft === isRight || isRight) && (
            <Select
              id={`Left_${id}`}
              label='From'
              value={valueLeft}
              onChange={(valueLeft = from) => {
                setValueLeft(valueLeft);
                setHighlightedRange([valueLeft, valueRight]);
                onChange?.([valueLeft, valueRight]);
              }}
              options={getSelectOptions(from, valueRight)}
              disabled={disabled}
              size='sm'
              data-testid='left-pubYear-dropdown'
              className={styles.select}
            />
          )}
          {isLeft === isRight && <div className='pt-4'>—</div>}
          {(isLeft === isRight || isLeft) && (
            <Select
              id={`Right_${id}`}
              label='To'
              value={valueRight}
              onChange={(valueRight = to) => {
                setValueRight(valueRight);
                setHighlightedRange([valueLeft, valueRight]);
                onChange?.([valueLeft, valueRight]);
              }}
              options={getSelectOptions(valueLeft, to)}
              disabled={disabled}
              size='sm'
              data-testid='right-pubYear-dropdown'
              className={styles.select}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default SliderHistoChart;
