import type { VFC } from 'react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import type { RowSelectionState } from '@tanstack/react-table';
import { Progress } from '@els/biomed-ui';

import { AppButtonLink } from 'components/AppLink';
import Breadcrumb from 'components/Breadcrumb';
import InfoTooltip from 'components/InfoTooltip';
import ActionsFooter from 'components/Projects/ActionsFooter';
import ConceptsMappingInfo from 'components/Projects/ConceptsMappingInfo';
import FileNameTemplate from 'components/Projects/FileNameTemplate';
import { networkMaxConceptsCount } from 'constants/constant';
import useTrackNewPage from 'hooks/useTrackNewPage';
import { getNetworkResultsPath } from '../constants';
import { useFetchNetwork, useUpdateNetwork } from '../services/queries';
import { useBreadcrumbs } from '../services/useBreadcrumbs';
import { getNetworkSessionData, setNetworkSessionData } from '../services/useNetworkSessionData';
import { useSelectedEntries } from '../services/useSelectedEntries';
import { validate } from '../services/validate';
import { ConceptList } from './ConceptList';

const NetworkDetails: VFC = () => {
  const breadcrumbs = useBreadcrumbs();
  const { networkId } = useParams();
  const parsedNetworkId = Number(networkId);

  const { isLoading, data: networkInfo } = useFetchNetwork(parsedNetworkId);
  const [, updateNetwork] = useUpdateNetwork();

  const [selectionState, setSelectionState] = useState<RowSelectionState>({});

  const selectedEntries = useSelectedEntries(networkInfo, selectionState);

  useEffect(() => {
    if (networkInfo) {
      const networkSessionData = getNetworkSessionData(networkInfo.experiment.id);
      const selection = networkSessionData
        ? (networkSessionData.selection ?? {})
        : networkInfo.entries.reduce<RowSelectionState>((state, entry) => {
            state[entry.rowNum] = true;
            return state;
          }, {});

      setSelectionState(selection);
      setNetworkSessionData({
        id: networkInfo.experiment.id,
        selection,
      });
    }
  }, [networkInfo]);

  useTrackNewPage('network:concepts mapping');

  function modifyNetwork(name: string, description: string | null) {
    updateNetwork({ id: parsedNetworkId, name, description });
  }

  function selectionChanged(selection: RowSelectionState) {
    if (!networkInfo) return;

    const networkSessionData = getNetworkSessionData(parsedNetworkId) || {
      id: networkInfo.experiment.id,
    };

    setNetworkSessionData({
      ...networkSessionData,
      selection,
    });
    setSelectionState(selection);
  }

  if (isLoading) {
    return (
      <Progress
        className='es-move-middle es-move-center'
        contentSize='md'
        size='lg'
        animationSpeed='moderate'
      />
    );
  }

  if (!networkInfo) {
    return <div>Network data not found.</div>;
  }

  const { experiment, entries } = networkInfo;

  const [entriesMessage, selectedEntriesMessage] = validate(entries, selectedEntries);

  return (
    <section>
      <Breadcrumb list={breadcrumbs} />
      <FileNameTemplate
        name={experiment.title}
        description={experiment.description}
        onDataUpdate={modifyNetwork}
      />

      <ConceptsMappingInfo {...networkInfo} maxConceptsCount={networkMaxConceptsCount}>
        {entriesMessage}
      </ConceptsMappingInfo>

      <ConceptList
        data={networkInfo}
        selection={selectionState}
        onSelectionChange={selectionChanged}
      />

      <ActionsFooter>
        {entries.length > 0 && selectedEntriesMessage && (
          <InfoTooltip placement='top-end' content={selectedEntriesMessage} size='md' />
        )}

        <AppButtonLink
          to={getNetworkResultsPath(`${parsedNetworkId}`)}
          disabled={!!entriesMessage || !!selectedEntriesMessage}
          data-testid='generate-network'
        >
          Generate network
        </AppButtonLink>
      </ActionsFooter>
    </section>
  );
};

export default NetworkDetails;
