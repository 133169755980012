import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';

import { convertFacetsToRequestedFacets } from 'utils/facets';
import type { Entry, GetRelationsRequest } from 'utils/models';
import { fetchNetworkRelations } from '../api';
import type { NetworkFilters } from '../facets';
import { networksQueries } from './queries';

export function useFetchNetworkRelations(entries: Entry[], filters: NetworkFilters = {}) {
  const request = useMemo<GetRelationsRequest>(
    () => ({
      entities: entries.map(e => e.concept.urn),
      facets: convertFacetsToRequestedFacets(filters, null) ?? undefined,
    }),
    [entries, filters]
  );

  return useQuery({
    queryKey: networksQueries.relations(request),
    queryFn: async () => {
      if (!request.entities.length) {
        return null;
      }
      return fetchNetworkRelations(request);
    },
    keepPreviousData: true,
  });
}
