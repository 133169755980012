import omitBy from 'lodash/omitBy';

import type { Option } from 'components/FilterOption';
import {
  conceptFilters,
  type ConceptFilterTypes,
  filterGroupCategoryMapping,
  filterGroupKeyMapping,
} from 'constants/constant';
import type {
  Direction,
  EntityType,
  FacetData,
  FacetRequestData,
  FacetResponse,
  Facets,
  SavedFilters,
} from 'utils/models';
import { SelectionStatus } from 'utils/models';

export type FilterKey = keyof SavedFilters;

export const transformSavedFacets = (
  key: FilterKey,
  savedFiltersData: SavedFilters,
  filterData: Facets | null
) => {
  let transformedSelectedFilters: FacetRequestData = {};

  if (
    filterGroupCategoryMapping['relationFilters'].includes(key) &&
    savedFiltersData[key] === SelectionStatus.ALL
  ) {
    if (key !== 'direction' && filterData) {
      const filterDataForKey = filterData[key];
      if (filterDataForKey)
        transformedSelectedFilters = { [key]: filterDataForKey.map(item => item.key) };
    }
  } else {
    if (key === 'direction' && savedFiltersData.direction?.length) {
      if (savedFiltersData.direction?.length === 1)
        transformedSelectedFilters = {
          direction: savedFiltersData.direction[0] as Direction,
        };
    } else {
      transformedSelectedFilters = { [key]: savedFiltersData[key] };
    }
  }

  return transformedSelectedFilters;
};

// Helper function to handle conceptType transformation
function transformConceptType(savedFilters: SavedFilters): SavedFilters {
  if (!('conceptType' in savedFilters)) return savedFilters;

  const conceptType = savedFilters.conceptType as string[];
  const transformedFilters = conceptType.reduce(
    (acc, val) => {
      acc[val as FilterKey] = SelectionStatus.ALL;
      return acc;
    },
    { ...savedFilters }
  );

  delete transformedFilters.conceptType;
  return transformedFilters;
}

// Helper function to create entityType objects
function createEntityTypeObject(key: FilterKey, savedFilters: SavedFilters): EntityType {
  return savedFilters[key] === SelectionStatus.ALL
    ? { name: key }
    : { name: key, entityNames: savedFilters[key] as string[] };
}

export const convertFacetsToRequestedFacets = (
  savedFilters: SavedFilters | null,
  facetData: Facets | null
): FacetRequestData | null => {
  if (!savedFilters || !Object.keys(savedFilters).length) return null;

  // Transform conceptType in savedFilters if present
  const transformedFilters = transformConceptType(savedFilters)!;

  const conceptFiltersKeys = Object.keys(conceptFilters) as FilterKey[];
  const savedFilterKeys = Object.keys(transformedFilters) as FilterKey[];

  // Construct requestedFacetObject by filtering and reducing savedFilterKeys
  const requestedFacetObject: FacetRequestData = savedFilterKeys
    .filter(key => !conceptFiltersKeys.includes(key))
    .reduce((nonConceptFilters, key) => {
      const updatedFilter = transformSavedFacets(key, transformedFilters, facetData);
      return { ...nonConceptFilters, ...updatedFilter };
    }, {});

  // Construct entityType array
  requestedFacetObject.entityType = savedFilterKeys
    .filter(key => conceptFiltersKeys.includes(key))
    .map(key => createEntityTypeObject(key, transformedFilters));

  return requestedFacetObject;
};

export const normalizeFacetData = (facetResponse: FacetResponse) => {
  const { entityType: entityFilters, ...rest } = facetResponse;

  if (entityFilters.length) {
    const conceptFilters = entityFilters.reduce(
      (result: { [key: string]: FacetData[] }, currentValue: FacetData) => {
        const entityName = currentValue.key;
        const entityItems = currentValue.items || ([] as FacetData[]);
        if (!result[entityName]) {
          result[entityName] = [];
        }
        result[entityName] = entityItems;
        return result;
      },
      {}
    );
    return {
      ...rest,
      ...conceptFilters,
    } as Facets;
  } else return rest as Facets;
};

export const isInEntityConceptFilters = (concept: string) =>
  Object.keys(conceptFilters).includes(concept) || concept === filterGroupKeyMapping.conceptFilters;

const allHitsZero = (facets: FacetData[]) => facets.filter(facet => facet.hits !== 0).length === 0;

export const isEmptyFacetData = (facetData: Facets | null) => {
  if (facetData) {
    const clearObject = omitBy(
      facetData,
      (value, key) =>
        !value ||
        (Array.isArray(value) && !value.length) ||
        (key === 'refNum' && allHitsZero(value)) ||
        (key === 'years' && allHitsZero(value))
    );
    return !Object.keys(clearObject).length;
  }
  return true;
};

export const mapFacetsToOptions = (facetData: Facets | null, key: ConceptFilterTypes) => {
  if (facetData) {
    const conceptFacets = facetData[key];
    if (conceptFacets) {
      const options = conceptFacets.map(item => ({ label: item.key, value: item.hits }));
      return options as Option[];
    }
  }
};

export const removeEmptyKeys = (selectedFilters: SavedFilters) => {
  if (selectedFilters) {
    const clearObject = omitBy(
      selectedFilters,
      value => !value || (Array.isArray(value) && !value.length)
    );
    return Object.keys(clearObject).length > 0 ? clearObject : null;
  }
  return null;
};

export function fillHistogramData(facetArray: FacetData[] = []) {
  const histogramData: number[] = [];
  facetArray.forEach(data => {
    for (let hitCounter = 0; hitCounter < Number(data.hits); hitCounter++) {
      histogramData.push(Number(data.key));
    }
  });
  return histogramData;
}
