import type { ComponentType } from 'react';
import { Suspense } from 'react';
import { useLocation } from 'react-router-dom';
import { Progress } from '@els/biomed-ui';

type Props<P> = P & {
  component: ComponentType<P>;
};

const LazyLoad = <P extends object>({ component: Component, ...rest }: Props<P>) => {
  const location = useLocation();

  return (
    <Suspense
      key={location.pathname}
      fallback={
        <>
          <div className='u-margin-xl-ver d-flex justify-content-center'>
            <Progress className='u-margin-xl-ver' size='lg' animationSpeed='moderate' />
          </div>
        </>
      }
    >
      <Component {...(rest as P)} />
    </Suspense>
  );
};
export default LazyLoad;
