import { useContext } from 'react';

import { MessageBoxContext } from './MessageBoxContext';

export const useMessageBox = () => {
  const context = useContext(MessageBoxContext);

  if (!context) {
    throw new Error('Missing `MessageBoxProvider` in the components tree!');
  }

  const { show, hide } = context;

  return { showMessage: show, hide };
};
