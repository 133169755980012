import type { VFC } from 'react';
import { useMemo, useReducer, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { Button, Col, Placeholder, Progress, Row } from '@els/biomed-ui';
import { useToggle } from '@els/biomed-ui/utils/hooks';

import { Bullseye } from 'assets/icons';
import Breadcrumb from 'components/Breadcrumb';
import CollapsibleCol from 'components/CollapsibleCol';
import NetworkLegend from 'components/NetworkLegend';
import Sidebar from 'components/Sidebar';
import { maxResults } from 'constants/constant';
import ConceptDetailsContainer from 'containers/ConceptDetailsContainer';
import ResultFragment from 'containers/ResultFragment';
import { useExportArticles } from 'containers/ResultFragment/ExportControl';
import { useAnimatedContainer } from 'hooks/useAnimatedContainer';
import useTrackNewPage from 'hooks/useTrackNewPage';
import { useSaveArticles } from 'pages/Result';
import type { ApiError } from 'utils/axios';
import type { Operator } from 'utils/models';
import { useListTitle } from 'utils/Result';
import { networkReducer, type NetworkState } from '../reducer';
import { filterCurrentFilters, type NetworkFilters } from '../services/facets';
import type { NetworkRelationsResponse } from '../services/models';
import {
  useFetchNetwork,
  useFetchNetworkArticles,
  useFetchNetworkRelations,
} from '../services/queries';
import { useBreadcrumbs } from '../services/useBreadcrumbs';
import { useNetworkSessionData } from '../services/useNetworkSessionData';
import { useSearchEntities } from '../services/useSearchEntities';
import { useSelectedEntries } from '../services/useSelectedEntries';
import { ActiveFilters } from './ActiveFilters';
import { FiltersPanel } from './FiltersPanel';
import { NetworkChart } from './NetworkChart';

import styles from './NetworkResults.module.scss';

const initialState: NetworkState = {
  selectedFilters: {},
  pageInfo: { pageIndex: 0, pageSize: 10 },
  searchTerms: undefined,
  operator: 'AND',
};

const NetworkResults: VFC = () => {
  const { networkId: id } = useParams();
  const location = useLocation();
  const breadcrumbs = useBreadcrumbs();
  const [{ selectedFilters, pageInfo, searchTerms, operator }, dispatch] = useReducer(
    networkReducer,
    initialState
  );
  const networkId = Number(id);
  const { isLoading: isNetworkLoading, data: networkInfo } = useFetchNetwork(networkId);
  const networkSessionData = useNetworkSessionData(networkId);
  const [clearSearchOptions, setClearSearchOptions] = useState<() => void>();

  useTrackNewPage('network:results');

  const selectedEntries = useSelectedEntries(networkInfo, networkSessionData?.selection);

  const [focusModeFilters, setFocusModeFilters] = useState<NetworkFilters>({});

  const appliedConcept = location.hash.slice(1) || undefined;
  const isInFocusMode = !!appliedConcept;

  const currentFilters = isInFocusMode ? focusModeFilters : selectedFilters;

  // Need to keep initial relations for rendering chart and latest relations to dim nodes.
  const [originalRelationsData, setOriginalRelationsData] = useState<NetworkRelationsResponse>();

  const { isLoading: isRelationsLoading, data: relationsData } = useFetchNetworkRelations(
    selectedEntries,
    currentFilters
  );

  if (relationsData && isEmpty(currentFilters) && relationsData !== originalRelationsData) {
    setOriginalRelationsData(relationsData);
  }

  const { relations: originalRelations, facets: originalPublicationFacets } =
    originalRelationsData ?? {};
  const { relations: filteredRelations, facets: availableFacets } = relationsData ?? {};

  const refinedCurrentFilters = useMemo(
    () => filterCurrentFilters(currentFilters, availableFacets),
    [currentFilters, availableFacets]
  );

  const {
    data: selectedConcept,
    open: openSelectedConcept,
    close: closeSelectedConcept,
    containerProps: conceptSidebar,
  } = useAnimatedContainer<string>();

  const appliedRelations = useMemo(
    () =>
      appliedConcept
        ? originalRelations?.filter(({ sourceTerm, targetTerm }) =>
            [sourceTerm.urn, targetTerm.urn].includes(appliedConcept)
          )
        : originalRelations,
    [originalRelations, appliedConcept]
  );

  const appliedActiveRelations = useMemo(
    () =>
      appliedConcept
        ? filteredRelations?.filter(({ sourceTerm, targetTerm }) =>
            [sourceTerm.urn, targetTerm.urn].includes(appliedConcept)
          )
        : filteredRelations,
    [filteredRelations, appliedConcept]
  );

  console.log('!', { appliedRelations, filteredRelations });

  const { entity, facets = null } = useSearchEntities({
    activeConceptUrn: appliedConcept,
    relations: appliedActiveRelations,
    availableFacets,
    activeFacets: refinedCurrentFilters,
    // Do not add nodes w/o relations to facets in focused mode.
    selectedEntries: appliedConcept ? undefined : selectedEntries,
  });

  const {
    data: articlesData,
    isFetching: isLoadingArticles,
    error: loadingArticlesError,
    isPreviousResponse,
    resetPrevSuccessResponse,
  } = useFetchNetworkArticles({
    entity: entity || [],
    filters: facets,
    pagination: pageInfo,
    searchTerms: searchTerms || [],
    operator: operator!,
  });

  const {
    articles,
    number: currentPage = 0,
    size: currentPageSize = 10,
    totalPages = 0,
    totalElements: totalResultCounts = 0,
    articleIdsAndEntityURNMapping,
  } = articlesData ?? {};

  const title = useListTitle(totalResultCounts);

  const [isSaving, saveArticles] = useSaveArticles();
  const [isExporting, exportArticles] = useExportArticles();

  const [filtersExpanded, toggleFilters] = useToggle(true);

  const updateFilters = (newFilterCriteria: NetworkFilters) => {
    clearSearchOptions?.();
    if (isInFocusMode) {
      // Update focusModeFilters if in focus mode
      setFocusModeFilters({
        ...newFilterCriteria,
      });
    } else {
      // Update filters otherwise
      dispatch({
        type: 'update-filter-selection',
        payload: {
          ...newFilterCriteria,
        },
      });
    }
  };

  if (isNetworkLoading || isRelationsLoading) {
    return (
      <Progress className='es-move-center' contentSize='md' size='lg' animationSpeed='moderate' />
    );
  }

  const canFocus =
    !appliedConcept &&
    !!selectedConcept &&
    !!appliedRelations?.some(rel =>
      [rel.sourceTerm.urn, rel.targetTerm.urn].includes(selectedConcept)
    );

  if (!networkInfo) {
    return <div>Network data not found.</div>;
  }

  const pageContent = (
    <section className={styles.root}>
      <Breadcrumb list={breadcrumbs} />
      <h1 className='es-font-size-lg'>{networkInfo.experiment.title}</h1>
      {networkInfo.experiment.description && (
        <p className={styles.description}>{networkInfo.experiment.description}</p>
      )}

      {!!appliedRelations && selectedEntries.length > 1 && (
        <>
          <div className={styles.chartContainer}>
            <NetworkLegend />
            <NetworkChart
              // Do not render nodes w/o relations in focused mode.
              selectedEntries={appliedConcept ? undefined : selectedEntries}
              relations={appliedRelations}
              facets={refinedCurrentFilters}
              filteredRelations={filteredRelations}
              selectedConcept={selectedConcept}
              onSelectConcept={openSelectedConcept}
            />

            <Sidebar
              {...conceptSidebar}
              className={styles.conceptDetailsSidebar}
              contentClassName={styles.conceptDetailsContainer}
            >
              {selectedConcept && (
                <ConceptDetailsContainer urn={selectedConcept} className={styles.conceptDetails}>
                  {canFocus && (
                    <Button
                      data-testid='open-focus-mode'
                      onClick={() => {
                        setFocusModeFilters({ ...selectedFilters });
                        closeSelectedConcept();
                        resetPrevSuccessResponse();
                        document.location.hash = selectedConcept;
                      }}
                      className='mr-auto'
                    >
                      Open focus mode
                    </Button>
                  )}
                </ConceptDetailsContainer>
              )}
            </Sidebar>

            {appliedConcept && (
              <Button
                data-testid='close-focus-mode'
                variant='secondary'
                onClick={() => (document.location.hash = '')}
                iconRight={Bullseye}
                className='ml-auto mt-4'
              >
                Close focus mode
              </Button>
            )}
          </div>

          <ActiveFilters facets={refinedCurrentFilters} onChange={updateFilters} />

          <div className={styles.results}>
            <ResultFragment
              articlesInfo={{
                articles,
                totalPages,
                currentPage,
                currentPageSize,
                totalResultCounts,
                articleIdsEntityMapping: articleIdsAndEntityURNMapping?.map(
                  item => item.entityAndArticleIdsDto
                ),
              }}
              isFocusModeApplied={isInFocusMode}
              showInfoBanner={isPreviousResponse}
              facets={facets}
              freeTextOperator={operator}
              freeTextTerms={searchTerms}
              maxItemsCount={maxResults}
              title={title}
              hideSearchStructure={true}
              loading={isLoadingArticles || (isSaving ? 'Saving results' : false)}
              isExporting={isExporting}
              resultType='result'
              clearSearchOptions={clearSearchOptions}
              setClearSearchOptions={setClearSearchOptions}
              onPageChanged={(pageIndex, pageSize) => {
                dispatch({
                  type: 'update-page-info',
                  payload: { pageIndex: pageIndex - 1, pageSize: pageSize },
                });
              }}
              onSave={(listData, articleIds) => {
                saveArticles({ listData, identifiers: Object.values(articleIds) });
              }}
              onExport={(selectedArticles, fileType) => {
                exportArticles({ selectedArticles, fileType, facets, entity });
              }}
              onTextSearch={searchOptions => {
                dispatch({ type: 'update-free-text-tokens', payload: searchOptions });
              }}
              onOperatorChange={value => {
                dispatch({ type: 'update-operator', payload: value as Operator });
              }}
              onClear={() => dispatch({ type: 'update-operator', payload: 'AND' as Operator })}
            />
          </div>
        </>
      )}

      {!isLoadingArticles && !articlesData?.articles?.length && !isInFocusMode && (
        <Placeholder className='mt-4'>
          {loadingArticlesError
            ? `Error: ${(loadingArticlesError as ApiError).message}`
            : isEmpty(refinedCurrentFilters)
              ? "Unfortunately, we couldn't find any articles describing the relationship existing between the items selected. Please change your selection or upload a different file."
              : "Unfortunately, we couldn't find any articles relevant to the current filter selection. Please try a new combination of filters."}
        </Placeholder>
      )}
    </section>
  );

  return (
    <Row className='flex-nowrap'>
      <CollapsibleCol expanded={filtersExpanded} onToggle={toggleFilters} xs={6} className='mr-6'>
        <FiltersPanel
          originalPublicationFacets={originalPublicationFacets}
          availableFacets={availableFacets}
          filters={refinedCurrentFilters}
          onChange={updateFilters}
        />
      </CollapsibleCol>
      <Col>{pageContent}</Col>
    </Row>
  );
};

export default NetworkResults;
