import { Trash } from 'assets/icons';
import type { Project } from 'utils/models';
import { ProjectActions, UploadStatus } from 'utils/models';
import type { RowActionProps } from '../../Table/Columns';

export function createDeleteAction(
  options?: Partial<RowActionProps<Project>>
): RowActionProps<Project> {
  return {
    icon: <Trash title='delete' />,
    onClick: (project, table) => {
      table.options.meta?.onAction(project, ProjectActions.DELETE_FILE);
    },
    disabled: project => project.status === UploadStatus.UPLOADING,
    'data-testid': 'delete-project',
    ...options,
  };
}
