import type { ColumnDef } from '@tanstack/react-table';

import type { Entry } from 'utils/models';
import { ConceptType } from '../ConceptType';

import styles from './ConceptsTableColumns.module.scss';

export function createConceptTypeColumn<T extends Entry>(
  options?: Partial<ColumnDef<T>>
): ColumnDef<T> {
  return {
    header: 'Concept type',
    accessorKey: 'concept.type',
    enableSorting: false,
    meta: { className: 'text-center', 'data-testid': 'concept-type' },
    cell: ({ row: { original: entry } }) => {
      const { concept } = entry;
      return <ConceptType concept={concept} className={styles.conceptType} />;
    },
    size: 220,
    ...options,
  };
}
