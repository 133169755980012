import type { VFC } from 'react';
import { useEffect, useRef } from 'react';
import type { FileError } from 'react-dropzone';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

import Breadcrumb from 'components/Breadcrumb';
import DropzoneUploader, { useFileUploadTracker } from 'components/DropZoneUploader';
import FileTemplateDownload from 'components/FileTemplateDownload';
import InfoBanner from 'components/InfoBanner';
import LoaderOverlay from 'components/LoaderOverlay';
import {
  EXCEL_TEMPLATES,
  NETWORK_UPLOAD_TEMPLATE_NAME,
  networkMaxConceptsCount,
  networkRecommendedConceptsCount,
  recentUploadsCount,
} from 'constants/constant';
import { urlConfig } from 'constants/UrlConfig';
import { useMessageBox } from 'containers/MessageBox';
import useSSE from 'hooks/useSSE';
import useTrackNewPage from 'hooks/useTrackNewPage';
import type { GetProjectsRequest, Network, ProjectsSSE, Workbook } from 'utils/models';
import { ProjectActions } from 'utils/models';
import { getErrorMsgForSingleExcelFile, useMergeProjects } from 'utils/upload';
import { getNetworkDetailsPath } from '../constants';
import { uploadNetwork } from '../services/api';
import { useDeleteNetwork, useFetchNetworks, useUpdateNetwork } from '../services/queries';
import { networksQueries } from '../services/queries/queries';
import { useBreadcrumbs } from '../services/useBreadcrumbs';
import { RecentUploads } from './RecentUploads';

const recentRequest: GetProjectsRequest = {
  page: 0,
  size: recentUploadsCount,
  sortBy: 'updateDate',
  sortOrder: 'desc',
};

const FileUpload: VFC = () => {
  const navigate = useNavigate();
  const breadcrumbs = useBreadcrumbs();
  const { showMessage } = useMessageBox();

  const { uploadedFiles, uploadFile, cancelUpload, removeFile } = useFileUploadTracker(
    uploadNetwork,
    'Upload successful. Mapping in progress.'
  );

  const checked = useRef(false);

  const queryClient = useQueryClient();
  const { data: workbook, isLoading } = useFetchNetworks(recentRequest);

  const [isDeleting, deleteNetwork] = useDeleteNetwork(removeUploadedFile);
  const [, updateNetwork] = useUpdateNetwork();

  const makeSSEConnection = useSSE<ProjectsSSE<Network>>(
    urlConfig.networkBuilderSSE,
    ({ experimentStatuses: updatedNetworks }) => {
      if (updatedNetworks?.length) {
        queryClient.setQueryData<Workbook<Network>>(networksQueries.list(recentRequest), prev => {
          if (prev) {
            return {
              ...prev,
              experiments: prev.experiments.map(network => {
                const newData = updatedNetworks.find(n => n.id === network.id);
                return newData ? { ...network, status: newData.status } : network;
              }),
            };
          }
          return prev;
        });
      }
    }
  );

  useEffect(() => {
    if (workbook) {
      // Connect to BE only once on mount (after data loaded) + every
      // time on upload success (see below).
      if (!checked.current) {
        makeSSEConnection();
        checked.current = true;
      }
    }
  }, [workbook, makeSSEConnection]);

  useTrackNewPage('network:upload');

  const networksList = useMergeProjects(workbook, uploadedFiles);

  function upload(file: File[] | File) {
    uploadFile(Array.isArray(file) ? file[0] : file, network => {
      // Add uploaded file into fetched query. Need this to properly works with
      // data invalidation e.g. after experiment update.
      queryClient.setQueryData<Workbook<Network>>(networksQueries.list(recentRequest), prev => {
        if (prev) {
          return {
            ...prev,
            experiments: [network, ...prev.experiments],
          };
        }
        return prev;
      });
      makeSSEConnection();
    });
  }

  function showUploadError(errors: FileError[]) {
    const message = getErrorMsgForSingleExcelFile(errors[0]);
    showMessage(message, { supportLink: false });
  }

  function updateFileData(
    network: Network,
    keyName: string,
    { title, description }: Partial<Network>
  ) {
    if (keyName === 'title' && network.id && title) {
      updateNetwork({ id: network.id, name: title, description });
    }
  }

  function performAction(network: Network, actionType: string) {
    switch (actionType) {
      case ProjectActions.OPEN_ANALYSIS: {
        navigate(getNetworkDetailsPath(String(network.id)));
        break;
      }
      case ProjectActions.CANCEL_UPLOAD: {
        const cancelledFile = uploadedFiles.find(item => item.file.name === network.title);
        if (cancelledFile) {
          cancelUpload(cancelledFile.file);
        }
        break;
      }
      case ProjectActions.DELETE_FILE: {
        deleteNetwork(network);
        break;
      }
    }
  }

  function removeUploadedFile(networkId: number) {
    const file = uploadedFiles.find(e => e.data?.id === networkId);
    if (file) {
      removeFile(file);
    }
  }

  return (
    <section>
      <h1>Network</h1>
      <Breadcrumb list={breadcrumbs} />

      <InfoBanner className='mt-1 mb-4'>
        We advise you to upload a list of 2 - {networkRecommendedConceptsCount} items to achieve a
        clear visualization with EmBiology network visualizer. While it is possible to upload more
        than {networkRecommendedConceptsCount} concepts, keep in mind it may result in sub-optimal
        layouts.
      </InfoBanner>
      <DropzoneUploader
        id='upload-network'
        accept={EXCEL_TEMPLATES}
        maxFiles={1}
        maxRows={networkMaxConceptsCount}
        onFileUpload={upload}
        onFileUploadError={showUploadError}
      />
      <FileTemplateDownload name={NETWORK_UPLOAD_TEMPLATE_NAME} url={urlConfig.networkTemplate} />

      <LoaderOverlay on={isDeleting} className='mt-10'>
        <RecentUploads
          loading={isLoading}
          data={networksList.slice(0, recentUploadsCount)}
          onAction={performAction}
          onDataChange={updateFileData}
        />
      </LoaderOverlay>
    </section>
  );
};

export default FileUpload;
