import type { MouseEvent, VFC } from 'react';
import { Icon, Link, toast } from '@els/biomed-ui';

import { Idea } from 'assets/icons';
import { downloadFileTemplate } from 'utils/download';

interface Props {
  name: string;
  url: string;
}

const FileTemplateDownload: VFC<Props> = props => {
  function download(e: MouseEvent) {
    e.preventDefault();
    downloadFileTemplate(props)
      .then(() => toast.success('Template download successful'))
      .catch(() => toast.error('Template download failed. Please retry.'));
  }
  return (
    <div className='d-flex align-items-center'>
      <Icon className='es-color-theme2' name={Idea} size='lg' />
      <p className='es-font-size-sm pt-1'>Not sure about which file to upload?</p>
      <Link
        data-testid='download-template'
        className='pl-1 pt-1'
        href='#'
        size='sm'
        colored
        underlined
        onClick={download}
      >
        Download the template
      </Link>
    </div>
  );
};

export default FileTemplateDownload;
