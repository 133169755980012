import type { ColumnDef } from '@tanstack/react-table';

import type { Entry } from 'utils/models';

import styles from './ConceptsTableColumns.module.scss';

export function createDescriptionColumn<T extends Entry>(
  options?: Partial<ColumnDef<T>>
): ColumnDef<T> {
  return {
    header: 'Description from databases',
    accessorKey: 'concept.shortDescription',
    enableSorting: false,
    cell: ({ row: { original: entry } }) => {
      const { concept } = entry;
      return (
        <div className={styles.description} data-testid='concept-description'>
          {concept.shortDescription ?? ''}
        </div>
      );
    },
    ...options,
  };
}
