import type { FunctionComponent } from 'react';
import { useState } from 'react';
import { Pagination } from '@els/biomed-ui';

import PageSize from '../PageSize';

import styles from './PagePanel.module.scss';

interface Props {
  /** declares the current page number */
  pageNumber?: number;

  /** declares the total number of pages */
  totalPages?: number;

  /** declares the number of items per page */
  pageSize?: number;

  /** declares the array of possible numbers of items per page */
  pageSizeOptions?: number[];

  /** declares the label prefix for Page Size Options. By default is: 'Display:' */
  pageSizeLabelPrefix?: string;

  /** declares the label prefix for Page Size Options. By default is: 'results per page' */
  pageSizeLabelSuffix?: string;

  /** declares the callback on change the current page number or number of items per page */
  onPageChanged?: (pageNumber: number, pageSize: number) => void;
}

const PagePanel: FunctionComponent<Props> = ({
  pageNumber,
  totalPages,
  pageSize = 10,
  pageSizeOptions,
  pageSizeLabelPrefix,
  pageSizeLabelSuffix,
  onPageChanged,
}) => {
  const [currentPageSize, setCurrentPageSize] = useState(pageSize);

  return (
    <div className={styles.elsPagePanel}>
      <div className={styles.stickToLeft} data-testid='page-size'>
        <PageSize
          pageSize={pageSize}
          pageSizeOptions={pageSizeOptions}
          pageSizeLabelPrefix={pageSizeLabelPrefix}
          pageSizeLabelSuffix={pageSizeLabelSuffix}
          onPageSizeChange={(updatedPageSize: number) => {
            setCurrentPageSize(updatedPageSize);
            onPageChanged && onPageChanged(1, updatedPageSize);
          }}
        />
      </div>
      <div className={styles.stickToRight} data-testid='pagination'>
        <Pagination
          total={totalPages}
          index={pageNumber}
          enumerated
          onChange={selectedPageNumber =>
            onPageChanged && onPageChanged(selectedPageNumber, currentPageSize)
          }
        />
      </div>
    </div>
  );
};

export default PagePanel;
