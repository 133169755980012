import type { Entity, FacetRequestData, PaginationInfo } from './models';

export interface Article {
  id: string;
  fullTextLink: string;
  content: ArticleContent;
  freeTextSearch: FreeTextSearchTokens | null;
  pubInfo: PubInfo;
  pubType: 'ClinicalTrial' | 'Article';
  authors: Author[];
  source: Source;
  relationCount: number;
  clinicalTrialData: ClinicalData | null;
}

export interface FreeTextSearchTokens {
  isPrimaryRelationHasToken: boolean | null;
  isAbstractContainsTokens: boolean | null;
  isSecondaryRelationHasToken: boolean | null;
}

export interface ClinicalData {
  conditions: string[];
  interventions: string[];
  phases: string[];
  studyType: string | null;
  status: string | null;
  sponsor: string | null;
  collaborator: string | null;
}

export interface ArticleContent {
  title: string;
  abstract: string[];
  copyright?: string | null;
}

export interface PubInfo {
  date: PubDate;
  identifier: Identifier;
}

export interface Identifier {
  medlPmid: number | null;
  doi: string | null;
  pui: string | null;
  nctid: string | null;
}

export interface PubDate {
  day?: number | null;
  month?: number | null;
  year: number;
}

export interface Author {
  initials: string;
  surname: string;
}

export interface Source {
  title: string;
  issn: Issn[];
  firstPage?: string | null;
  lastPage?: string | null;
  volume?: string | null;
  issue?: string | null;
  articleNumber?: string | null;
}

export interface Issn {
  type: string;
  number: string;
}

export type SelectedArticles = Record<string, Identifier>;

export const isClinicalTrial = (article: Article) =>
  article.pubType === 'ClinicalTrial' || !!article.clinicalTrialData;

export interface GetArticlesParams {
  entity: Entity[];
  facets: FacetRequestData | null;
  questionIdentifier?: string;
}

export interface GetArticlesRequest {
  searchParameters: GetArticlesParams;
  page?: PaginationInfo;
}
