import type { ColumnDef } from '@tanstack/react-table';

import type { Project, ProjectSortedProps } from 'utils/models';
import { formatDate } from 'utils/timeAndDate';

export function createUpdatedDateColumn<T extends Project>(
  options?: Partial<ColumnDef<T>>
): ColumnDef<T> {
  return {
    header: 'Date',
    id: 'updateDate' satisfies ProjectSortedProps,
    accessorKey: 'updatedDate',
    size: 100,
    cell: ({ row: { original: project } }) => formatDate(project.updatedDate),
    meta: { 'data-testid': 'updated-date' },
    ...options,
  };
}
