import { useMutation } from '@tanstack/react-query';
import { Heading, Link, toast } from '@els/biomed-ui';

import type { ListData } from 'containers/ResultFragment/ResultFragmentContext';
import type { Identifier } from 'utils/models';
import { saveToList } from './api';

export interface SaveToListPayload {
  identifiers: Identifier[];
  listData: ListData;
}

export function useSaveArticles() {
  const { mutate, isLoading } = useMutation({
    mutationFn: (params: SaveToListPayload) => {
      const { listData, identifiers } = params;
      return saveToList(listData.id, identifiers);
    },
    onSuccess: (_, { listData }) => {
      toast.success(
        <Heading level='h5' data-testid='statusMessage-save-title-success'>
          Saved to {listData.name}
        </Heading>,
        <div className='es-font-size-xs' data-testid='statusMessage-save-body-success'>
          To access the list go to{' '}
          <Link
            target='_blank'
            colored
            className='d-inline-block'
            href={`/saved?listName=${listData.id}`}
            data-testid='toast-link-saved-lists'
          >
            Saved lists
          </Link>
          .
        </div>
      );
    },
    onError: (_, { listData }) => {
      toast.error(
        <Heading level='h5' data-testid='statusMessage-save-error'>
          Unable to save your list to {listData.name}
        </Heading>,
        <div className='es-font-size-xs' data-testid='statusMessage-save-body-error'>
          <p>Unfortunately we couldn't save your list. Please retry later.</p>
          <p>If the problem persists please contact support.</p>
        </div>
      );
    },
  });

  return [isLoading, mutate] as const;
}
