import type { Option } from '@els/biomed-ui/components/Select/utils';

export const EXPORT_OPTIONS = [
  { value: 'text/csv', label: 'CSV' },
  {
    value: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    label: 'XLSX',
  },
] satisfies Option[];

export const options = [
  { value: 'AND', label: 'AND' },
  { value: 'OR', label: 'OR' },
] satisfies Option[];
