import type { VFC } from 'react';
import { Checkbox, LinkButton } from '@els/biomed-ui';
import { useId } from '@els/biomed-ui/utils/hooks';

import type { Article, SelectedArticles } from 'utils/models';
import ExportControl from '../ExportControl';
import RemoveArticlesControl from '../RemoveArticlesControl';
import { useResultFragmentContext } from '../ResultFragmentContext';
import SaveArticlesControl from '../SaveArticlesControl';

import styles from './ListHeaderActionControls.module.scss';

interface Props {
  resultItems: Article[];
  total: number;
  selectedArticles: SelectedArticles;
  onCheckAll?: (checked: boolean) => void;
  onClearAll?: () => void;
}

const ListHeaderActionControls: VFC<Props> = ({
  resultItems,
  total,
  selectedArticles,
  onCheckAll,
  onClearAll,
}) => {
  const currentSelectedCount = resultItems.filter(result => selectedArticles[result.id]).length;

  const api = useResultFragmentContext();

  const indeterminate =
    currentSelectedCount !== 0 &&
    currentSelectedCount !== resultItems.filter(item => !!item.content.title).length;

  const selectedArticlesIds = Object.keys(selectedArticles);
  const totalSelectedCount = selectedArticlesIds.length;

  const id = useId(undefined, id => `actions-${id}`);
  const selectionId = `${id}-check-all`;

  return (
    <div className={styles.root} data-testid='list-header'>
      <div className={styles.checkboxContainer}>
        <Checkbox
          size='sm'
          data-testid='check-all'
          aria-labelledby={selectionId}
          checked={!!currentSelectedCount}
          indeterminate={indeterminate}
          onChange={e => onCheckAll?.(e.target.checked)}
          className={styles.checkbox}
        />
      </div>
      <div className={styles.selection}>
        <LinkButton
          id={selectionId}
          data-testid='selection-count'
          size='sm'
          inline
          onClick={() => onCheckAll?.(!indeterminate && !currentSelectedCount)}
        >
          {currentSelectedCount > 0 ? 'Unselect' : 'Select'} all{' '}
          {totalSelectedCount
            ? `(${totalSelectedCount.toLocaleString()}/${total.toLocaleString()})`
            : ''}
        </LinkButton>
        <LinkButton
          data-testid='clear-selection'
          size='xs'
          inline
          onClick={onClearAll}
          disabled={!totalSelectedCount}
          colored
        >
          Clear selection
        </LinkButton>
      </div>
      <div className={styles.actions}>
        {api.exportArticles && (
          <div data-testid='export-selected'>
            <ExportControl selectedArticles={selectedArticlesIds} />
          </div>
        )}
        {api.saveArticles && (
          <div data-testid='save-selected'>
            <SaveArticlesControl selectedArticles={selectedArticles} />
          </div>
        )}
        {api.removeArticles && (
          <div data-testid='remove-selected'>
            <RemoveArticlesControl selectedArticles={selectedArticles} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ListHeaderActionControls;
