import { forwardRef, useImperativeHandle, useState } from 'react';
import cn from 'classnames';
import { Autocomplete, Button, defaultAutocompleteTheme, SearchInput } from '@els/biomed-ui';

import { Search } from 'assets/icons';
import { autoCompleteSuggestions } from './service/api';
import { renderAutoCompleteItem } from './utils';

import styles from './AutoCompleteWrapper.module.scss';

const theme = {
  ...defaultAutocompleteTheme,
  suggestionsContainer: `${defaultAutocompleteTheme.suggestionsContainer} ${styles.underAll}`,
  suggestionHighlighted: styles.suggestionHighlighted,
  suggestion: `${defaultAutocompleteTheme.suggestion} ${styles.suggestion}`,
};

export type SuggestionsResponse = {
  synonym: string;
  useTerm: string;
  termUrn: string;
  hits: number;
  type: string;
};

export type QueryObject = {
  name: string;
  urn?: string;
};

export type Props = {
  id: string;
  renderFullSuggestion?: boolean;
  onItemSelect: (value: QueryObject) => void;
  query?: string;
  entityType?: string;
  onValueChange?: (value: string) => void;
  className?: string;
  clearAction?: boolean;
};

const AutoCompleteContainer = forwardRef(
  (
    {
      id,
      query = '',
      renderFullSuggestion = true,
      entityType,
      onItemSelect,
      onValueChange,
      className = '',
      clearAction,
    }: Props,
    ref
  ) => {
    const [value, setValue] = useState(query);

    const isDisabled = (value: string, query: string) =>
      value.trim() === query || value.trim() === '';

    useImperativeHandle(ref, () => ({
      resetValue: (value?: string) => {
        setValue(value || query);
        onValueChange?.('');
      },
    }));

    const fetcher = async (term: string = '') => {
      if (term) {
        try {
          return entityType
            ? await autoCompleteSuggestions({ term, entityType })
            : await autoCompleteSuggestions({ term });
        } catch (error: any) {
          console.log(error.message);
          return [];
        }
      }
      return Promise.resolve([]);
    };

    const getSuggestionValue = (suggestion: SuggestionsResponse) => suggestion.useTerm;

    return (
      <>
        {renderFullSuggestion && (
          <h2 className={styles.heading}>
            <b>Type in a single search Term</b> (e.g. disease,protein,drug,cell process,etc.)
          </h2>
        )}
        <div className={cn(styles.container, className)}>
          <Autocomplete
            className={styles.autocomplete}
            theme={theme}
            fetcher={fetcher}
            minChars={3}
            debounce={250}
            getSuggestionValue={getSuggestionValue}
            onSuggestionSelected={suggestion =>
              onItemSelect({ name: suggestion.useTerm.trim(), urn: suggestion.termUrn })
            }
            renderInput={({ onKeyDown, ...props }) => (
              <SearchInput
                {...props}
                className={styles.searchInput}
                data-testid={`autosuggest-input-field-${id}`}
                //In order to fix broken aria reference bug given by wave tool.
                //Issue in BIOMED library which needs to be fixed.
                aria-describedby={undefined}
                onKeyDown={e => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();
                    if (value) onItemSelect({ name: value.trim() });
                  }
                  onKeyDown?.(e);
                }}
                clearAction={clearAction}
              />
            )}
            inputProps={{
              value,
              autoFocus: true,
              onChange: (value: string) => {
                setValue(value);
                onValueChange?.(value);
              },
            }}
            renderSuggestion={renderAutoCompleteItem(value, renderFullSuggestion)}
            preventFetchOnFocus
            preventValueChangeOnKeyboardSelect
          />
          {renderFullSuggestion && (
            <Button
              data-testid={`autosuggest-input-button-${id}`}
              title='Search'
              iconRight={Search}
              disabled={isDisabled(value, query)}
              onClick={() => onItemSelect({ name: value.trim() })}
            >
              Search
            </Button>
          )}
        </div>
      </>
    );
  }
);

export default AutoCompleteContainer;
