import type { FunctionComponent, ReactNode } from 'react';
import cn from 'classnames';
import { Progress } from '@els/biomed-ui';

import styles from './LoaderOverlay.module.scss';

type Props = {
  /** switch on/off overlay */
  on?: boolean;
  children?: ReactNode;
  /** switch alignment of loader indicator: by center of screen (default) or by center of child component */
  indicatorCenteredByScreen?: boolean;
  /** indicator size */
  size?: 'sm' | 'md' | 'lg';
  /** Rotation animation speed: slow = 5s, moderate = 2s, fast = 1s. */
  animationSpeed?: 'slow' | 'moderate' | 'fast';
  /** Signature after indicator */
  legend?: string;
  /** Additional class to assign to the root element. */
  className?: string;
};

const LoaderOverlay: FunctionComponent<Props> = ({
  on = true,
  children,
  indicatorCenteredByScreen = true,
  size = 'lg',
  animationSpeed = 'moderate',
  legend = 'Loading...',
  className,
}) => {
  return (
    <div className={cn(styles.container, className)}>
      <div>{children}</div>
      {on && (
        <>
          <div className={styles.cover}></div>
          <div
            className={`${styles.loaderPanel} ${
              indicatorCenteredByScreen ? styles.centerScreen : styles.centerContainer
            }`}
          >
            <Progress size={size} animationSpeed={animationSpeed} className={styles.indicator} />
            <p>{legend}</p>
          </div>
        </>
      )}
    </div>
  );
};

export default LoaderOverlay;
