import { type VFC } from 'react';
import { Collapsible, Icon, LinkButton } from '@els/biomed-ui';

import { NavigateDown } from 'assets/icons';
import ReferenceInputSlider from 'components/ReferenceInputSlider';
import type { Range } from 'components/SliderHistoChart';
import SliderHistoChart from 'components/SliderHistoChart';

import styles from './PublicationFilterWrapper.module.scss';

interface Props {
  groupName?: string;
  pubData: number[];
  originalPubData?: number[];
  pubYearRange: Range;
  referenceRange: Range;
  referenceData: Range;
  yearLabel?: string;
  referenceLabel?: string;
  onPubFilterChange?: (values: Range, filterType: string) => void;
  limitRangeYear?: Range;
  onReferenceError?: (error: boolean) => void;
}

const PublicationFilterWrapper: VFC<Props> = (props: Props) => {
  const {
    groupName,
    pubData,
    referenceRange,
    pubYearRange,
    yearLabel = 'Publication years',
    referenceLabel = 'Supporting references',
    onPubFilterChange,
    originalPubData,
    limitRangeYear,
    referenceData,
    onReferenceError,
  } = props;

  return (
    <div data-testid='publication-filters'>
      {groupName && (!!referenceData.length || !!pubData?.length) && (
        <h4 className={styles.header}>{groupName}</h4>
      )}
      {!!pubData?.length && (
        <Collapsible
          data-testid='years-slider'
          defaultExpanded
          trigger={props => (
            <LinkButton
              {...props}
              iconRight={<Icon name={NavigateDown} size='sm' />}
              className='font-weight-bold w-100 d-flex justify-content-between pr-1'
            >
              {yearLabel}
            </LinkButton>
          )}
          className={styles.years}
          collapseProps={{
            mountOnEnter: true,
            unmountOnExit: true,
            className: styles.yearsContent,
          }}
        >
          <SliderHistoChart
            id='pubYearsSlider'
            data={pubData}
            originalDataSet={originalPubData}
            range={pubYearRange}
            limitRange={limitRangeYear}
            maxTicksHisto={100}
            showInputs
            isTickList
            tickAliases={limitRangeYear && { [`${limitRangeYear[0]}`]: `< ${limitRangeYear[0]}` }}
            onChange={value => onPubFilterChange?.(value, 'years')}
          />
        </Collapsible>
      )}
      {!!referenceData.length && (
        <Collapsible
          data-testid='reference-slider'
          defaultExpanded
          trigger={props => (
            <LinkButton
              {...props}
              iconRight={<Icon name={NavigateDown} size='sm' />}
              className='font-weight-bold w-100 d-flex justify-content-between pr-1'
            >
              {referenceLabel}
            </LinkButton>
          )}
          className={styles.references}
          collapseProps={{ className: styles.referencesContent }}
        >
          <ReferenceInputSlider
            domain={referenceRange}
            values={referenceData}
            ticksList={referenceRange}
            tickAliases={{
              [referenceRange[1]]: `${referenceRange[1]}+`,
            }}
            onChange={value => {
              onPubFilterChange?.(value, 'refNum');
            }}
            onError={val => {
              onReferenceError?.(val);
            }}
          />
        </Collapsible>
      )}
    </div>
  );
};

export default PublicationFilterWrapper;
