import type { HTMLAttributes, VFC } from 'react';
import cn from 'classnames';
import type { TestProps } from '@els/biomed-ui';

import { DescriptionListItem } from './DescriptionListItem';

import styles from './DescriptionList.module.scss';

interface Props extends TestProps, HTMLAttributes<HTMLDListElement> {
  compact?: true;
}

function DescriptionList({ compact, className, ...props }: Props): ReturnType<VFC> {
  return <dl className={cn(styles.root, compact && styles.compact, className)} {...props} />;
}

DescriptionList.Item = DescriptionListItem;

export default DescriptionList;
