import { saveAs } from 'file-saver';
import kebabCase from 'lodash/kebabCase';
import { useMutation } from '@tanstack/react-query';
import { Heading, toast } from '@els/biomed-ui';

import type { Entity, SavedFilters } from 'utils/models';
import type { ListData } from '../../ResultFragmentContext';
import { exportContent } from './api';
import { EXPORT_OPTIONS } from './constants';

export interface ExportArticlesParams {
  selectedArticles: string[];
  fileType: string;
  listData?: ListData;
  query?: Entity | null;
  facets?: SavedFilters | null;
  entity?: Entity[];
}

export function useExportArticles() {
  const { mutate, isLoading } = useMutation({
    mutationFn: (params: ExportArticlesParams) => {
      const { listData, facets = null, ...otherParams } = params;
      return exportContent({
        ...otherParams,
        listId: listData?.id,
        facets,
      });
    },
    onSuccess: async (content, params) => {
      const { fileType, listData } = params;

      const extension = EXPORT_OPTIONS.find(type => type.value === fileType)?.label.toLowerCase();
      const listName = listData?.name;
      const fileName = listName ? `${kebabCase(listName)}-` : '';

      saveAs(content, `embio-${fileName}result.${extension}`);

      toast.success(
        <Heading level='h5' data-testid='statusMessage-export-title-success'>
          Success!
        </Heading>,
        <div className='es-font-size-xs' data-testid='statusMessage-export-body-success'>
          The .{extension?.toUpperCase()} file has been saved to your computer.
        </div>
      );
    },
    onError: () => {
      toast.error(
        <Heading level='h5' data-testid='statusMessage-export-title-error'>
          Unable to export your list
        </Heading>,
        <div className='es-font-size-xs' data-testid='statusMessage-export-body-error'>
          <p>Unfortunately we couldn't export your list.Please retry later.</p>
          <p>If the problem persists please contact support.</p>
        </div>
      );
    },
  });

  return [isLoading, mutate] as const;
}
