import type { RefObject } from 'react';
import { useLayoutEffect, useState } from 'react';
import throttle from 'lodash/throttle';

export const useMarginOnScroll = (
  elementRef?: RefObject<HTMLElement>,
  shouldTrigger: boolean = false
) => {
  const [marginTop, setMarginTop] = useState(0);

  useLayoutEffect(() => {
    const element = elementRef?.current;

    if (!element || !shouldTrigger) return;

    const position = () => {
      const calculatedHeight = element.getBoundingClientRect().bottom;
      if (calculatedHeight <= 0) {
        setMarginTop(0);
      } else {
        setMarginTop(calculatedHeight);
      }
    };

    const throttledPosition = throttle(position, 125);

    // Position immediately on mount.
    position();
    window.addEventListener('scroll', throttledPosition);
    return () => {
      throttledPosition.cancel();
      window.removeEventListener('scroll', throttledPosition);
    };
  }, [elementRef, shouldTrigger]);

  return marginTop;
};
