import { forwardRef } from 'react';
import { Collapsible, LinkButton } from '@els/biomed-ui';
import { pluralize } from '@els/biomed-ui/utils/format';

import { NavigateDown } from 'assets/icons';
import HighlightText from '../HighlightText';
import type { RelationSearchHit } from '../RelationInfoPanel';
import RelationInfoHeader from './RelationInfoHeader';

import styles from './RelationCollapsibleItem.module.scss';

interface Props {
  hit: RelationSearchHit;
  index: number;
  isHighlightingEnabled?: boolean;
}

const RelationCollapsibleItem = forwardRef<HTMLDivElement, Props>(
  ({ hit, index, isHighlightingEnabled = true }, ref) => {
    const {
      relation: { snippets, freeTextSearch },
      primary,
    } = hit;
    const { isRelationContainsTokens = false, textSnippetsHavingTokens = [] } =
      freeTextSearch || {};

    return (
      <div
        ref={ref}
        data-testid={`relation-info-${primary ? 'primary' : 'secondary'}-${index}`}
        className={styles.root}
      >
        {snippets.length ? (
          <Collapsible
            trigger={props => (
              <RelationInfoHeader
                index={index}
                highlightTokens={isRelationContainsTokens}
                isHighlightingEnabled={isHighlightingEnabled}
                {...hit}
              >
                <LinkButton
                  data-testid={`relation-info-collapsible-trigger-${index}`}
                  size='xs'
                  iconRight={NavigateDown}
                  {...props}
                >
                  {pluralize('(?#) snippet(?s)', snippets.length)}
                </LinkButton>
              </RelationInfoHeader>
            )}
          >
            {snippets.map((snippet, snippetIdx) => (
              <div
                key={snippetIdx}
                data-testid={`relation-info-snippet-${index}-${snippetIdx}`}
                className={styles.snippet}
              >
                {snippets.length > 1 && (
                  <p
                    data-testid={`relation-info-snippet-title-${index}-${snippetIdx}`}
                    className={styles.snippetTitle}
                  >
                    Snippet {snippetIdx + 1}/{snippets.length}
                  </p>
                )}
                <p
                  data-testid={`relation-info-snippet-text-${index}-${snippetIdx}`}
                  className={styles.snippetText}
                >
                  {textSnippetsHavingTokens.includes(snippetIdx + 1) ? (
                    <HighlightText text={snippet} />
                  ) : (
                    <span>{snippet}</span>
                  )}
                </p>
              </div>
            ))}
          </Collapsible>
        ) : (
          <RelationInfoHeader index={index} highlightTokens={isRelationContainsTokens} {...hit} />
        )}
      </div>
    );
  }
);

RelationCollapsibleItem.displayName = 'RelationCollapsibleItem';

export default RelationCollapsibleItem;
