import { urlConfig } from 'constants/UrlConfig';
import { post, put } from 'utils/axios';
import type { Identifier, ResultList, SearchResultRequestData } from 'utils/models';

export const saveToList = (listName: string, identifiers: Identifier[]) => {
  const url = `${urlConfig.listContentUrl}/${listName}/content`;
  return put(url, identifiers);
};

export const fetchResultsList = (searchResultRequestPayload: SearchResultRequestData) => {
  return post<ResultList>(urlConfig.resultListUrl, searchResultRequestPayload);
};
