import { urlConfig } from 'constants/UrlConfig';
import { post } from 'utils/axios';
import type { Entity, FacetRequestData, SankeyData } from 'utils/models';

export type ArticlesFacetsRequestData = {
  entity: Entity;
  facets: FacetRequestData | null;
};

export const fetchSankeyData = ({ entity: query, facets }: ArticlesFacetsRequestData) => {
  return post<SankeyData>(urlConfig.sankeyDataUrl, { entity: query, facets });
};
