import type { FC, HTMLAttributes } from 'react';
import { Progress } from '@els/biomed-ui';

import ConceptDetails from 'components/Projects/ConceptDetails';
import useFetchConcept from './service/useFetchConcept';

import styles from './ConceptDetailsContainer.module.scss';

interface Props extends HTMLAttributes<HTMLDivElement> {
  urn: string;
}

const ConceptDetailsContainer: FC<Props> = ({ urn, children, ...props }) => {
  const { isLoading, isError, data: concept } = useFetchConcept(urn);

  if (isLoading) {
    return (
      <Progress className={styles.center} contentSize='md' size='lg' animationSpeed='moderate' />
    );
  }

  if (isError) {
    return <div className={styles.center}>Error: Something went wrong, please try again</div>;
  }

  return (
    <div {...props}>
      <ConceptDetails concept={concept} />
      {children}
    </div>
  );
};

export default ConceptDetailsContainer;
