import type { VFC } from 'react';
import cn from 'classnames';
import type { SVGComponent } from '@els/biomed-ui';
import { Icon } from '@els/biomed-ui';

import { conceptTypeIcons, conceptTypes, proteinTypeIcons } from 'constants/concepts';
import type { Concept } from 'utils/models';

import styles from './ConceptType.module.scss';

interface Props {
  concept: Concept;
  className?: string;
}

/**
 * Displays concept type information in proper icon-color combination.
 */
export const ConceptType: VFC<Props> = ({ concept: { type, termClass }, className }) => {
  let icon: SVGComponent | undefined = conceptTypeIcons[type];
  let label = conceptTypes[type] ?? type;
  let title: string | undefined = undefined;

  if (type === 'Protein' && termClass?.length) {
    const [proteinType] = termClass;
    label = proteinType ?? label;
    // No icon by default if unknown protein type.
    // Match term class ignoring case.
    icon = Object.entries(proteinTypeIcons).find(([name]) =>
      new RegExp(name, 'i').test(proteinType)
    )?.[1];

    if (proteinType !== label) {
      title = `${label}: ${proteinType}`;
    }
  }

  return (
    <div title={title} className={cn(styles.root, styles[type], className)}>
      {icon && <Icon name={icon} />}
      {label}
    </div>
  );
};
