import { type FunctionComponent, useState } from 'react';
import { ButtonLink, Col, Row, Tab, Tabs } from '@els/biomed-ui';

import MacLarge from 'assets/Images/MacBookLaptop.png';
import MacSmall from 'assets/Images/MacBookSmallScreen.png';
import Video from 'assets/Videos/Embio_video.mp4';
import { learnMoreUrl } from 'constants/constant';

import styles from './MiddleSection.module.scss';

const MiddleSection: FunctionComponent = () => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <Row className={styles.midSection} data-testid='middle-section'>
      <Col xs={12} className={styles.firstContainer} data-testid='first-container'>
        <h2 className={styles.sectionTitle} data-testid='section-title'>
          What you can do in EmBiology?
        </h2>
        <Tabs
          activeTab={activeTab}
          onChange={setActiveTab}
          className={styles.tabContainer}
          aria-label='Tabs for EmBiology features'
          data-testid='tabs'
        >
          <Tab label='Embio Search' aria-label='Search tab' data-testid='tab-search'>
            <div>
              <p className={styles.header}>
                With EmBiology you can quickly and easily interpret experimental data to understand
                disease biology, improve target and/or biomarker identification and prioritization,
                and decide what drug targets to pursue.
              </p>
            </div>
          </Tab>
          <Tab label='Embio Insights' aria-label='Insights tab' data-testid='tab-insights'>
            <div>
              <p className={styles.header}>
                Leverage EmBio Insights to efficiently assess candidate biological targets: using
                interactive data visualizations, EmBio Insights helps you quickly evaluate multiple
                gene products in a single step, highlighting hidden biological functions and shared
                relationships.
              </p>
            </div>
          </Tab>
          <Tab label='Embio Network' aria-label='Network tab' data-testid='tab-network'>
            <div>
              <p className={styles.header}>
                Rely on the power of EmBio Network builder to transform your list of biological
                entities into interactive pathway networks that visualize known interactions, shared
                functions, and biological relationships among your genes, proteins, or other
                molecular targets.
              </p>
            </div>
          </Tab>
        </Tabs>
        <ButtonLink
          size='md'
          className={styles.learnMoreButton}
          aria-label='Learn more about EmBiology'
          href={learnMoreUrl}
          data-testid='learn-more-button'
          target='_blank'
        >
          Learn more about EmBiology
        </ButtonLink>
      </Col>
      <Col xs={12} className={styles.laptopContainer} data-testid='laptop-container'>
        <img
          src={MacLarge}
          alt='Large MacBook laptop'
          className={styles.laptopImage}
          data-testid='large-laptop-image'
        />
        <img
          src={MacSmall}
          alt='Small MacBook laptop'
          className={styles.macContainer}
          data-testid='small-laptop-image'
        />
        <video className={styles.laptopVideo} src={Video} controls data-testid='large-laptop-video'>
          <track kind='captions' srcLang='en' label='English' />
        </video>
      </Col>
    </Row>
  );
};

export default MiddleSection;
