import type { PropsWithChildren, VFC } from 'react';
import { Collapsible, Icon, LinkButton } from '@els/biomed-ui';

import { InformationOutlineSquare, NavigateDown } from 'assets/icons';
import type { ProjectDetails, ProjectInfo } from 'utils/models';

import styles from './ConceptsMappingInfo.module.scss';

interface Props<P extends ProjectDetails> extends ProjectInfo<P> {
  maxConceptsCount?: number;
  maxUnmatchedExpanded?: number;
}

/**
 * Displays information about concepts mapping process - duplicates, unmatched counts etc.
 */
const ConceptsMappingInfo = <P extends ProjectDetails>({
  duplicates,
  unmatched,
  experiment,
  entries,
  maxConceptsCount = Number.MAX_VALUE,
  maxUnmatchedExpanded = 10,
  children,
}: PropsWithChildren<Props<P>>): ReturnType<VFC> => {
  if (
    entries.length &&
    !duplicates.length &&
    !unmatched.length &&
    experiment.documentRowsCount <= maxConceptsCount &&
    !children
  ) {
    return null;
  }

  return (
    <div data-testid='top-banner-info' className={styles.root}>
      <div className={styles.infoIcon}>
        <Icon name={InformationOutlineSquare} />
      </div>
      {entries.length ? (
        <Collapsible
          defaultExpanded={unmatched.length <= maxUnmatchedExpanded}
          trigger={props => (
            <LinkButton {...props} inline iconRight={NavigateDown} className={styles.trigger}>
              Check the following findings:
            </LinkButton>
          )}
          size='sm'
        >
          {experiment.documentRowsCount > maxConceptsCount && (
            <p>
              You have uploaded {experiment.documentRowsCount} entries but we will map only the
              first {maxConceptsCount} listed in the file.
            </p>
          )}
          {duplicates.length > 0 && (
            <p>Duplicate entries in your list: ({duplicates.join(', ')}). We will show only one.</p>
          )}
          {unmatched.length > 0 && (
            <div className={styles.unmappedList}>
              <p>Rows in your list that have not been found in the database:</p>
              <ul>
                {unmatched.map((each, index) => (
                  <li key={index}>{each}</li>
                ))}
              </ul>
            </div>
          )}
          {children}
        </Collapsible>
      ) : (
        <p className='es-font-size-sm'>
          No matches were found in the database, please check your entities names.
        </p>
      )}
    </div>
  );
};

export default ConceptsMappingInfo;
