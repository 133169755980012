import { type FunctionComponent } from 'react';
import { Navigate } from 'react-router-dom';
import { Grid } from '@els/biomed-ui';

import useAuthData from 'hooks/useAuthData';
import BottomSection from './BottomSection';
import MiddleSection from './MiddleSection';
import TopSection from './TopSection';

import styles from './LandingPage.module.scss';

const LandingPage: FunctionComponent = () => {
  const { signinRedirect, isRegistered, isAuthorized } = useAuthData();
  const isUnauthorizedUser = isRegistered && !isAuthorized;

  if (isAuthorized) {
    return <Navigate to='/search' />;
  }

  const handleAuthAction = (screenHint?: string) => {
    signinRedirect({
      extraQueryParams: {
        connection: '',
        ...(screenHint && { screen_hint: screenHint }),
      },
    });
  };

  return (
    <Grid fluid className={styles.grid}>
      <TopSection
        isRegistered={isRegistered}
        isAuthorized={isAuthorized}
        onRegister={() => handleAuthAction('signup')}
        onSignIn={() => handleAuthAction()}
      />
      {!isUnauthorizedUser && (
        <>
          <MiddleSection />
          <BottomSection />
        </>
      )}
    </Grid>
  );
};

export default LandingPage;
