import { useQuery } from '@tanstack/react-query';

import { urlConfig } from 'constants/UrlConfig';
import { get } from 'utils/axios';
import type { Concept } from 'utils/models';

const useFetchConcept = (urn: string) => {
  return useQuery({
    queryKey: ['concept', urn],
    queryFn: () => get<Concept>(`${urlConfig.concept}/${urn}`),
  });
};

export default useFetchConcept;
