import type { VFC } from 'react';

import DList from 'components/DescriptionList';
import { relationEffects } from 'constants/concepts';
import type { ConceptNode, RelationLink } from '../../services/models';

interface Props {
  link: RelationLink;
}

export const RelationData: VFC<Props> = ({ link }) => (
  <>
    <strong className='d-block mb-4'>
      {(link.source as ConceptNode).name}
      {['positive', 'negative'].includes(link.effect) ? ' ⟶ ' : ' — '}
      {(link.target as ConceptNode).name}
    </strong>
    <DList compact>
      <DList.Item title='Type' row>
        {link.type}
      </DList.Item>
      <DList.Item title='Effect' row>
        {relationEffects[link.effect] || link.effect}
      </DList.Item>
      <DList.Item title='Mechanism' row>
        {link.mechanism}
      </DList.Item>
      <DList.Item title='Organism' row>
        {link.organism?.join('; ')}
      </DList.Item>
      <DList.Item title='Organ' row>
        {link.organ?.join('; ')}
      </DList.Item>
      <DList.Item title='Tissue' row>
        {link.tissue?.join('; ')}
      </DList.Item>
    </DList>
  </>
);
