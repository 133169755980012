type Point = [x: number, y: number];
type Circle = [radius: number, center: Point];

/**
 * Calculate the circle that passes two points.
 * Steepness implies the radius of the circle. It should be between 0 and 0.5.
 * When it's 0 - the radius is infinite.
 * When it's 0.5 - the radius is the half of the distance between two points.
 */
export function calculateConnectionCircle(
  [x1, y1]: Point,
  [x2, y2]: Point,
  steepness: number
): [
  radius: number,
  circle1: [center: Point, midpoint: Point],
  circle2: [center: Point, midpoint: Point],
] {
  // Check if the steepness is valid
  if (steepness === 0 || steepness > 0.5) {
    throw new Error('The steepness is invalid: it should be between 0 and 0.5.');
  }

  // Calculate the distance between the two points
  const dx = x2 - x1;
  const dy = y2 - y1;
  const distance = Math.hypot(dx, dy);

  // Check if the points are the same
  if (distance === 0) {
    throw new Error('The given points are the same.');
  }

  // Calculate the radius of the circle that connects the two points.
  const r = (distance * (1 + 4 * steepness ** 2)) / (8 * steepness);

  // Calculate the midpoint between the two points.
  const midx = (x1 + x2) / 2;
  const midy = (y1 + y2) / 2;

  // Calculate the components of the vector that is perpendicular to the line
  // between the two points and has length equal to the distance from the midpoint
  // to the center of the circle.
  const k = Math.sqrt(r ** 2 - (distance / 2) ** 2) / distance;

  const rx = k * dy;
  const ry = k * dx;

  // Calculate the components of the vector that is parallel to the line
  // between the two points and has length equal to the distance from the midpoint
  // to the point on the circle that is closest to the line between the two points.
  const mrx = dy * steepness;
  const mry = dx * steepness;

  // Return the radius and the centers and midpoints of the two possible circles.
  return [
    r,
    [
      [midx - rx, midy + ry],
      [midx + mrx, midy - mry],
    ],
    [
      [midx + rx, midy - ry],
      [midx - mrx, midy + mry],
    ],
  ];
}

/**
 * Calculate the intersections of 2 circles.
 */
export function calculateCirclesIntersections(
  circle1: Circle,
  circle2: Circle
): [intersection1: Point, intersection1: Point] {
  const [r1, [x1, y1]] = circle1;
  const [r2, [x2, y2]] = circle2;

  // Calculate the distance between the centers of the two circles.
  const dx = x2 - x1;
  const dy = y2 - y1;
  const distance = Math.hypot(dx, dy);

  // Check if the circles intersect, if not throw an error.
  if (distance > r1 + r2) {
    throw new Error('The circles are too far apart to intersect');
  }
  if (distance < Math.abs(r1 - r2)) {
    throw new Error('One circle is contained within the other');
  }
  if (distance === 0 && r1 === r2) {
    throw new Error('The circles are identical');
  }

  // Calculate the distance from the center of the first circle to the line between the intersection points.
  const a = (r1 ** 2 - r2 ** 2 + distance ** 2) / (2 * distance);
  // Calculate the distance from the line between the centers of the circles to the intersection points.
  const h = Math.sqrt(r1 ** 2 - a ** 2);

  // Calculate the coordinates of the point on the line between the centers of the circles
  // that is closest to the intersection points.
  const hx = x1 + a * (dx / distance);
  const hy = y1 + a * (dy / distance);

  // Calculate the offsets from this point to the intersection points.
  const rx = -dy * (h / distance);
  const ry = dx * (h / distance);

  // Return the intersection points.
  return [
    [hx + rx, hy + ry],
    [hx - rx, hy - ry],
  ];
}
