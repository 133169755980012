import type { ComponentProps, HTMLAttributes } from 'react';
import cn from 'classnames';
import type { Progress, TestProps } from '@els/biomed-ui';
import { Block } from '@els/biomed-ui';
import { isDevEnv } from '@els/biomed-ui/utils';
import { useId } from '@els/biomed-ui/utils/hooks';

import styles from './ProgressBar.module.scss';

type Props = ComponentProps<typeof Progress> &
  TestProps & {
    /**
     * If false - does not render text representation of the value next to the progress bar
     * Note: children property has a priority of what should have been rendered
     */
    showValue?: boolean;
    disabled?: boolean;
    showProgressValue?: boolean;
    showCurrentProgressValue?: boolean;
    minValue?: number;
    maxValue?: number;
  };

const toLowerSize = (size: 'sm' | 'md' | 'lg') => {
  switch (size) {
    case 'sm':
      return 'xs';
    case 'md':
      return 'sm';
    case 'lg':
      return 'md';
  }
};

export const ProgressBar = ({
  id: idFromProps,
  className,
  value,
  size = 'sm',
  children,
  animationSpeed = 'slow',
  showProgressValue,
  showCurrentProgressValue = true,
  indicatorClassName,
  showValue = true,
  contentSize = toLowerSize(size),
  disabled,
  minValue,
  maxValue,
  'aria-label': ariaLabel,
  'aria-labelledby': ariaLabelledBy,
  'aria-describedby': ariaDescribedBy,
  'aria-valuetext': ariaValueText,
  ...props
}: Props) => {
  // Progressbar requires accessible name, but does not take it from content.
  // https://w3c.github.io/aria/#progressbar
  const MIN_VALUE = minValue || 0;
  const MAX_VALUE = maxValue || 100;
  const id = useId(idFromProps, id => 'progress-' + id);

  let indicatorProps: HTMLAttributes<HTMLDivElement> = {
    role: 'progressbar',
    'aria-label': ariaLabel,
    'aria-labelledby': ariaLabel ? undefined : ariaLabelledBy || id,
    'aria-describedby': ariaDescribedBy,
    'aria-disabled': disabled,
  };
  const normalizedValue = Math.round(
    ((Math.min(Math.max(value ?? 0, MIN_VALUE), MAX_VALUE) - MIN_VALUE) / (MAX_VALUE - MIN_VALUE)) *
      100
  );
  if (value !== undefined) {
    if (isDevEnv && (value < MIN_VALUE || value > MAX_VALUE)) {
      console.error(
        `value' prop is out of range: allowed interval is [${MIN_VALUE}, ${MAX_VALUE}]`
      );
    }

    indicatorProps = {
      ...indicatorProps,
      style: {
        width: `${normalizedValue}%`,
      },
      'aria-valuemin': MIN_VALUE,
      'aria-valuemax': MAX_VALUE,
      'aria-valuenow': normalizedValue,
      'aria-valuetext': ariaValueText,
      'aria-busy': normalizedValue !== 100,
    };
  }

  return (
    <Block id={id} className={cn(styles.root, styles[size], className)} {...props}>
      <div className={styles.progressWrapper}>
        <div className={cn(styles.progress, styles[size], indicatorClassName)}>
          <div className={cn(styles.progressTrack, styles[size], disabled && styles.disabled)} />
          <div
            className={cn(
              styles.progressBar,
              value === undefined && styles.indeterminate,
              styles[size],
              disabled && styles.disabled,
              !disabled && styles[`animation-${animationSpeed}`]
            )}
            {...indicatorProps}
          />
        </div>

        {showProgressValue && (
          <div className={styles.progressValueWrapper}>
            {value !== undefined && value < MAX_VALUE && showCurrentProgressValue
              ? value + ' / '
              : null}
            {MAX_VALUE}
          </div>
        )}
      </div>
      {((value && showValue) || children) && (
        <Block className={styles.content} size={contentSize}>
          {((value && showValue) || children) && (children || `${value}%`)}
        </Block>
      )}
    </Block>
  );
};

export default ProgressBar;
