import type { VFC } from 'react';
import { Collapsible } from '@els/biomed-ui';

import { NetworkArrowDotted } from 'assets/icons';
import NetworkArrowIcon from './NetworkArrowIcon';

import styles from './NetworkLegend.module.scss';

const networkArrowMappings = [
  {
    name: 'Positive effect on',
    color: '#158509',
  },
  { name: 'Negative effect on', color: '#C83727' },
  {
    name: 'Unknown effect on',
    color: '#939595',
  },
];

const NetworkLegend: VFC = () => {
  return (
    <Collapsible className={styles.collapsibleContainer} label='Relations key' defaultExpanded>
      <div className={styles.container}>
        <ul>
          {networkArrowMappings.map(({ name, color }) => (
            <li className='d-flex align-items-center' key={color}>
              <NetworkArrowIcon color={color} />
              <span className='ml-5'>{name}</span>
            </li>
          ))}
          <li>
            <NetworkArrowDotted />
            <span className='ml-5'>Non directional effect between</span>
          </li>
        </ul>
      </div>
    </Collapsible>
  );
};

export default NetworkLegend;
