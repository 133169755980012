import type { VFC } from 'react';
import { useMemo } from 'react';
import type { ColumnDef } from '@tanstack/react-table';

import { AppLink } from 'components/AppLink';
import { createDeleteAction } from 'components/Projects/ProjectsTableActions';
import {
  createActionsColumns,
  createNameColumn,
  createUpdatedDateColumn,
  createUploadStatusColumn,
} from 'components/Projects/ProjectsTableColumns';
import { Table } from 'components/Table';
import type { RowActionProps } from 'components/Table/Columns';
import type { Network } from 'utils/models';
import { savedNetworksPath } from '../../constants';

import styles from './RecentUploads.module.scss';

interface Props {
  loading?: boolean;
  data: Network[];
  onAction?: (experiment: Network, actionType: string) => void;
  onDataChange?: (experiment: Network, column: string, patch: Partial<Network>) => void;
}

export const RecentUploads: VFC<Props> = ({ loading, data, onAction, onDataChange }) => {
  const columns: ColumnDef<Network>[] = useMemo(
    () => [
      createNameColumn({ enableSorting: false }),
      createUploadStatusColumn({ enableSorting: false }),
      createUpdatedDateColumn({ enableSorting: false }),
      createActionsColumns(),
    ],
    []
  );

  const rowActions: RowActionProps<Network>[] = useMemo(() => [createDeleteAction()], []);

  return (
    <>
      <h2 className='mt-10'>Recent uploads</h2>
      <Table
        index
        onAction={onAction}
        columns={columns}
        rowActions={rowActions}
        className={styles.table}
        onDataUpdate={onDataChange}
        items={data}
        isLoading={loading}
      />
      {!!data.length && (
        <div className='es-font-size-sm mt-4 text-center'>
          See all uploaded networks on{' '}
          <AppLink data-testid='saved-networks' to={`../${savedNetworksPath}`} colored underlined>
            Saved networks
          </AppLink>{' '}
          page.
        </div>
      )}
    </>
  );
};
