import type { FunctionComponent } from 'react';
import { useMemo } from 'react';
import type { DropzoneProps, FileError, FileRejection } from 'react-dropzone';
import { ErrorCode, useDropzone } from 'react-dropzone';
import { Button, Heading } from '@els/biomed-ui';
import { pluralize } from '@els/biomed-ui/utils/format';
import { useId, useStableCallback } from '@els/biomed-ui/utils/hooks';

import { Upload } from 'assets/icons';

import styles from './DropzoneUploader.module.scss';

type Props = Omit<DropzoneProps, 'onDrop'> & {
  id?: string;
  className?: string;
  buttonLabel?: string;
  dropZoneLabel?: string;
  maxFiles?: number;
  maxRows?: number;
  /* Callback on file drop with one file if multiple is false,otherwise all uploaded files*/
  onFileUpload?: (file: File[] | File) => void;
  onFileUploadError?: (errors: FileError[]) => void;
};

const DropzoneUploader: FunctionComponent<Props> = ({
  id: idFromProps,
  accept,
  multiple = false,
  className,
  maxFiles = 1,
  maxRows,
  buttonLabel = 'Browse files',
  dropZoneLabel = 'Drag and drop your document here or',
  onFileUpload,
  onFileUploadError,
}) => {
  const id = useId(idFromProps, id => `dropzone-${id}`);

  const onDrop = useStableCallback((acceptedFiles: File[], fileRejections: FileRejection[]) => {
    const totalFiles = acceptedFiles.length + fileRejections.length;

    if (totalFiles > maxFiles) {
      onFileUploadError?.([{ code: ErrorCode.TooManyFiles }] as FileError[]);
      return;
    } else if (acceptedFiles.length) {
      onFileUpload?.(multiple ? acceptedFiles : acceptedFiles[0]);
      return;
    }

    // Returning error for first rejected file.need to think if we need errors for all rejected ones
    if (fileRejections.length) onFileUploadError?.([...fileRejections[0].errors]);
  });

  const { getRootProps, getInputProps, isFocused, open } = useDropzone({
    accept,
    multiple,
    onDrop,
    maxFiles,
    // Disable click and keydown behavior
    noClick: true,
    // Setting it true makes focus untracked
    noKeyboard: false,
  });
  const classNames = `${className} ${styles.baseStyle} ${isFocused ? styles.focusedStyle : ''}`;

  const hint = useMemo(() => {
    const acceptedFormats = accept && Object.values(accept);
    const acceptedFormatHint =
      !!acceptedFormats?.length &&
      pluralize(`Accepted format(?s): ${acceptedFormats.join(', ')}`, acceptedFormats.length);
    const maxRowsHint = maxRows && `Max rows allowed: ${maxRows}`;

    const hint = [acceptedFormatHint, maxRowsHint].filter(Boolean).join(' - ');
    return hint ? `(${hint})` : undefined;
  }, [accept, maxRows]);

  return (
    <div className={`${styles.root}`}>
      <div
        id={id}
        data-testid={`${id}-file-uploader`}
        {...getRootProps({
          className: classNames,
        })}
      >
        <input aria-label='file upload' {...getInputProps()} />
        <div className={styles.content}>
          <Upload title={'Upload files'} className={styles.uploadIcon} />
          <Heading level={'h2'} className='es-font-size-lg'>
            {dropZoneLabel}
          </Heading>
          <Button className='my-5' onClick={open}>
            {buttonLabel}
          </Button>
          {hint && <p className='es-font-size-sm'>{hint}</p>}
        </div>
      </div>
    </div>
  );
};

export default DropzoneUploader;
