import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useMessageBox } from 'containers/MessageBox';
import type { Network } from 'utils/models';
import { deleteNetwork } from '../api';
import { networksQueries } from './queries';

export function useDeleteNetwork(onSuccess?: (networkId: number) => unknown) {
  const { showMessage } = useMessageBox();

  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation({
    mutationFn: deleteNetwork,
    onSuccess: async (_, networkId) => {
      queryClient.removeQueries(networksQueries.detail(networkId));
      await queryClient.invalidateQueries(networksQueries.lists());
      // Important to also wait for "onSuccess" to finish because
      // the "deleting" flag should be reset after all operations finished.
      await onSuccess?.(networkId);
    },
  });

  return [
    isLoading,
    (network: Network) => {
      const networkId = network.id;
      if (networkId) {
        showMessage('Deleting this network cannot be undone. Do you wish to proceed?', {
          type: 'attention',
          header: 'Delete network?',
          supportLink: false,
          cancel: 'Cancel',
          confirm: 'Yes, delete',
          onConfirm: () => {
            // Do not return promise as we want to close confirm immediately.
            mutate(networkId);
          },
        });
      }
    },
  ] as const;
}
