import { useQuery } from '@tanstack/react-query';

import { type GetProjectsRequest } from 'utils/models';
import { fetchNetworks } from '../api';
import { networksQueries } from './queries';

export function useFetchNetworks(request: GetProjectsRequest) {
  return useQuery({
    // Turning off in this particular case, because we want to always
    // rerender fresh data: because we have a copy of data in separate state on upload page.
    structuralSharing: false,
    queryKey: networksQueries.list(request),
    queryFn: () => fetchNetworks(request),
    // Keeping prev data to not show blank screen when applying sorting or changing page.
    keepPreviousData: true,
  });
}
