import type { SankeyGraph, SankeyLayout } from 'd3-sankey';
import { sankey } from 'd3-sankey';

import type { ChartData, Link, Node, SLink, SNode } from 'components/SankeyChart';
import { sankeyPreferences } from 'components/SankeyChart';
import type { ConceptFilterTypes } from 'constants/constant';
import { conceptFilters } from 'constants/constant';
import type { RelationData, SankeyData, SavedFilters } from 'utils/models';
import { Direction, SelectionStatus } from 'utils/models';
import type { FilterData } from '../../SankeyPage/ChartFilters';

export enum GroupingList {
  RELATIONTYPE = 'relationType',
  EFFECT = 'effect',
  ENTITYTYPE = 'entityType',
  ENTITYNAME = 'entityName',
}

export type SortingData = {
  nodes: Record<string, SNode>;
  links: Record<string, SLink>;
};

type Side = 'left' | 'right';
type NodeObject = Record<string, Node>;
type LinkObject = Record<string, Link>;

export type DecomposeEntity = {
  type: string;
  side: Side;
};

export type CombineType = {
  [key in GroupingList]?: boolean;
};

export type PrepareChartData = (
  data: SankeyData,
  combineOptions?: CombineType,
  sortingData?: SortingData,
  decompose?: DecomposeEntity[]
) => ChartData;

const filterMapping: { [key: string]: string } = {
  [GroupingList.EFFECT]: 'effect',
  [GroupingList.RELATIONTYPE]: 'relation',
  [GroupingList.ENTITYTYPE]: 'entity',
};

export const filterObject: Record<string, string> = {
  effect: GroupingList.EFFECT,
  relation: GroupingList.RELATIONTYPE,
};

export const removeDuplicates = (values: string[]) =>
  values.filter((val, id, array) => array.indexOf(val) === id);

export const mergeFilterMap = (
  a: Record<string, string[] | SelectionStatus>,
  b: Record<string, string[] | SelectionStatus>
) => {
  const merged = { ...a };
  Object.keys(b).forEach(key => {
    if (merged[key] !== SelectionStatus.ALL && b[key] !== SelectionStatus.NONE) {
      if (merged[key] === SelectionStatus.NONE) merged[key] = b[key];
      else if (b[key] === SelectionStatus.ALL) merged[key] = SelectionStatus.ALL;
      else
        merged[key] = merged[key]
          ? removeDuplicates([...(a[key] as string[]), ...(b[key] as string[])])
          : b[key];
    }
  });
  return merged;
};

export const inRange = (value: string, range?: string[] | SelectionStatus) =>
  !range || (!!range.length && range.indexOf(value) >= 0) || range === SelectionStatus.ALL;

const getNodeAttribute = (node: SNode) =>
  node.group
    ? node.group === GroupingList.ENTITYTYPE
      ? { [node.name]: SelectionStatus.ALL }
      : { [node.group]: [node.name] }
    : {};

const getNextNodeFilter = (link: SLink) => {
  const nextNode = link.side === 'left' ? (link.target as SNode) : (link.source as SNode);
  return nextNode.group === 'main'
    ? {}
    : mergeFilterMap(getNodeAttribute(nextNode), getNextLinkFilter(nextNode));
};

const getNextLinkFilter = (node: SNode) => {
  let filter: Record<string, string[] | SelectionStatus> = {};
  const nextLinks = node.side === 'left' ? node.sourceLinks : node.targetLinks;
  if (nextLinks && nextLinks.length > 0)
    nextLinks.forEach(link => (filter = mergeFilterMap(filter, getNextNodeFilter(link))));
  return filter;
};

export const getMarkerFilterFromNode = (node: SNode) =>
  mergeFilterMap(getNodeAttribute(node), getNextLinkFilter(node));

export const getMarkerFilterFromLink = (link: SLink) => {
  const prevNode = link.side === 'left' ? (link.source as SNode) : (link.target as SNode);
  const nextNode = link.side === 'right' ? (link.source as SNode) : (link.target as SNode);
  if (nextNode.group === 'main') return getNodeAttribute(prevNode);
  const filter = mergeFilterMap(getNodeAttribute(prevNode), getNodeAttribute(nextNode));
  return mergeFilterMap(filter, getNextLinkFilter(nextNode));
};

export const checkEntities = (filters: SavedFilters, value: string) =>
  !Object.keys(conceptFilters).find(key => filters[key as ConceptFilterTypes]) || //no any entity types
  Object.keys(filters).find(key => key === value); //there is specified entity type

export const isNotEmptySelectedFilters = (filters: SavedFilters | null) =>
  filters && filters != null && Object.keys(filters).length > 0;

export const createSortingData = (data: ChartData) => {
  const sankeyLayout: SankeyLayout<ChartData, Node, Link> = sankey<ChartData, Node, Link>()
    .nodeId((d: any) => d.id)
    .nodeWidth(10)
    .nodePadding(10)
    .extent([
      [sankeyPreferences.extentX, sankeyPreferences.extentY],
      [
        sankeyPreferences.width - sankeyPreferences.extentX,
        sankeyPreferences.hight - sankeyPreferences.extentY,
      ],
    ]);

  const hashSorting: SortingData = { nodes: {}, links: {} };

  const sGraph: SankeyGraph<Node, Link> = sankeyLayout({
    nodes: data.nodes.map((d: Node) => Object.assign({}, d)),
    links: data.links.map((d: Link) => Object.assign({}, d)),
  });

  sGraph.nodes.forEach(d => {
    hashSorting.nodes[d.id] = d;
  });

  sGraph.links.forEach(d => {
    hashSorting.links[d.id] = d;
  });

  return hashSorting;
};

const checkGroupDecomposition = (
  decompose: DecomposeEntity[] | undefined,
  side: Side,
  relation: RelationData
) => {
  if (!decompose || !decompose.length) return false;

  let retValue = false;
  if (decompose && !!decompose.length) {
    decompose.forEach((d: DecomposeEntity) => {
      if (
        side === d.side &&
        ((side === 'left' && relation.entityType === d?.type) ||
          (side === 'right' && relation.entityType === d?.type))
      )
        retValue = true;
    });
  }
  return retValue;
};

const getLinkCoordinate = (a: Node, aa: SNode) => {
  if (a.side === 'left') {
    return aa.sourceLinks?.find(
      (d: SLink) => `${(d.target as SNode).id}_${a.group}_${a.type}` === a.id
    )?.y1;
  } else {
    return aa.targetLinks?.find(
      (d: SLink) => `${(d.source as SNode).id}_${a.group}_${a.type}` === a.id
    )?.y0;
  }
};

const formSankeyData = (
  data: SankeyData,
  combineOptions?: CombineType,
  decompose?: DecomposeEntity[]
) => {
  const groupingList = [GroupingList.RELATIONTYPE, GroupingList.EFFECT, GroupingList.ENTITYTYPE];
  const nodesObject: NodeObject = {
    mainObj: {
      id: 'main_obj',
      combId: 'main_obj',
      name: data.entity.name,
      refs: data.relations,
      type: data.entity.type,
      group: 'main',
    },
  };

  const linksObject: LinkObject = {};

  data.relations.forEach((relation: RelationData) => {
    const side = relation.direction === Direction.DOWNSTREAM ? 'right' : 'left';
    let innerId = 'main_obj';
    let outerId = side;
    let innerFullId = innerId;
    const combine = { ...combineOptions };

    groupingList.forEach((group: GroupingList) => {
      let name = '';
      if (group === GroupingList.RELATIONTYPE) {
        name = relation.relationType;
      } else if (group === GroupingList.EFFECT) {
        name = relation.effect || '';
      } else if (group === GroupingList.ENTITYTYPE) {
        name = relation.entityType;
      }

      const outerFullId = [outerId, group, name].join('_');
      const combId = [side, group, name].join('_');

      outerId =
        combine[group] && !checkGroupDecomposition(decompose, side, relation)
          ? combId
          : outerFullId;

      if (!nodesObject[outerId]) {
        nodesObject[outerId] = {
          id: outerId,
          name,
          combId: combId,
          side,
          type: name,
          group: group,
          refs: [],
        };
      }

      nodesObject[outerId].refs?.push(relation);

      const linkId = (
        side === 'left' ? outerFullId + '_to_' + innerFullId : innerFullId + '_to_' + outerFullId
      ).replace(/\s/g, '_');

      if (!linksObject[linkId]) {
        linksObject[linkId] = {
          id: linkId,
          source: side === 'left' ? outerId : innerId,
          target: side === 'left' ? innerId : outerId,
          value: 0,
          side,
          refs: [],
        };
      }

      linksObject[linkId].value += relation.relationNumber;
      linksObject[linkId].refs?.push(relation);

      innerId = outerId;
      innerFullId = outerFullId;
    });
  });
  const nodes = Object.values(nodesObject);
  const links = Object.values(linksObject);
  return { nodes, links };
};

export const prepareChartData: PrepareChartData = (
  data: SankeyData,
  combineOptions = {},
  sortingData,
  decompose
) => {
  const combine = { ...combineOptions };
  const { nodes, links } = formSankeyData(data, combine, decompose);

  if (sortingData) {
    nodes.sort((a, b) => {
      if (a.group && b.group && a.group !== b.group) return a.group < b.group ? -1 : 1;

      const aa = sortingData.nodes[a.id] || sortingData.nodes[a.combId || ''];
      const bb = sortingData.nodes[b.id] || sortingData.nodes[b.combId || ''];

      const compareVal = aa && bb && aa.y0 && bb.y0 ? aa.y0 - bb.y0 : 0;

      if (compareVal !== 0) {
        return compareVal;
      } else if (
        a.name === b.name &&
        a.side === b.side &&
        a.group === GroupingList.ENTITYTYPE &&
        b.group === GroupingList.ENTITYTYPE
      ) {
        const ya = getLinkCoordinate(a, aa);
        const yb = getLinkCoordinate(b, bb);
        return ya !== undefined && yb !== undefined ? ya - yb : 0;
      }

      return 0;
    });

    links.sort((a, b) => {
      const aa = sortingData.links[a.id];
      const bb = sortingData.links[b.id];
      return aa && bb && aa.y0 && bb.y0 ? aa.y0 - bb.y0 : 0;
    });
  }

  return { nodes, links };
};

/* Below is deprecated codes*/
export const getFilters = (data: ChartData) => {
  const leftSideFiltersData = data.nodes.filter((item: Node) => item.side === 'left');
  const rightSideFiltersData = data.nodes.filter((item: Node) => item.side === 'right');
  const leftSideFiltersObject = groupBy(leftSideFiltersData);
  const rightSideFiltersObject = groupBy(rightSideFiltersData);

  const leftSideFilterArray = prepareFilterData(leftSideFiltersObject, 'left');
  const rightSideFilterArray = prepareFilterData(rightSideFiltersObject, 'right');

  return { leftSideFilterArray, rightSideFilterArray };
};

export const prepareFilterData = (dataObject: { [key: string]: string[] }, side: string) => {
  const filterArray: FilterData[] = [];
  for (const [key, value] of Object.entries(dataObject)) {
    const values = value.map((value: string) => ({
      label: value,
      checked: true,
      side: side,
      filterType: filterMapping[key],
    }));
    filterArray.push({ filterLabel: filterMapping[key], filterOptions: values });
  }
  return filterArray;
};

export const groupBy = (data: Node[]) => {
  return data.reduce((r: { [key: string]: string[] }, a: Node) => {
    if (a.group) {
      r[a.group] = r[a.group] || [];
      if (!r[a.group].includes(a.name) && a.name) r[a.group].push(a.name);
    }
    return r;
  }, {});
};
