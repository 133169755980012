/**
 * Render function interface for providing overrideable render callbacks.
 */
export type RenderFunction<P extends object = object> = {
  (
    props: P,
    defaultRender: (props: P) => JSX.Element | null | undefined
  ): JSX.Element | null | undefined;
};

/** helper function used to combine default and overriden renderers */
export const composeOverridableRenderer = <P extends object>(
  props: P,
  defaultComponent: (props: P) => JSX.Element | null | undefined,
  renderFn?: RenderFunction<P>
): JSX.Element | null | undefined => {
  return renderFn ? renderFn(props, defaultComponent) : defaultComponent(props);
};
