import cn from 'classnames';
import intersection from 'lodash/intersection';
import type { RowData, Table } from '@tanstack/react-table';
import type { IconSource } from '@els/biomed-ui';
import { Flexbox, LinkButton } from '@els/biomed-ui';
import { pluralize } from '@els/biomed-ui/utils/format';

import { PredefinedColumnIds } from '../../Columns';

import styles from './GlobalActions.module.scss';

export type GlobalActionDefinition<TData extends RowData> = {
  /**
   * Title of the action.
   * Can be either constant or function if we want to Pluralize number of items selected
   */
  title: string | ((selected: TData[]) => string);
  /** Icon for the corresponding action */
  icon: IconSource;
  /**
   * disabled can be boolean or function if we need to disable action if something wrong selected
   * if you need to disable global action when row action is specified - better to specufy tags property
   */
  disabled?: boolean | ((selected: TData[]) => boolean);
  onClick: (selected: TData[]) => void;
  /**
   * If you specify tags property, then when row action with at least specified tags is disabled
   * it will disable global action with the same tag specified
   */
  tags?: string[];
};

export type RenderGlobalActionsProps<TData> = {
  actions: GlobalActionDefinition<TData>[];
  selectedItems: TData[];
  table: Table<TData>;
};

export const GlobalActionsFragment = <TData extends object>({
  actions,
  selectedItems,
  table,
}: RenderGlobalActionsProps<TData>) => (
  <tr>
    <th className={cn(styles.globalActions)} colSpan={table.getAllColumns().length}>
      <Flexbox direction='row'>
        {actions.map(({ title, icon, onClick, disabled, tags }, i) => {
          // in case we need to use custom title depending on items selected
          const titleText = pluralize(
            typeof title === 'string' ? title : title(selectedItems),
            selectedItems.length
          );
          // here we check if we have row actions that are disabled
          // so we do not allow global action with same tags as disable row action tags
          const getDisabledTags = table.getColumn(PredefinedColumnIds.RowActions)?.columnDef?.meta
            ?.getDisabledRowActionTags;
          const actionDisabled =
            (typeof disabled === 'boolean' ? disabled : disabled?.(selectedItems)) ||
            selectedItems?.some(item => intersection(tags, getDisabledTags?.(item)).length > 0);
          return (
            <LinkButton
              key={i}
              iconLeft={icon}
              title={titleText}
              disabled={actionDisabled}
              onClick={ev => {
                onClick(selectedItems);
                ev.stopPropagation();
              }}
            >
              {titleText}
            </LinkButton>
          );
        })}
      </Flexbox>
    </th>
  </tr>
);
