import type { ComponentProps, VFC } from 'react';
import cn from 'classnames';
import { Slider as BiomedSlider } from '@els/biomed-ui';

import styles from './Slider.module.scss';

interface Props extends ComponentProps<typeof BiomedSlider> {}

/**
 * Slider from "biomed" library with some embio-specific settings.
 */
const Slider: VFC<Props> = ({ className, ...props }) => {
  return <BiomedSlider className={cn(styles.root, className)} {...props} />;
};

export default Slider;
