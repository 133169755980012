import type { SVGComponent } from '@els/biomed-ui';

import {
  Cell,
  CellObject,
  CellProcess,
  ClinicalParameter,
  Disease,
  DrugsChemicals,
  FunctionalClass,
  GeneticVariant,
  Organ,
  Pathogen,
  Protein,
  ProteinComplex,
  ProteinKinase,
  ProteinLegand,
  ProteinNonCodingRNA,
  ProteinPhotophosphatase,
  ProteinReceptor,
  ProteinTransFactor,
  ProteinTransporter,
  Tissue,
  Treatment,
  Virus,
} from 'assets/icons';
import type { RelationEffect } from 'utils/models';

export type ConceptTypes = keyof typeof conceptTypes;

/**
 * UI config for concept types.
 * Also, `ConceptTypes` type is inferred from keys of this constant.
 */
export const conceptTypes = {
  CellObject: 'Cell object',
  CellProcess: 'Cell process',
  CellType: 'Cell',
  ClinicalParameter: 'Clinical parameter',
  Complex: 'Protein complex',
  Disease: 'Disease',
  FunctionalClass: 'Functional class',
  GeneticVariant: 'Genetic variant',
  Organ: 'Organ',
  Pathogen: 'Pathogen',
  Protein: 'Protein',
  SmallMol: 'Drugs & chemicals',
  Tissue: 'Tissue',
  Treatment: 'Treatments',
  Virus: 'Virus',
};

export const conceptTypeIcons: Record<ConceptTypes, SVGComponent> = {
  CellObject,
  CellProcess,
  CellType: Cell,
  ClinicalParameter,
  Complex: ProteinComplex,
  Disease,
  FunctionalClass,
  GeneticVariant,
  Organ,
  Pathogen,
  Protein,
  SmallMol: DrugsChemicals,
  Tissue,
  Treatment,
  Virus,
};

export const proteinTypeIcons: Record<string, SVGComponent> = {
  Kinase: ProteinKinase,
  Receptor: ProteinReceptor,
  Photophosphatase: ProteinPhotophosphatase,
  Ligand: ProteinLegand,
  'Transcription factor': ProteinTransFactor,
  'Non-coding RNA': ProteinNonCodingRNA,
  Transporter: ProteinTransporter,
  // Endogenous compound
};

export const relationEffects: Record<RelationEffect, string> = {
  undefined: 'Non Applicable',
  unknown: 'Unknown',
  positive: 'Positive',
  negative: 'Negative',
};
