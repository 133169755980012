import { select } from 'd3';

export const showPointerRect = (x0: number, x1: number, y0: number, y1: number) =>
  select('#pointerRect')
    .attr('display', 'block')
    .attr('x', x0 - 2)
    .attr('y', y0 - 2)
    .attr('width', x1 - x0 + 4)
    .attr('height', y1 - y0 + 4);

export const hidePointerRect = () => select('#pointerRect').attr('display', 'none');

export const sankeyPreferences = {
  width: 1000,
  hight: 600,
  extentX: 1,
  extentY: 5,
  /** nodePadding for group of nodes - make sure your chartData already sorted by the nodePaddingGroup */
  nodePadding: 10,
  /** name of nodes group - make sure your chartData already sorted by the nodePaddingGroup */
  nodePaddingGroup: 'entityType',
};

export const sankeyColumnsLabel = ['RELATION', 'EFFECT', 'ENTITY'];
