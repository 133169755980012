import { useMatches } from 'react-router-dom';

import type { BreadcrumbItem } from 'components/Breadcrumb';
import type { Matches } from './models';

export function useBreadcrumbs(): BreadcrumbItem[] {
  const matches = useMatches() as Matches[];

  const breadcrumbs = matches
    .filter(match => Boolean(match.handle?.crumb))
    .map(match => ({
      name: match.handle?.crumb,
      path: match.handle?.crumbPath || match.pathname,
    }));

  return breadcrumbs;
}
