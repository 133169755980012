import type { CellContext, RowData } from '@tanstack/react-table';

export function getRowIndex<TData extends RowData, TValue>({
  table,
  row: { index, id },
}: CellContext<TData, TValue>): number {
  const { pageIndex, pageSize } = table.getState().pagination;

  // Need this to keep proper order number in case of UI sorting.
  if (!table.options.manualSorting) {
    index = table.getSortedRowModel().rows.findIndex(row => row.id === id);
  }

  return index + 1 + pageIndex * pageSize;
}
