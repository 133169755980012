import { useQuery } from '@tanstack/react-query';

import { fetchNetwork } from '../api';
import { networksQueries } from './queries';

export function useFetchNetwork(id: number) {
  return useQuery({
    queryKey: networksQueries.detail(id),
    queryFn: () => fetchNetwork(id),
    refetchOnMount: false,
  });
}
