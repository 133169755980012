import type { VFC } from 'react';
import { Tab, Tabs, Tag } from '@els/biomed-ui';

import InfoTooltip from 'components/InfoTooltip';
import type { Article, Entity, SavedFilters } from 'utils/models';
import { isClinicalTrial } from 'utils/models';
import AbstractPanel from '../AbstractPanel';
import ClinicalInfoPanel from '../ClinicalInfoPanel';
import HighlightText from '../HighlightText';
import RelationInfoPanel from '../RelationInfoPanel';

import styles from './ArticleInfoSidePanel.module.scss';

export interface ArticleInfoPanelContent {
  article: Article;
  facets: SavedFilters | null;
  tab?: string;
  entity?: Entity[];
}

interface Props extends ArticleInfoPanelContent {
  onTabChange: (tab: string) => void;
}

const tabTypes = ['abstract', 'relation', 'additional-relations', 'info'];

const RELATIONS_TAB_NAME = {
  key: 'Key relations',
  all: 'All relations',
};

const RELATIONS_TOOLTIP_MAPPING = {
  [RELATIONS_TAB_NAME.key]:
    'Key relations are the relations found in the article directly related to your search and filters applied.',
  [RELATIONS_TAB_NAME.all]:
    'The list contains all relations found in the article both directly and indirectly related to the topic of your search and filters applied.',
};

const ArticleInfoSidePanel: VFC<Props> = ({ article, tab, facets = null, entity, onTabChange }) => {
  const activeIndex = tab ? Math.max(0, tabTypes.indexOf(tab)) : 0;
  const isClinicalData = isClinicalTrial(article);
  const relationsTabName = entity ? RELATIONS_TAB_NAME.key : RELATIONS_TAB_NAME.all;
  const relationsTooltip = RELATIONS_TOOLTIP_MAPPING[relationsTabName];

  function changeTab(index: number) {
    onTabChange(tabTypes[index]);
  }

  return (
    <section className={styles.root}>
      <Tag size='sm' className={styles.pubType}>
        {article.pubType}
      </Tag>
      <h1 className={styles.title}>
        <HighlightText text={article.content.title} />
      </h1>
      <Tabs
        data-testid='article-info-side-panel'
        activeTab={activeIndex}
        onChange={changeTab}
        className={styles.tabs}
        // Do not render the content of the hidden tabs
        // to avoid extra-api calls for primary/secondary relations.
        lazy
      >
        <Tab label='Abstract' hidden={isClinicalData}>
          {!isClinicalData && (
            <AbstractPanel
              abstractData={article.content}
              highlightTokens={article?.freeTextSearch?.isAbstractContainsTokens || false}
            />
          )}
        </Tab>
        <Tab
          label={
            <div className='text-nowrap'>
              {relationsTabName} <InfoTooltip className={styles.info} content={relationsTooltip} />
            </div>
          }
        >
          <RelationInfoPanel
            articleId={article.id}
            entity={entity}
            facets={facets}
            primary={true}
          />
        </Tab>

        <Tab
          label={
            <div className='text-nowrap'>
              Additional relations{' '}
              <InfoTooltip
                className={styles.info}
                content='Additional relations are all the other relations found in the article, not necessarily related to the topic of your search.'
              />
            </div>
          }
          hidden={!entity?.length}
        >
          <RelationInfoPanel
            articleId={article.id}
            entity={entity}
            facets={facets}
            primary={false}
          />
        </Tab>

        <Tab label='Info' hidden={!isClinicalData}>
          {isClinicalData && <ClinicalInfoPanel clinicalData={article.clinicalTrialData} />}
        </Tab>
      </Tabs>
    </section>
  );
};

export default ArticleInfoSidePanel;
