import { useCallback, useEffect, useState } from 'react';
import { useStableCallback } from '@els/biomed-ui/utils/hooks';

import type { ServerEvent } from 'utils/models';

interface Options extends EventSourceInit {
  onError?: (e: unknown) => void;
}

export default function useSSE<T extends ServerEvent>(
  url: string,
  onSuccess: (data: T) => void,
  { onError, withCredentials = true }: Options = {}
) {
  const [connected, setConnected] = useState(false);

  const notify = useStableCallback(onSuccess);
  const notifyError = useStableCallback(onError);

  useEffect(() => {
    if (connected) {
      console.debug('sse: connecting', url);

      const source = new EventSource(url, { withCredentials });

      source.onopen = () => {
        console.debug('sse: connected');
      };

      source.onmessage = event => {
        try {
          const data: T = JSON.parse(event.data);
          console.debug('sse: event', data);
          if (data.terminate) {
            source.close();
            setConnected(false);
          }
          notify(data);
        } catch (e) {
          console.warn('sse: failed to parse server data', e);
        }
      };

      source.onerror = event => {
        console.warn('sse: connection failed ', event);
        notifyError?.(event);
      };

      return () => {
        console.debug('sse: disconnecting');
        source.close();
      };
    }
  }, [url, connected, notify, notifyError, withCredentials]);

  return useCallback(() => {
    setConnected(true);
  }, []);
}
