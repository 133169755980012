import type { VFC } from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import type { PaginationState, SortingState } from '@tanstack/react-table';
import { Col, Placeholder, Row } from '@els/biomed-ui';

import LoaderOverlay from 'components/LoaderOverlay';
import StorageLimit from 'components/Projects/StorageLimit';
import { MAX_FILE_NO } from 'constants/constant';
import useTrackNewPage from 'hooks/useTrackNewPage';
import type { ProjectSortedProps } from 'utils/models';
import { type Network, ProjectActions, UploadStatus } from 'utils/models';
import { getNetworkDetailsPath } from '../constants';
import { useDeleteNetwork, useFetchNetworks, useUpdateNetwork } from '../services/queries';
import { SavedNetworksTable } from './SavedNetworksTable';

const SavedNetworks: VFC = () => {
  const navigate = useNavigate();
  const [sorting, setSorting] = useState<SortingState>(() => [
    { id: 'updateDate' satisfies ProjectSortedProps, desc: true },
  ]);
  const [paging, setPaging] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const { data: workbook, isLoading } = useFetchNetworks({
    page: paging.pageIndex,
    size: paging.pageSize,
    filterByStatus: [UploadStatus.COMPLETED, UploadStatus.FAILED],
    sortBy: sorting[0].id as ProjectSortedProps,
    sortOrder: sorting[0].desc ? 'desc' : 'asc',
  });

  useEffect(() => {
    if (workbook) {
      // E.g. when delete last item from the 2nd+ page.
      if (!workbook.experiments.length) {
        setPaging(paging =>
          paging.pageIndex > 0 ? { ...paging, pageIndex: paging.pageIndex - 1 } : paging
        );
      }
    }
  }, [workbook]);

  useTrackNewPage('network:saved networks');

  const [isDeleting, deleteNetwork] = useDeleteNetwork();
  const [, updateNetwork] = useUpdateNetwork();

  const networks = workbook?.experiments;
  const pageCount = workbook?.page.totalPages ?? 1;

  function changePage(pageIndex: number, pageSize: number) {
    setPaging({ pageIndex: pageIndex - 1, pageSize });
  }

  function performAction(network: Network, actionType: string) {
    switch (actionType) {
      case ProjectActions.OPEN_ANALYSIS: {
        navigate(getNetworkDetailsPath(String(network.id)));
        break;
      }
      case ProjectActions.DELETE_FILE: {
        deleteNetwork(network);
        break;
      }
    }
  }

  function modifyNetwork(
    network: Network,
    keyName: string,
    { title, description }: Partial<Network>
  ) {
    if (keyName === 'title' && network.id && title) {
      updateNetwork({ id: network.id, name: title, description });
    }
  }

  return (
    <Row>
      <Col xs={24}>
        <h1 className='es-font-size-lg'>Network</h1>
        <p>Saved networks</p>

        <Col xs={18} className='mt-4'>
          <StorageLimit count={workbook?.page.totalElements} total={MAX_FILE_NO} />
        </Col>
        <div>
          {!!networks && !networks.length ? (
            <Placeholder className='mt-4'>
              You still haven’t saved any networks. Click on New Project to start a new project.
            </Placeholder>
          ) : (
            <LoaderOverlay on={isDeleting}>
              <SavedNetworksTable
                items={networks ?? []}
                pageCount={pageCount}
                onPageChanged={changePage}
                sorting={sorting}
                onSortingChange={setSorting}
                onAction={performAction}
                onDataUpdate={modifyNetwork}
                isLoading={isLoading}
              />
            </LoaderOverlay>
          )}
        </div>
      </Col>
    </Row>
  );
};

export default SavedNetworks;
