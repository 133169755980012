import type { VFC } from 'react';
import { LinkButton } from '@els/biomed-ui';

import { Trash } from 'assets/icons';
import type { SelectedArticles } from 'utils/models';
import { useResultFragmentContext } from '../ResultFragmentContext';

export interface Props {
  selectedArticles: SelectedArticles;
}

const RemoveArticlesControl: VFC<Props> = ({ selectedArticles }) => {
  const { removeArticles } = useResultFragmentContext();

  const selectedArticlesIds = Object.keys(selectedArticles);

  return (
    <LinkButton
      iconLeft={Trash}
      data-testid='remove-articles-button'
      size='sm'
      inline
      disabled={!selectedArticlesIds.length}
      onClick={() => removeArticles?.(selectedArticles)}
    >
      Remove from list{' '}
      {selectedArticlesIds.length ? `(${selectedArticlesIds.length.toLocaleString()})` : ''}
    </LinkButton>
  );
};

export default RemoveArticlesControl;
