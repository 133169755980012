import type { FunctionComponent, ReactNode } from 'react';
import { useEffect, useRef, useState } from 'react';
import type { BaseType, Bin, Selection } from 'd3';
import { histogram, max, min, scaleLinear, select } from 'd3';

import styles from './HistogramChart.module.scss';

interface Props {
  /** array of numbers */
  data: number[];
  /** data array for second histogram - to compare them e.t.c. */
  originalDataSet?: number[];
  /** limits for histogram if different when [min,max] of data values */
  limitRange?: [number, number];
  /** the component width */
  width?: number;
  /** the component hight */
  height?: number;
  /** histogram color */
  color?: string;
  /** histogram ticks number */
  ticks?: number;
  /** generator one bar tooltip */
  tipGenerator?: (a: Bin<number, number>) => string;
  /** bars in the range will be highlighted */
  highlightedRange?: [number, number];
  /** true to place on top number of objects accumulated for all highlighted bars */
  showHighlightedCount?: boolean;
  /** title before showHighlightedCount */
  selectedCountTitle?: string;
  /** children component to insert on a bottom - for examlpe slider*/
  children?: ReactNode;
  /** true if disabled */
  disabled?: boolean;
  /** draw or not grey mat under histogram (better look for histogram with slider) */
  useMat?: boolean;
  /** histogram color for original data set */
  originalDataSetColor?: string;
  /** histogram color for filtered out bars */
  filteredOutColor?: string;
  /** if true will dynamically add margin to children wrapper */
  useHalfBar?: boolean;
}

export const HistogramChart: FunctionComponent<Props> = ({
  data,
  originalDataSet = [],
  limitRange,
  width,
  height = 150,
  color = '#007398',
  ticks = 30,
  tipGenerator,
  highlightedRange,
  showHighlightedCount = false,
  selectedCountTitle = '',
  children,
  disabled = false,
  useMat = false,
  originalDataSetColor = '#e0e0e0',
  filteredOutColor = '#acacac',
  useHalfBar = false,
}) => {
  const histogramRef = useRef<null | SVGSVGElement>(null);
  const [highlightedCount, setHighlightedCount] = useState(0);
  const [halfBar, setHalfBar] = useState(0);

  const defaultTipGenerator = (d: Bin<number, number>) =>
    `${d.length} objects in a range ${d.x0}-${d.x1}`;

  if (limitRange) {
    const packInLimits = (data: number[]) =>
      data.map(d => (d < limitRange[0] ? limitRange[0] : d > limitRange[1] ? limitRange[1] : d));

    data = packInLimits(data);
    originalDataSet = packInLimits(originalDataSet);
  }

  useEffect(() => {
    const isHighlighted = (d: Bin<number, number>) => {
      if (disabled) return false;
      if (!highlightedRange) return true;
      if (highlightedRange[0] <= highlightedRange[1])
        return !!d.x1 && highlightedRange[0] < d.x1 && !!d.x0 && d.x0 <= highlightedRange[1];
      else return !!d.x0 && highlightedRange[0] > d.x0 && !!d.x1 && d.x1 > highlightedRange[1];
    };

    if (data && histogramRef.current) {
      select(histogramRef.current).selectAll('g').remove();

      const calculatedWidth = width ? width : histogramRef.current.getBoundingClientRect().width;

      const svg = select(histogramRef.current)
        .attr('viewBox', `0 0 ${calculatedWidth} ${height}`)
        .append('g');

      const xd: [number, number] = [
        limitRange && limitRange[0] ? limitRange[0] : min([...data, ...originalDataSet]) || 0,
        (limitRange && limitRange[1] ? limitRange[1] : max([...data, ...originalDataSet]) || 0) + 1,
      ];

      const x = scaleLinear().domain(xd).range([0, calculatedWidth]);

      const histogramChart = histogram()
        .domain(xd)
        .thresholds(x.ticks(min([ticks, xd[1] - xd[0], calculatedWidth])));

      const bins = histogramChart(data);
      const originalBins = histogramChart(originalDataSet);

      const heights = bins.map(d => d.length);
      const moreHeights = originalBins.map(d => d.length);
      const y = scaleLinear()
        .domain([0, max([...heights, ...moreHeights]) || 0])
        .range([height, 0]);

      if (bins && bins.length > 0) {
        setHalfBar(((x(xd[0] + 1) || 0) - (x(xd[0]) || 0)) / 2); //calc and set half width of one tick (one bar if tick == 1)
      }

      const getWidth = (d: Bin<number, number>) => {
        const w = (x(d.x1 || 0) || 0) - (x(d.x0 || 0) || 0);
        return w < 1 ? 1 : w;
      };

      const addRects = (
        selection: Selection<BaseType, unknown, SVGGElement, unknown>,
        bins: Bin<number, number>[],
        key: string,
        getColor: (d: Bin<number, number>) => string
      ) => {
        selection
          .data(bins)
          .enter()
          .append('rect')
          .attr('id', (_, i) => `histo_${key}_bar_${i}`)
          .attr('data-testid', (_, i) => `histo_${key}_bar_${i}`)
          .attr('x', d => (x(d.x0 || 0) || 0) + getWidth(d) * 0.1)
          .attr('y', d => y(d.length) || 0)
          .attr('rx', 1)
          .attr('ry', 1)
          .attr('width', d => getWidth(d) * 0.8)
          .attr('height', (d: Bin<number, number>) => {
            const h = height - (y(d.length) || 0);
            return h === 0 ? 0 : h + 1;
          })
          .style('fill', getColor)
          .append('title')
          .text(tipGenerator || defaultTipGenerator);
      };

      const rects = svg.selectAll('rect');
      addRects(rects, originalBins, 'orig', () => originalDataSetColor);
      addRects(rects, bins, 'cur', d => (isHighlighted(d) ? color : filteredOutColor));

      if (showHighlightedCount) {
        if (!highlightedRange) setHighlightedCount(0);
        else {
          const articlesNumber = bins
            .filter(d => isHighlighted(d))
            .map(d => d.length)
            .reduce(function (acc, val) {
              return acc + val;
            }, 0);
          setHighlightedCount(articlesNumber);
        }
      }
    }
  }, [
    data,
    width,
    height,
    color,
    ticks,
    tipGenerator,
    highlightedRange,
    showHighlightedCount,
    disabled,
    limitRange,
    originalDataSet,
    originalDataSetColor,
    filteredOutColor,
  ]);

  return (
    <div className={styles.elsHistogram}>
      {showHighlightedCount && (
        <div className={styles.highlightedCount}>
          {`${selectedCountTitle}${selectedCountTitle ? ': ' : ''}${highlightedCount}`}
        </div>
      )}
      <svg ref={histogramRef} />
      {useMat && (
        <div className={styles.matWrapper}>
          <div className={styles.mat}></div>
        </div>
      )}
      <div style={useHalfBar ? { margin: `0 ${halfBar}px` } : undefined}>{children}</div>
    </div>
  );
};

export default HistogramChart;
