import { type UIMatch } from 'react-router-dom';
import type { SimulationLinkDatum, SimulationNodeDatum } from 'd3-force';
import { intersection } from 'lodash';

import type { Concept, FacetsResponse, RelationEffect } from 'utils/models';

export interface NetworkPathParams {
  networkId: string;
}

export interface Relation {
  urn: string;
  type: string;
  effect: RelationEffect;
  mechanism?: null | string;
  organism?: null | string[];
  organ?: null | string[];
  tissue?: null | string[];
}

/**
 * The data coming from back-end.
 */
export interface NetworkRelationsResponse {
  relations: NetworkRelation[];
  facets: FacetsResponse;
}

export interface NetworkRelation extends Relation {
  sourceTerm: Concept;
  targetTerm: Concept;
}

export interface ConceptNode extends Concept, SimulationNodeDatum {
  links: RelationLink[];
}

export interface RelationLink extends NetworkRelation, SimulationLinkDatum<ConceptNode> {
  // The number of common localizations between source & target of the link.
  commonLocalizations: number;
  // The direction if the link's arc in chart. Calculate once for performance.
  clockwise: boolean;
  // The index of the link between particular nodes with particular direction (clockwise or not).
  order: number;
  // Total number of links between particular nodes with particular direction (clockwise or not).
  total: number;
}

export interface NetworkData {
  nodes: ConceptNode[];
  links: RelationLink[];
  // Need to scale nodes' sizes.
  minLinks: number;
  maxLinks: number;
  // Need to scale the links' distances.
  maxCommonLocalizations: number;
}

export interface Matches extends Omit<UIMatch, 'handle'> {
  handle: {
    crumb: string;
    crumbPath?: string;
  };
}

export const facetsKeyMap = {
  localization: 'PrimaryCellLocalization',
  termClass: 'TermClass',
  relationTissue: 'Tissue',
  entityType: 'conceptType',
} as const;

export const invertedFacetsKeyMap = Object.fromEntries(
  Object.entries(facetsKeyMap).map(([key, value]) => [value, key])
) as Record<string, string>;

// Type guard to check if a key is a key of FacetsKeyMap
export function isKeyOfFacetsKeyMap<T extends typeof facetsKeyMap | typeof invertedFacetsKeyMap>(
  key: string | keyof T,
  keyMap: T
): key is keyof T {
  return key in keyMap;
}

export function formatData(relations: NetworkRelation[] = []): NetworkData {
  const data: NetworkData = {
    nodes: [],
    links: [],
    minLinks: 0,
    maxLinks: 0,
    maxCommonLocalizations: 0,
  };

  function addNode(node: Concept, link: RelationLink) {
    let foundNode = data.nodes.find(n => n.urn === node.urn);
    if (!foundNode) {
      foundNode = { ...node, links: [] };
      data.nodes.push(foundNode);
    }
    foundNode.links.push(link);
    return foundNode;
  }

  const order: RelationEffect[] = ['undefined', 'unknown', 'positive', 'negative'];
  const orderedRelations = relations
    .slice(0)
    .sort((a, b) => order.indexOf(a.effect) - order.indexOf(b.effect));

  orderedRelations.forEach(relation => {
    const { sourceTerm, targetTerm } = relation;

    const commonLocalizations = intersection(
      sourceTerm.primaryCellLocalization,
      targetTerm.primaryCellLocalization
    ).length;

    const clockwise = ['positive', 'unknown'].includes(relation.effect);
    const otherRelations = orderedRelations.filter(otherRelation => {
      const isClockwise = ['positive', 'unknown'].includes(otherRelation.effect);

      if (relation === otherRelation) {
        return true;
      }

      if (
        otherRelation.sourceTerm.urn === relation.sourceTerm.urn &&
        otherRelation.targetTerm.urn === relation.targetTerm.urn
      ) {
        return clockwise === isClockwise;
      }

      if (
        otherRelation.sourceTerm.urn === relation.targetTerm.urn &&
        otherRelation.targetTerm.urn === relation.sourceTerm.urn
      ) {
        return clockwise !== isClockwise;
      }

      return false;
    });

    const link: RelationLink = {
      ...relation,
      source: sourceTerm.urn,
      target: targetTerm.urn,
      commonLocalizations,
      clockwise,
      order: otherRelations.indexOf(relation),
      total: otherRelations.length,
    };

    addNode(sourceTerm, link);
    addNode(targetTerm, link);

    data.links.push(link);

    if (commonLocalizations > data.maxCommonLocalizations) {
      data.maxCommonLocalizations = commonLocalizations;
    }
  });

  data.nodes.forEach(node => {
    if (node.links.length > data.maxLinks) {
      data.maxLinks = node.links.length;
    }
    if (data.minLinks === 0 || node.links.length < data.minLinks) {
      data.minLinks = node.links.length;
    }
  });

  return data;
}
