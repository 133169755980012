import { type FunctionComponent } from 'react';
import { ButtonLink, Col, Row } from '@els/biomed-ui';

import { signUpForPanelUrl } from 'constants/constant';

import styles from './BottomSection.module.scss';

const BottomSection: FunctionComponent = () => {
  return (
    <Row className={styles.sectionSpacing} data-testid='bottom-section'>
      <Col xs={24} data-testid='bottom-col'>
        <div className={styles.bannerWrapper} data-testid='banner-wrapper'>
          <div className={styles.bannerText} data-testid='banner-text'>
            <h2 data-testid='banner-title'>Share your insights</h2>
            <p className={styles.sectionContent} data-testid='banner-content'>
              Are you a biologist passionate about advancing research?
              <br />
              We invite you to participate in our product research to share your insights and
              <br />
              help us improve your experience and develop solutions that answer your needs!
              <br />
            </p>

            <ButtonLink
              size='md'
              className={styles.signUpButton}
              aria-label='Sign up to our panel'
              data-testid='sign-up-button'
              href={signUpForPanelUrl}
              target='_blank'
            >
              Sign up to our panel
            </ButtonLink>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default BottomSection;
