import type { FC, ReactElement } from 'react';
import { LinkButton, Popover, PopoverContent } from '@els/biomed-ui';

import styles from './SelectPopup.module.scss';

type Props = {
  trigger: ReactElement;
  offset?: [number, number];
  options: Option[];
  onClick: (value: string) => void;
};

export type Option = {
  value: string;
  label: string;
  disabled?: boolean;
};

const SelectPopup: FC<Props> = ({ options, onClick, trigger, offset }) => {
  return (
    <Popover
      placement='bottom-end'
      trigger={trigger}
      theme='menu'
      content={({ toggle, theme }) => (
        <PopoverContent size='sm' padding='sm' theme={theme} closeButton={false}>
          <ul className={styles.content}>
            {options.map(({ value, label, disabled }) => (
              <li key={value}>
                <LinkButton
                  onClick={() => {
                    toggle();
                    onClick(value);
                  }}
                  disabled={disabled}
                  inline
                  className={styles.list}
                  data-testid={`option-${value}`}
                >
                  {label}
                </LinkButton>
              </li>
            ))}
          </ul>
        </PopoverContent>
      )}
      offset={offset}
    />
  );
};

export default SelectPopup;
