import { Children } from 'react';
import { escapeRegExp } from 'lodash';

import type { SuggestionsResponse } from './AutoCompleteWrapper';

import styles from './AutoCompleteWrapper.module.scss';

export const emphasizeText = (text: string, value: string) => {
  const regExpression = new RegExp(`(${escapeRegExp(value)})`, 'gi');
  const parts = text.split(regExpression);

  // Keys are set automatically by React
  return Children.map(parts, part => {
    if (regExpression.test(part)) {
      return <mark>{part}</mark>;
    } else {
      return part;
    }
  });
};

export const renderAutoCompleteItem =
  (value: string, renderFullSuggestion: boolean) => (suggestion: SuggestionsResponse) => {
    return (
      <div className={`notranslate ${styles.suggestionTermWrapper}`}>
        <div className={styles.suggestionContainer}>
          {renderFullSuggestion && !!suggestion.synonym && (
            <>
              <span data-testid='synonym' className={styles.synonym}>
                {emphasizeText(suggestion.synonym, value)}
              </span>
              <span className='use-label'>{` use: `}</span>
            </>
          )}
          <span
            data-testid='useTerm'
            className={
              suggestion.synonym && renderFullSuggestion ? styles.highLightResponse : undefined
            }
          >
            {emphasizeText(suggestion.useTerm, value)}
          </span>
        </div>
        {renderFullSuggestion && (
          <div data-testid='hits' className={styles.suggestionHits}>
            {suggestion.hits.toLocaleString()}
          </div>
        )}
      </div>
    );
  };
