import type { ColumnDef } from '@tanstack/react-table';

import type { Project, ProjectSortedProps } from 'utils/models';
import { UploadStatus } from 'utils/models';
import FileNameTemplate from '../FileNameTemplate';

export function createNameColumn<T extends Project>(options?: Partial<ColumnDef<T>>): ColumnDef<T> {
  return {
    header: 'Name',
    accessorKey: 'title' satisfies ProjectSortedProps,
    cell: ({ table, row: { original: project, index }, column }) => {
      return (
        <FileNameTemplate
          key={index}
          index={index}
          name={project.title}
          description={project.description}
          onDataUpdate={(title, description) => {
            if (title) {
              table.options.meta?.onDataUpdate(project, column.id, {
                title,
                description,
              } as Partial<T>);
            }
          }}
          disabled={project.status === UploadStatus.UPLOADING}
        />
      );
    },
    ...options,
  };
}
