import type { ColumnDef } from '@tanstack/react-table';
import { Progress } from '@els/biomed-ui';

import { Delete } from 'assets/icons';
import { ProgressBar } from 'components/ProgressBar';
import StatusCue from 'components/StatusCue';
import type { Project, ProjectSortedProps } from 'utils/models';
import { UploadStatus } from 'utils/models';

export function createUploadStatusColumn<T extends Project>(
  options?: Partial<ColumnDef<T>>
): ColumnDef<T> {
  return {
    header: 'Status',
    id: 'experimentStatus' satisfies ProjectSortedProps,
    accessorKey: 'status',
    cell: ({ row: { original: project } }) => {
      switch (project.status) {
        case UploadStatus.UPLOADING:
          return (
            <ProgressBar
              data-testid='upload-progress'
              size='md'
              value={project.progressValue}
              showValue
            />
          );
        case UploadStatus.FAILED:
          return (
            <StatusCue icon={Delete} size='sm' theme='warn' data-testid='upload-failed'>
              Failed
            </StatusCue>
          );
        case UploadStatus.UPLOADED:
        case UploadStatus.ANALYSING:
          return (
            <Progress data-testid='mapping-progress' size='sm' contentSize='xs'>
              Analyzing...
            </Progress>
          );
        default:
          return (
            <StatusCue size='sm' data-testid='upload-completed'>
              Completed
            </StatusCue>
          );
      }
    },
    size: 200,
    ...options,
  };
}
