import type { FC, VFC } from 'react';
import type { TestProps } from '@els/biomed-ui';

import type { ClinicalData } from 'utils/models';

import styles from './ClinicalInfoPanel.module.scss';

interface Props {
  clinicalData: ClinicalData | null;
}

const ClinicalInfoPanel: VFC<Props> = ({ clinicalData }) => (
  <div data-testid='clinical-info-panel' className={styles.root}>
    <div className='mb-3'>
      <h6>CONDITION(S):</h6>
      <List items={clinicalData?.conditions} data-testid='clinical-condition' />
    </div>
    <div className='mb-3'>
      <h6>INTERVENTION(S)/DRUG TYPE(S):</h6>
      <List items={clinicalData?.interventions} data-testid='clinical-intervention' />
    </div>
    <div className='mb-3'>
      <h6>TRIAL STATE:</h6>
      <Item data-testid='clinicalTrial-state'>{clinicalData?.status}</Item>
    </div>
    <div className='mb-3'>
      <h6>PHASES:</h6>
      <List items={clinicalData?.phases} data-testid='clinical-phases' />
    </div>
    <div className='mb-3'>
      <h6>STUDY TYPE:</h6>
      <Item data-testid='clinical-study-type'>{clinicalData?.studyType}</Item>
    </div>
    <div className='mb-3'>
      <h6>STUDY SPONSOR:</h6>
      <Item data-testid='clinical-study-sponsor'>{clinicalData?.sponsor}</Item>
    </div>
    <div className='mb-3'>
      <h6>STUDY COLLABORATOR:</h6>
      <Item data-testid='clinical-study-collaborator'>{clinicalData?.collaborator}</Item>
    </div>
  </div>
);

const List: VFC<TestProps & { items?: string[] }> = ({ 'data-testid': testId, items }) =>
  items?.length ? (
    <ul>
      {items.map((item, index) => (
        <li key={index} data-testid={`${testId}-${index}`}>
          {item}
        </li>
      ))}
    </ul>
  ) : (
    <Item data-testid={`${testId}-non`} />
  );

const Item: FC<TestProps> = ({ 'data-testid': testId, children }) => (
  <p data-testid={testId}>{children || 'Information not provided'}</p>
);

export default ClinicalInfoPanel;
