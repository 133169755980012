import type { FC } from 'react';
import { breakpoints, Button, ElsevierProductLogo, Header as GlobalHeader } from '@els/biomed-ui';

import { Logo } from 'assets/icons';
import { headerNavLinks, supportHubUrl, tokenKey } from 'constants/constant';
import useAuthData from 'hooks/useAuthData';
import { useMediaQueryMaxWidth } from 'hooks/useMediaQueryMaxWidth';
import { removeLocalData } from 'utils/storage';
import HProfile from './HProfile';
import HUtils from './HUtils';
import Nav from './Nav';

import styles from './Header.module.scss';

// TODO: add burger to mobile panel
const Header: FC = () => {
  const isReduceSize = useMediaQueryMaxWidth(breakpoints.xl);

  const {
    isAuthorized,
    isRegistered,
    signinRedirect,
    signoutRedirect,
    userInfo,
    removeUser,
    idToken,
  } = useAuthData();

  const handleSignOut = async () => {
    await removeUser();
    signoutRedirect({ id_token_hint: idToken });
    removeLocalData(tokenKey);
  };

  return (
    <GlobalHeader
      className={styles.root}
      id='header'
      data-testid='global-header'
      size={isReduceSize ? 'sm' : 'md'}
      brand={
        <ElsevierProductLogo id='header-brand' href='/' alt={'Embiology'}>
          <Logo className={styles.logo} />
        </ElsevierProductLogo>
      }
      nav={isAuthorized && <Nav list={headerNavLinks} />}
      profile={
        isRegistered ? (
          <HProfile
            email={userInfo?.email || ''}
            name={userInfo?.name || ''}
            onSignOut={handleSignOut}
          />
        ) : (
          <Button className={styles.signButton} onClick={() => signinRedirect()}>
            Sign In
          </Button>
        )
      }
      utils={isRegistered && <HUtils helpRef={supportHubUrl} />}
    />
  );
};

export default Header;
