import type { FC } from 'react';

import Mark from 'components/Mark';
import { useResultFragmentContext } from '../ResultFragmentContext';
import {
  escapeSpecialChars,
  expandMatchIndex,
  normalizeText,
  regexpFromSearchTerms,
} from './utils';

import styles from './HighlightText.module.scss';

interface Props {
  className?: string;
  text: string;
}

export const HighlightText: FC<Props> = ({ text }) => {
  const { searchTerms = [], isHighlightingEnabled = true } = useResultFragmentContext();

  if (!isHighlightingEnabled) {
    return <span>{text}</span>;
  }

  const highlightedText = highlightText(text, searchTerms);
  return <span>{highlightedText}</span>;
};

function highlightText(text: string, searchTerms: string[]) {
  const filteredTerms = searchTerms.filter(term => term.trim() !== '');

  if (!filteredTerms.length) return text;

  const regex = regexpFromSearchTerms(filteredTerms);

  const { normalized: normalizedText, mapping } = normalizeText(text);

  const normalizedSearchTerms = filteredTerms.map(term =>
    term.replace(/[^a-zA-Z0-9\s]/g, '').toLowerCase()
  );

  const highlighted: string[] = [];
  const highlightedRegions: number[][] = [];

  //step-1. Try to find matches with regexp
  text.replace(regex, (match, ...args) => {
    const matchIndex = args[args.length - 2];

    highlighted.push(match.trim());

    highlightedRegions.push([matchIndex, matchIndex + match.length]); // Save highlighted range

    return match;
  });

  //step-2. Try to find matches ignoring special characters
  normalizedSearchTerms.forEach(term => {
    if (!term) return; // Skip if the term is empty

    let matchIndex = normalizedText.indexOf(term);

    while (matchIndex !== -1) {
      const originalStart = expandMatchIndex(mapping, matchIndex, 'start');
      const originalEnd = expandMatchIndex(mapping, matchIndex + term.length, 'end');

      // Check if this region overlaps any already-highlighted region
      const overlaps = highlightedRegions.some(
        ([start, end]) =>
          (originalStart >= start && originalStart < end) ||
          (originalEnd > start && originalEnd <= end)
      );
      if (!overlaps) {
        const match = text.slice(originalStart, originalEnd);

        highlighted.push(match.trim());
      }

      matchIndex = normalizedText.indexOf(term, matchIndex + term.length);
    }
  });
  // Prepare final text with replaced search terms
  let lastIndex = 0;
  const resultText: React.ReactNode[] = [];
  const highlightRegexp = new RegExp(
    `${highlighted.map(term => escapeSpecialChars(term)).join('|')}`,
    'gi'
  );

  text.replace(highlightRegexp, (match, ...args) => {
    const matchIndex = args[args.length - 2];

    // Add non-matching part of text before the current match
    if (matchIndex > lastIndex) {
      resultText.push(text.slice(lastIndex, matchIndex));
    }

    // Push the highlighted term as a <Mark />
    resultText.push(<Mark key={matchIndex} className={styles.highlightedText} text={match} />);

    // Update the last index to prevent duplication
    lastIndex = matchIndex + match.length;

    return match;
  });

  // Add any remaining part of the text after the last match
  if (lastIndex < text.length) {
    resultText.push(text.slice(lastIndex));
  }

  return highlighted.length ? resultText : text;
}

export default HighlightText;
