import type { VFC } from 'react';
import { Icon } from '@els/biomed-ui';

import { File } from 'assets/icons';
import { ProgressBar } from '../../ProgressBar';

interface Props {
  count?: number;
  total: number;
}

/**
 * Indicates the available user storage.
 */
const StorageLimit: VFC<Props> = ({ count = 0, total }) => {
  return (
    <ProgressBar
      size='lg'
      value={count}
      showProgressValue
      maxValue={total}
      data-testid='storage-limit'
      aria-label={`Storage limit: ${count} of ${total}`}
    >
      <Icon name={File} size='lg' className='es-color-grey8' />
    </ProgressBar>
  );
};

export default StorageLimit;
