import type { VFC } from 'react';
import { useEffect, useState } from 'react';
import cn from 'classnames';
import { Input, LinkButton, TextArea } from '@els/biomed-ui';

import { Check, Pencil } from 'assets/icons';

import styles from './FileNameTemplate.module.scss';

interface Props {
  name: string;
  description?: string | null;
  onDataUpdate: (name: string, description: string | null) => void;
  index?: number;
  disabled?: boolean;
}

// TODO: allow to reset data when update is failed.
const FileNameTemplate: VFC<Props> = ({
  name: initialName,
  description: initialDescription,
  onDataUpdate,
  index,
  disabled,
}) => {
  const [isEditing, setEditing] = useState(false);
  const [name, setName] = useState(initialName);
  const [description, setDescription] = useState(initialDescription || '');

  // I.e. rendered not in list, but as page header.
  // Which means the name should be shown in h1 tag.
  const standalone = index === undefined;

  useEffect(() => {
    setName(initialName);
    setDescription(initialDescription || '');
  }, [initialName, initialDescription]);

  const handleSave = () => {
    if (name) {
      onDataUpdate(name, description);
      setEditing(false);
    }
  };

  const nameTestId = `file-name-${index ?? 0}`;
  const descriptionTestId = `file-description-${index ?? 0}`;

  return (
    <div>
      {isEditing ? (
        <div className={styles.form}>
          <div className={styles.nameContainer}>
            <Input
              data-testid={nameTestId}
              label={<span className='sr-only'>Experiment name</span>}
              value={name}
              onChange={e => setName(e.target.value)}
              size='sm'
              className={styles.nameInput}
              autoFocus
              placeholder='Enter your experiment name'
              placeholderPosition='input'
              error={name ? undefined : 'Please add a name to this list.'}
              maxLength={100}
            />
            <LinkButton iconLeft={Check} title='update' size='xs' onClick={handleSave} />
          </div>
          <TextArea
            data-testid={descriptionTestId}
            label={<span className='sr-only'>Experiment description</span>}
            value={description}
            onChange={e => setDescription(e.target.value)}
            size='xs'
            placeholder='Enter your experiment description'
            placeholderPosition='input'
            minRows={1}
            maxLength={150}
          />
        </div>
      ) : (
        <div>
          <div className={styles.nameContainer}>
            {standalone ? (
              <h1 className='es-font-size-lg' data-testid={nameTestId}>
                {name}
              </h1>
            ) : (
              <span data-testid={nameTestId}>{name}</span>
            )}
            <LinkButton
              data-testid='edit-experiment'
              iconLeft={Pencil}
              title='edit'
              size='xs'
              disabled={disabled}
              onClick={() => setEditing(true)}
            />
          </div>
          <p
            data-testid={descriptionTestId}
            className={cn(styles.description, !description && styles.empty)}
          >
            {description || 'Click edit icon to enter your experiment description.'}
          </p>
        </div>
      )}
    </div>
  );
};

export default FileNameTemplate;
