import type { VFC } from 'react';
import cn from 'classnames';
import { Checkbox, Link, LinkButton } from '@els/biomed-ui';

import { NavigateRight } from 'assets/icons';
import InfoTooltip from 'components/InfoTooltip';
import type { Article } from 'utils/models';
import { isClinicalTrial } from 'utils/models';
import { getMonthName } from 'utils/timeAndDate';
import HighlightText from '../HighlightText';

import styles from './ListItem.module.scss';

interface ActionLink {
  value: string;
  label: string;
  isExternal?: boolean;
  type?: string;
}

const actionLinks: ActionLink[] = [
  { value: 'abstract', label: 'Abstract' },
  { value: 'relation', label: 'Relations' },
  { value: 'info', label: 'Info' },
  { value: 'viewText', label: 'Full text', isExternal: true },
];

interface Props {
  offset: number;
  index: number;
  item: Article;
  onCheckItem: (articleId: string, checked: boolean) => void;
  onActionClick: (article: Article, tab: string) => void;
  selected?: boolean;
  selectedTab?: string;
  checked?: boolean;
  isHighlightingEnabled?: boolean;
}

export const ListItem: VFC<Props> = ({
  offset,
  index,
  item,
  checked,
  onCheckItem,
  selected,
  selectedTab,
  onActionClick,
  isHighlightingEnabled = true,
}) => {
  const { source, pubInfo } = item;
  const isClinicalArticle = isClinicalTrial(item);

  const pubDate = `
    ${pubInfo.date.day ?? ''} 
    ${pubInfo.date.month ? getMonthName(pubInfo.date.month) : ''} ${pubInfo.date.year}
  `;

  const indexId = `item-index-${item.id}`;

  return (
    <div className={styles.root}>
      <div className={styles.checkboxContainer}>
        <Checkbox
          aria-labelledby={indexId}
          data-testid={`list-item-check-${index}`}
          size='sm'
          disabled={!item.content.title}
          checked={checked}
          onChange={e => onCheckItem(item.id, e.target.checked)}
          className={styles.checkbox}
        />
        <span id={indexId} className={styles.index} data-testid={`list-item-${index}`}>
          {(offset + index + 1).toLocaleString()}
        </span>
      </div>
      <div
        className={cn(styles.item, selected && styles.itemSelected)}
        data-testid={`list-item-content-${index}`}
      >
        <div className={styles.pubType} data-testid={`list-item-pub-type-${index}`}>
          {item.pubType}
        </div>
        <h1 className={styles.itemTitle} data-testid={`list-item-title-${index}`}>
          <HighlightText text={item.content.title} />
        </h1>
        <div className={styles.itemSubTitle} data-testid={`list-item-subheader-${index}`}>
          {isClinicalArticle ? (
            <span data-testid={`list-item-clinical-trial-${index}`}>
              <HighlightText text={source?.title || 'ClinicalTrials.gov'} />
            </span>
          ) : (
            <dl>
              {source?.title && (
                <dd data-testid={`list-item-journal-${index}`}>
                  <HighlightText text={source?.title} />
                </dd>
              )}

              {source?.volume && (
                <dd data-testid={`list-item-volume-${index}`}>volume {source.volume}</dd>
              )}

              {source?.firstPage && source?.lastPage && (
                <dd data-testid={`list-item-pages-${index}`}>
                  Pages {source.firstPage}-{source.lastPage}
                </dd>
              )}

              <dd data-testid={`list-item-date-${index}`}>
                <HighlightText text={pubDate} />
              </dd>

              {source?.articleNumber && (
                <dd data-testid={`list-item-article-number-${index}`}>
                  Article {source.articleNumber}
                </dd>
              )}
            </dl>
          )}
        </div>

        {isClinicalArticle ? (
          <div className={styles.clinicalDate}>
            <p className='font-weight-bold' data-testid={`list-item-study-sponsor-${index}`}>
              Study Sponsor: {item.clinicalTrialData?.sponsor}
            </p>
            <p data-testid={`list-item-start-date-${index}`}>Start date: {pubDate}</p>
          </div>
        ) : (
          <ul className={styles.authorsList} data-testid={`list-item-authors-list-${index}`}>
            {item.authors.map((author, authorIndex) => (
              <li
                key={`author-${item.id}-${authorIndex}`}
                data-testid={`list-item-author-${authorIndex}-${index}`}
              >
                <HighlightText text={`${author.initials ?? ''} ${author.surname ?? ''}`} />
              </li>
            ))}
          </ul>
        )}

        <div className={styles.actions} data-testid='action-links'>
          {actionLinks
            .filter(actionLink =>
              isClinicalArticle ? actionLink.value !== 'abstract' : actionLink.value !== 'info'
            )
            .filter(actionLink => (!item.fullTextLink ? actionLink.value !== 'viewText' : true))
            .map(actionLink => {
              const actionSelected = selected && actionLink.value === selectedTab;
              const isRelationHighlighted =
                actionLink.value === 'relation' &&
                (item?.freeTextSearch?.isPrimaryRelationHasToken ||
                  item?.freeTextSearch?.isSecondaryRelationHasToken);
              const isAbstractHighlighted =
                actionLink.value === 'abstract' && item?.freeTextSearch?.isAbstractContainsTokens;
              const props = {
                key: actionLink.value,
                'data-testid': `${actionLink.value}-${item.id}`,
                className: cn(
                  styles.action,
                  isHighlightingEnabled && isRelationHighlighted && styles.highlightLink,
                  isHighlightingEnabled && isAbstractHighlighted && styles.highlightLink,
                  actionSelected && styles.actionSelected
                ),
                selected: actionSelected,
                colored: true,
              };
              return actionLink.isExternal ? (
                <Link
                  href={item.fullTextLink}
                  onClick={e => {
                    // Prevent sidebar from closing
                    e.stopPropagation();
                  }}
                  external
                  {...props}
                >
                  {isClinicalArticle ? 'Record details' : actionLink.label}
                </Link>
              ) : (
                <LinkButton
                  iconRight={NavigateRight}
                  inline
                  onClick={e => {
                    // Prevent sidebar from closing
                    e.stopPropagation();
                    onActionClick(item, actionLink.value);
                  }}
                  {...props}
                >
                  {actionLink.value === 'relation'
                    ? `${actionLink.label}: ${item.relationCount}`
                    : actionLink.label}
                </LinkButton>
              );
            })}
        </div>
      </div>

      {!item.content.title && (
        <InfoTooltip
          size='md'
          placement='top-end'
          content='Unfortunately, not all data about this document was available.'
        />
      )}
    </div>
  );
};
