import { type FunctionComponent, useMemo } from 'react';
import { LinkButton, Modal, ModalButtons, ModalContent, ModalHeader, toast } from '@els/biomed-ui';

import { CloudDownload, Info } from 'assets/icons';
import { conceptFilters } from 'constants/constant';
import type { Operator, ReferenceCountRange, SavedFilters, YearRange } from 'utils/models';
import { SelectionStatus } from 'utils/models';

interface Props {
  searchTerms: string[];
  searchQuery: string;
  operator?: Operator;
  appliedFilters?: SavedFilters | null;
  onClose: () => void;
}

const EXTERNAL_DEFAULT_OPERATOR = 'AND';

const formatYearRange = (yearRange: YearRange) => {
  const start = yearRange.start ?? 1968;
  const end = yearRange.end ?? 2024;
  return `(Years ${start}:${end})`;
};

const formatReferenceRange = (refRange: ReferenceCountRange) => {
  const minimum = refRange.minimum ?? 1;
  const maximum = refRange.maximum ?? '10+';
  return `(References ${minimum}:${maximum})`;
};

const appliedFilterString = (appliedFilters: SavedFilters) => {
  const filterStrings: string[] = [];
  const defaultConceptOperator = 'OR';

  for (const [key, value] of Object.entries(appliedFilters)) {
    if (key === 'years' && value) {
      filterStrings.push(formatYearRange(value satisfies YearRange));
    } else if (key === 'refNum' && value) {
      filterStrings.push(formatReferenceRange(value satisfies ReferenceCountRange));
    } else if (value === SelectionStatus.ALL) {
      filterStrings.push(conceptFilters[key as keyof typeof conceptFilters]);
    } else if (Array.isArray(value)) {
      filterStrings.push(`(${value.join(` ${defaultConceptOperator} `)})`);
    }
  }

  return filterStrings.join(` ${EXTERNAL_DEFAULT_OPERATOR} `);
};

const QueryModal: FunctionComponent<Props> = ({
  searchTerms,
  appliedFilters = null,
  searchQuery,
  operator,
  onClose,
}) => {
  const fullQueryString = useMemo(() => {
    const queryParts: string[] = [];
    const filterStrings = appliedFilters ? appliedFilterString(appliedFilters) : '';

    queryParts.push(searchQuery);

    filterStrings && queryParts.push(filterStrings);
    queryParts.push(`(${searchTerms.join(` ${operator} `)})`);

    return queryParts.join(` ${EXTERNAL_DEFAULT_OPERATOR} `);
  }, [searchQuery, searchTerms, appliedFilters, operator]);

  const handleCopyQuery = async () => {
    try {
      await navigator.clipboard.writeText(fullQueryString);
      toast.success('The query has been copied');
    } catch (err) {
      toast.error('Failed to copy query');
    }
  };

  return (
    <Modal data-testid='query-modal' width='50vw' isOpen onClose={onClose} preventBackgroundScroll>
      <ModalContent size='sm'>
        <ModalHeader>Query Structure</ModalHeader>
        <p>Your query is built as follows:</p>
        <p data-testid='query'>{fullQueryString}</p>
        <ModalButtons>
          <LinkButton iconLeft={Info} onClick={handleCopyQuery} data-testid='copy-query'>
            Copy query
          </LinkButton>
          <LinkButton
            iconLeft={CloudDownload}
            onClick={() => toast.success('The query has been exported as .csv')}
            data-testid='export-query'
          >
            Export (.csv)
          </LinkButton>
        </ModalButtons>
      </ModalContent>
    </Modal>
  );
};

export default QueryModal;
