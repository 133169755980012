import type { FunctionComponent } from 'react';
import { useState } from 'react';

import styles from './PageSize.module.scss';

interface Props {
  pageSize?: number;
  pageSizeOptions?: number[];
  pageSizeLabelPrefix?: string;
  pageSizeLabelSuffix?: string;
  onPageSizeChange?: (pageSize: number) => void;
}

// TODO: The component can be controlled: no need to have an internal state.
const PageSize: FunctionComponent<Props> = ({
  pageSize = 10,
  onPageSizeChange,
  pageSizeOptions = [10, 20, 30],
  // TODO: These two props below don't work atm. Should we fix or removed them?
  pageSizeLabelPrefix = 'Display:',
  pageSizeLabelSuffix = 'results per page',
}) => {
  const [curPageSize, setCurPageSize] = useState(pageSize);

  return (
    <div className={styles.elsPageSize}>
      <ul
        className='els-page-size-option-items prefix suffix'
        data-prefix={pageSizeLabelPrefix}
        data-suffix={pageSizeLabelSuffix}
      >
        {pageSizeOptions.map((option, index) => (
          <li
            tabIndex={index}
            key={`p-size-opt-${option}`}
            aria-current={curPageSize === option}
            onClick={() => {
              if (curPageSize === option) {
                return;
              }
              setCurPageSize(option);
              onPageSizeChange && onPageSizeChange(option);
            }}
          >
            <span
              className={`${
                curPageSize === option ? styles.activePerPage : styles.nonActivePerPage
              }`}
            >
              {option}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PageSize;
