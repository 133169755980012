import React from 'react';
import { Navigate, Outlet, Route } from 'react-router-dom';

import LazyLoad from 'components/LazyLoad';
import content from 'constants/content';
import BootstrapContainer from 'containers/Bootstrap';
import CookiesPolicy from 'pages/CookiesPolicy';
import { baseGenesAnalysisPath } from 'pages/GenesAnalysis/constants';
import LandingPage from 'pages/LandingPage';
import {
  baseNetworksPath,
  networkDetailsPath,
  networkResultsPath,
  savedNetworksPath,
  uploadNetworkPath,
} from 'pages/Network/constants';
import FileUpload from 'pages/Network/FileUpload';
import NetworkDetails from 'pages/Network/NetworkDetails';
import NetworkResults from 'pages/Network/NetworkResults';
import SavedNetworks from 'pages/Network/SavedNetworks';
import Search from 'pages/Search';
import AuthLayout from './LayoutRoutes';

const ResultModule = React.lazy(() => import('pages/Result'));
const ReferenceModule = React.lazy(() => import('pages/Reference'));
const SavedArticlesModule = React.lazy(() => import('pages/SavedArticles'));
const GenesAnalysisModule = React.lazy(() => import('pages/GenesAnalysis'));
const NetworkModule = React.lazy(() => import('pages/Network'));

const SearchPage = () => <Search staticContent={content.searchPage} />;
const LazyResultsPage = () => (
  <LazyLoad component={ResultModule} staticContent={content.resultPage} />
);
const LazyReferencePage = () => <LazyLoad component={ReferenceModule} />;
const LazySavedArticlesPage = () => <LazyLoad component={SavedArticlesModule} />;
const LazyGenesAnalysisPage = () => <LazyLoad component={GenesAnalysisModule} />;
const LazyNetworkModule = () => <LazyLoad component={NetworkModule} />;

const Root = (
  <Route element={<BootstrapContainer />}>
    <Route element={<AuthLayout />}>
      <Route path='/search' element={<SearchPage />} />
      <Route path='/result' element={<LazyResultsPage />} />
      <Route path='/reference' element={<LazyReferencePage />} />
      <Route path='/saved' element={<LazySavedArticlesPage />} />
      <Route
        path={`${baseNetworksPath}/*`}
        element={<LazyNetworkModule />}
        handle={{ crumb: 'Upload', crumbPath: `${baseNetworksPath}/${uploadNetworkPath}` }}
      >
        <Route index element={<Navigate to={uploadNetworkPath} replace />} />
        <Route path={uploadNetworkPath} element={<FileUpload />} />
        <Route
          path={networkDetailsPath}
          element={<Outlet />}
          handle={{ crumb: 'Concepts mapping' }}
        >
          <Route index element={<NetworkDetails />} />
          <Route
            path={networkResultsPath}
            element={<NetworkResults />}
            handle={{ crumb: 'Generate network' }}
          />
        </Route>
        <Route path={savedNetworksPath} element={<SavedNetworks />} />
        <Route path='*' element={<Navigate to={`${baseNetworksPath}`} replace />} />
      </Route>
      <Route path={`/${baseGenesAnalysisPath}/*`} element={<LazyGenesAnalysisPage />} />
    </Route>
    <Route path='/legal/cookies-policy' element={<CookiesPolicy />} />
    <Route path='/login' element={<LandingPage />} />
    <Route path='*' element={<Navigate to='/search' replace />} />
  </Route>
);

export default Root;
