import type { VFC } from 'react';
import cn from 'classnames';
import { Icon } from '@els/biomed-ui';

import { NavigateDown } from 'assets/icons';

import styles from './ShowMore.module.scss';

interface Props {
  visible?: boolean;
}

const ShowMore: VFC<Props> = ({ visible = true }) => (
  // Animate show/hide, always keep in DOM.
  <div className={cn(styles.root, visible && styles.visible)}>
    <div className={styles.iconContainer}>
      <Icon className={styles.icon} name={NavigateDown} />
    </div>
    <p className={styles.label}>Scroll to view more</p>
  </div>
);

export default ShowMore;
