import type { ColumnDef } from '@tanstack/react-table';

import type { Entry } from 'utils/models';

export function createRelationsColumn<T extends Entry>(
  options?: Partial<ColumnDef<T>>
): ColumnDef<T> {
  return {
    header: 'Total references',
    accessorKey: 'concept.totalRelations',
    meta: { className: 'text-center', 'data-testid': 'total-references' },
    cell: ({ row: { original: entry } }) => {
      const { concept } = entry;
      return (concept.totalRelations ?? 0).toLocaleString();
    },
    size: 150,
    ...options,
  };
}
