import type { VFC } from 'react';
import { useMemo } from 'react';
import type { ColumnDef } from '@tanstack/react-table';

import { createDeleteAction } from 'components/Projects/ProjectsTableActions';
import {
  createActionsColumns,
  createNameColumn,
  createUpdatedDateColumn,
  createUploadStatusColumn,
} from 'components/Projects/ProjectsTableColumns';
import { Table, type TableProps } from 'components/Table';
import type { RowActionProps } from 'components/Table/Columns';
import type { Network } from 'utils/models';

interface Props
  extends Pick<
    TableProps<Network, unknown>,
    | 'isLoading'
    | 'items'
    | 'pageCount'
    | 'onPageChanged'
    | 'sorting'
    | 'onSortingChange'
    | 'onAction'
    | 'onDataUpdate'
  > {}

export const SavedNetworksTable: VFC<Props> = props => {
  const columns: ColumnDef<Network>[] = useMemo(
    () => [
      createNameColumn(),
      createUploadStatusColumn(),
      createUpdatedDateColumn(),
      createActionsColumns(),
    ],
    []
  );

  const actions: RowActionProps<Network>[] = useMemo(() => [createDeleteAction()], []);

  return (
    <Table
      index
      columns={columns}
      renderHeader
      rowActions={actions}
      pagination
      manualPagination
      stickyHeader
      {...props}
    />
  );
};
