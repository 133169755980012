import type { PaginationState } from '@tanstack/react-table';

import type { Operator } from 'utils/models';
import type { NetworkFilters } from './services/facets';

export type NetworkState = {
  selectedFilters: NetworkFilters;
  pageInfo: PaginationState;
  searchTerms?: string[];
  operator?: Operator;
};

export type Action =
  | { type: 'update-filter-selection'; payload: NetworkFilters }
  | { type: 'reset-filter-selection' }
  | { type: 'update-free-text-tokens'; payload: string[] | undefined }
  | { type: 'update-operator'; payload: Operator }
  | { type: 'update-page-info'; payload: PaginationState };

export const networkReducer = (state: NetworkState, action: Action): NetworkState => {
  switch (action.type) {
    case 'update-filter-selection': {
      const { payload } = action;
      return {
        ...state,
        selectedFilters: payload,
        searchTerms: undefined,
        operator: 'AND',
        pageInfo: { ...state.pageInfo, pageIndex: 0 },
      };
    }
    case 'reset-filter-selection': {
      return {
        ...state,
        selectedFilters: {},
        searchTerms: undefined,
        operator: 'AND',
        pageInfo: { ...state.pageInfo, pageIndex: 0 },
      };
    }
    case 'update-free-text-tokens': {
      return {
        ...state,
        searchTerms: action.payload,
        pageInfo: { ...state.pageInfo, pageIndex: 0 },
      };
    }
    case 'update-operator': {
      return {
        ...state,
        operator: action.payload,
      };
    }
    case 'update-page-info': {
      const { payload } = action;
      return {
        ...state,
        pageInfo: payload,
      };
    }
    default:
      return state;
  }
};
