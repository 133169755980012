/**
 * get current date
 */
export const currentDate = new Date();

/**
 * get year of current month if month is lesser than 10 otherwise nex year value
 */
export const calculatedYear =
  currentDate.getMonth() > 10 ? currentDate.getFullYear() + 1 : currentDate.getFullYear();

/**
 * get month name
 */

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const getMonthName = (month: number) => months[month - 1];

export const formatDate = (dateString?: string) => {
  const date = dateString ? new Date(dateString) : new Date();
  const options: any = { day: 'numeric', month: 'numeric', year: 'numeric' };
  const formattedDate = date.toLocaleDateString('en-GB', options);
  return formattedDate;
};
