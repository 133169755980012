import type { VFC } from 'react';
import { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';

import type { Category } from 'components/ActiveFilterPanel';
import ActiveFilterPanel, { ActiveFilterOperation } from 'components/ActiveFilterPanel';
import {
  defaultYearForSlider,
  filterGroupCategoryMapping,
  filterGroupKeyMapping,
} from 'constants/constant';
import type { ReferenceCountRange, YearRange } from 'utils/models';
import { calculatedYear } from 'utils/timeAndDate';
import type { NetworkFacet, NetworkFilters } from '../../services/facets';
import { networkFacetLabels, networkFacets } from '../../services/facets';

interface Props {
  facets: NetworkFilters;
  onChange: (facets: NetworkFilters) => void;
}

export const ActiveFilters: VFC<Props> = ({ facets, onChange }) => {
  const activeFilters = useMemo<Category[]>(() => {
    if (isEmpty(facets)) {
      return [];
    }

    const categories: Category[] = [];

    for (const facet of networkFacets) {
      const values = facets[facet];
      if (!values) {
        continue;
      }

      const categoryId = Object.entries(filterGroupCategoryMapping).find(([_, filters]) =>
        filters.includes(facet)
      )?.[0];

      if (!categoryId) {
        console.warn(`Could not find category for ${facet}`);
        continue;
      }

      let category = categories.find(c => c.type === categoryId);
      if (!category) {
        category = {
          name: filterGroupKeyMapping[categoryId],
          type: categoryId,
          filters: [],
        };
        categories.push(category);
      }

      const config = networkFacetLabels[facet];
      // TODO: handle publication filters
      const getValue = (
        values: string[] | YearRange | ReferenceCountRange,
        configObj: typeof config
      ) => {
        if (values instanceof Array) {
          return `${values.length === 1 ? configObj.options[values[0]]?.label ?? values[0] : values.length}`;
        } else if ('start' in values || 'end' in values) {
          if (values.start === values.end) {
            return `${values.start}`;
          } else {
            return values.start === undefined
              ? `< ${defaultYearForSlider} to ${values.end}`
              : values.end === undefined
                ? `${values.start}-${calculatedYear}`
                : `${values.start}-${values.end}`;
          }
        } else if ('minimum' in values || 'maximum' in values) {
          if (values.minimum === values.maximum) {
            return `${values.minimum}`;
          } else if (values.maximum) {
            return `${values.minimum || 1} to ${values.maximum}`;
          } else {
            return `${values.minimum || 1} to ${values.maximum || '10+'}`;
          }
        }
        return '';
      };
      category.filters.push({
        name: config.label,
        categoryId: facet,
        value: getValue(values, config),
      });
    }

    return categories;
  }, [facets]);

  if (!activeFilters.length) {
    return null;
  }

  return (
    <div className='mt-8'>
      <ActiveFilterPanel
        categoryList={activeFilters}
        onFilterTagClick={(facet, operation) => {
          if (operation === ActiveFilterOperation.DELETE) {
            const nextFacets = { ...facets };
            delete nextFacets[facet as NetworkFacet];
            onChange(nextFacets);
          }
        }}
        canOpen={false}
      />
    </div>
  );
};
