import type { VFC } from 'react';
import { useMemo, useState } from 'react';
import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { Collapsible, Icon, LinkButton, Tag } from '@els/biomed-ui';

import { Delete, NavigateDown } from 'assets/icons';
import type { Range } from 'components/SliderHistoChart';
import SwitchList from 'components/SwitchList';
import { defaultYearForSlider } from 'constants/constant';
import PublicationFilterWrapper from 'containers/PublicationFilterWrapper';
import { facetsKeyMap, isKeyOfFacetsKeyMap } from 'pages/Network/services/models';
import { fillHistogramData } from 'utils/facets';
import type {
  FacetsResponse,
  PublicationFacets,
  ReferenceCountRange,
  YearRange,
} from 'utils/models';
import { calculatedYear } from 'utils/timeAndDate';
import type { NetworkFacets, NetworkFilters } from '../../services/facets';
import { networkFacetLabels, networkFacets } from '../../services/facets';

import styles from './FiltersPanel.module.scss';

const defaultReferencesRange: [number, number] = [1, 10];
const defaultYearsRange: [number, number] = [defaultYearForSlider, calculatedYear];

interface Props {
  originalPublicationFacets?: PublicationFacets;
  availableFacets?: FacetsResponse;
  filters: NetworkFilters;
  onChange: (filters: NetworkFilters) => void;
}

type UnSelectedFilterValue = { position: number; value: string };
type UnSelectedFilters = {
  [key: string]: UnSelectedFilterValue[];
};

const getUpdatedFacets = (
  facets: FacetsResponse | undefined,
  unSelectedFilters: UnSelectedFilters
): NetworkFacets | undefined => {
  if (!facets) return;

  return Object.entries(facets).reduce((acc, [key, facetValue]) => {
    const newKey = isKeyOfFacetsKeyMap(key, facetsKeyMap) ? facetsKeyMap[key] : key;
    if (newKey === 'years' || newKey === 'refNum') return acc;

    let value = Array.isArray(facetValue) ? [...facetValue] : [facetValue].filter(Boolean);

    if (newKey === 'conceptType') {
      value = value.map(entity =>
        typeof entity === 'object' && 'name' in entity ? entity.name : entity
      );
    }

    const disabledValues = unSelectedFilters[newKey];
    disabledValues?.forEach(({ position, value: disabledValue }) => {
      const insertionPosition = Math.min(position, value.length);
      if (!value.includes(disabledValue)) {
        value.splice(insertionPosition, 0, disabledValue);
      }
    });
    return { ...acc, [newKey]: value };
  }, {} as NetworkFacets);
};

export const FiltersPanel: VFC<Props> = ({
  originalPublicationFacets,
  availableFacets,
  filters,
  onChange,
}) => {
  const [unSelectedFilters, setUnSelectedFilters] = useState<UnSelectedFilters>({});

  const availableFilters = getUpdatedFacets(availableFacets, unSelectedFilters) as NetworkFacets;
  const facetsOptions = useMemo(() => {
    if (!availableFilters) {
      return null;
    }
    return networkFacets
      .filter(facet => facet !== 'Tissue')
      .map(facet => {
        if (facet === 'years' || facet === 'refNum') return null;
        const data = availableFilters?.[facet];
        if (!data?.length) {
          return null;
        }

        const config = networkFacetLabels[facet];

        return {
          facet,
          label: config.label,
          options: data.map(name => ({
            value: name,
            label: config.options[name]?.label || name,
            description: config.options[name]?.description,
          })),
        };
      });
  }, [availableFilters]);

  const { start = defaultYearsRange[0], end = defaultYearsRange[1] } = filters.years ?? {};
  const yearsRange: Range = [start, end];

  const { minimum = defaultReferencesRange[0], maximum = defaultReferencesRange[1] } =
    filters.refNum ?? {};
  const referencesRange: Range = [minimum, maximum];

  const originalAvailableYears = originalPublicationFacets?.years;
  const availableYears = availableFacets?.years;

  const originalYearsData = useMemo(
    () => fillHistogramData(originalAvailableYears),
    [originalAvailableYears]
  );
  const yearsData = useMemo(() => fillHistogramData(availableYears), [availableYears]);

  return (
    <div className={styles.root}>
      <div className={cn(styles.header, 'px-2')}>
        <h2>Filters</h2>
        <LinkButton
          iconRight={Delete}
          colored
          disabled={isEmpty(filters)}
          onClick={() => {
            onChange({});
          }}
          size='xs'
        >
          Clear Filters
        </LinkButton>
      </div>
      <div className='px-2'>
        <PublicationFilterWrapper
          limitRangeYear={defaultYearsRange}
          originalPubData={originalYearsData}
          pubData={yearsData}
          pubYearRange={yearsRange}
          referenceRange={defaultReferencesRange}
          referenceData={referencesRange}
          onPubFilterChange={(value, type) => {
            const nextFilters = { ...filters };

            if (type === 'refNum') {
              const references: ReferenceCountRange = {};

              if (value[0] !== defaultReferencesRange[0]) references.minimum = value[0];
              if (value[1] !== defaultReferencesRange[1]) references.maximum = value[1];

              if (isEmpty(references)) {
                delete nextFilters.refNum;
              } else {
                nextFilters.refNum = references;
              }
            } else {
              const years: YearRange = {};

              if (value[0] && value[0] !== defaultYearsRange[0]) years.start = value[0];
              if (value[1] && value[1] !== defaultYearsRange[1]) years.end = value[1];

              if (isEmpty(years)) {
                delete nextFilters.years;
              } else {
                nextFilters.years = years;
              }
            }

            onChange(nextFilters);
          }}
          onReferenceError={error => {
            console.debug('> reference error', error);
          }}
        />

        {facetsOptions?.map(config => {
          if (!config?.options.length) {
            return null;
          }

          // All selected by default
          const selectedValues = filters[config.facet] ?? availableFilters?.[config.facet];
          const updateFilters = (values: string[], value: string, isChecked: boolean) => {
            const nextFilters = { ...filters };
            if (values.length === availableFilters?.[config.facet]?.length) {
              delete nextFilters[config.facet];
            } else {
              nextFilters[config.facet] = values;
            }
            onChange(nextFilters);

            const newUnSelectedFilters = { ...unSelectedFilters };
            const disabledValues = newUnSelectedFilters[config.facet] || [];
            if (!isChecked) {
              // Calculate position
              const position = availableFilters?.[config.facet]?.indexOf(value) ?? -1;
              newUnSelectedFilters[config.facet] = [...disabledValues, { value, position }];
            } else {
              newUnSelectedFilters[config.facet] = disabledValues.filter(v => v.value !== value);
            }
            setUnSelectedFilters(newUnSelectedFilters);
          };

          return (
            // TODO: think about creating re-usable "Collapsible" component, which can be used in:
            //       - <FilterContainer /> in search results
            //       - <PublicationFilterWrapper /> in both search & network results
            <Collapsible
              key={config.facet}
              defaultExpanded
              trigger={props => (
                <LinkButton
                  {...props}
                  iconRight={
                    <div className={styles.icon}>
                      <Tag size='xxs'>{selectedValues?.length ?? 0}</Tag>
                      <Icon name={NavigateDown} size='sm' />
                    </div>
                  }
                  className='font-weight-bold w-100 pr-1'
                >
                  {config.label}
                </LinkButton>
              )}
              className={styles.item}
            >
              <SwitchList
                data-testid={`${config.facet.toLocaleLowerCase()}-facets-container`}
                options={config.options}
                selectedValues={selectedValues}
                onChange={updateFilters}
              />
            </Collapsible>
          );
        }) ?? 'No filters'}
      </div>
    </div>
  );
};
