import type { ChangeEvent, ComponentProps, VFC } from 'react';
import { useState } from 'react';
import cn from 'classnames';
import { isEqual } from 'lodash';
import { Input } from '@els/biomed-ui';

import Slider from '../Slider';

import styles from './ReferenceInputSlider.module.scss';

// TODO: on page load, show empty "to" input

// Update some Slider's types to be more specific
interface Props extends Omit<ComponentProps<typeof Slider>, 'domain' | 'values' | 'onChange'> {
  domain: [number, number];
  values: [number, number];
  onChange: (data: [number, number]) => void;
  title?: string;
  onError?: (error: boolean) => void;
}

const ReferenceInputSlider: VFC<Props> = ({
  domain,
  values,
  ticksList,
  tickAliases,
  onChange,
  onError,
  title,
}) => {
  const [sliderValues, setSliderValues] = useState(values);
  const [inputValues, setInputValues] = useState(values);

  const [fromError, setFromError] = useState<string>();
  const [toError, setToError] = useState<string>();

  if (!isEqual(values, sliderValues)) {
    setSliderValues(values);
    setInputValues(values);
    setFromError(undefined);
    setToError(undefined);
  }

  const [domainMIN, domainMAX] = domain;

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, name: inputName } = event.target;

    const parsedValue = parseInt(value);

    if (inputName === 'from') {
      const fromValue = !isNaN(parsedValue) ? parsedValue : domainMIN;
      const toValue = inputValues[1];

      setInputValues([fromValue, toValue]);

      if (fromValue > toValue) {
        setFromError(`Should be ${toValue} or less.`);
        onError?.(true);
      } else {
        setFromError(undefined);
        setSliderValues([Math.min(fromValue, domainMAX), toValue]);

        onChange([fromValue, toValue]);
        onError?.(false);
      }
    } else {
      const toValue = !isNaN(parsedValue) ? parsedValue : domainMAX;
      const fromValue = inputValues[0];

      setInputValues([fromValue, toValue]);

      if (toValue < fromValue) {
        setToError(`Should be ${fromValue} or more.`);
        onError?.(true);
      } else {
        setToError(undefined);
        setSliderValues([fromValue, Math.min(toValue, domainMAX)]);

        onChange([fromValue, toValue]);
        onError?.(false);
      }
    }
  };

  const handleSliderChange = ([sliderFrom, sliderTo]: ReadonlyArray<number>) => {
    setFromError(undefined);
    setToError(undefined);

    const [inputFrom, inputTo] = inputValues;

    // If the slider is at the maximum value and the input value is greater than the maximum value,
    // set the resulting value to the maximum value.
    const newFromValue = sliderFrom === domainMAX && inputFrom > domainMAX ? inputFrom : sliderFrom;
    const newToValue = sliderTo === domainMAX && inputTo > domainMAX ? inputTo : sliderTo;

    const newValues: [number, number] = [newFromValue, newToValue];

    setSliderValues(newValues);
    setInputValues(newValues);
    onChange(newValues);
  };

  return (
    <div data-testid='reference-input-slider'>
      {title && <div className={styles.title}>{title}</div>}
      <div data-testid='reference-input-slider-element'>
        <Slider
          domain={domain}
          values={sliderValues}
          ticksList={ticksList}
          tickAliases={tickAliases}
          mode={1}
          step={1}
          onChange={handleSliderChange}
        />
      </div>
      <div className={styles.inputBlock}>
        <Input
          data-testid='left-references-slider'
          aria-label='minimum value'
          className={cn(styles.inputText, fromError && styles.inputTextInvalid)}
          value={inputValues[0]}
          type='number'
          name='from'
          min={domainMIN}
          max={inputValues[1]}
          size='sm'
          error={fromError}
          onChange={handleInputChange}
        />
        <div>—</div>
        <Input
          data-testid='right-references-slider'
          aria-label='maximum value'
          className={cn(styles.inputText, toError && styles.inputTextInvalid)}
          value={inputValues[1]}
          type='number'
          size='sm'
          name='to'
          min={inputValues[0]}
          max={Number.MAX_VALUE}
          error={toError}
          onChange={handleInputChange}
        />
      </div>
    </div>
  );
};

export default ReferenceInputSlider;
