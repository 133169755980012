import { urlConfig } from 'constants/UrlConfig';
import { post } from 'utils/axios';
import type { SearchResultRequestData } from 'utils/models';

export interface RelationList {
  nextToken: string | null;
  previousToken: string | null;
  relationSearchHits: RelationSearchHit[];
}

export interface RelationSearchHit {
  primary: boolean;
  relation: RelationItem;
}

export interface RelationItem {
  source: Target;
  target: Target;
  type: string;
  effect: string;
  mechanism: string | null;
  referenceCount: number;
  snippets: string[];
  directionType?: string | null;
  freeTextSearch?: FreeTextSearchTokens;
}
export interface FreeTextSearchTokens {
  isRelationContainsTokens: boolean;
  textSnippetsHavingTokens?: number[];
}

export interface Target {
  name: string;
  urn: string;
  type: string;
}

export const fetchArticleRelations = (
  articleId: string,
  request: SearchResultRequestData,
  primary?: boolean
) => {
  return post<RelationList>(`${urlConfig.articlesUrl}/${articleId}/relations`, request, {
    params: { primary },
  });
};
