import type { ReactNode } from 'react';

import type { Entry } from 'utils/models';

export function validate(
  entries: Entry[],
  selectedEntries: Entry[]
): [entriesMessage?: ReactNode, selectedEntriesMessage?: ReactNode] {
  const entriesMessage =
    entries.length > 0
      ? validateEntries(entries, [
          <p>
            You list contains only one (valid) concept. Unfortunately it’s not possible to generate
            a network with only one element.
            <br />
            Please upload a new file containing a minimum of 2 concepts.
          </p>,
          <p>
            All the concepts selected are synonyms with one another. Unfortunately it’s not possible
            to generate a network with only 1 concept.
            <br />
            Please upload a new file containing a minimum of 2 concepts that are not synonyms.
          </p>,
        ])
      : // This message is computed/shown by "ConceptsMappingInfo" component.
        undefined;
  const selectedEntriesMessage =
    // Do not validate selected if all is invalid.
    entriesMessage
      ? undefined
      : validateEntries(selectedEntries, [
          <p>Please select at least 2 concepts from the list to generate a network.</p>,
          <p>
            Please select at least 2 concepts that are not synonyms from the list to generate a
            network.
          </p>,
        ]);

  return [entriesMessage, selectedEntriesMessage];
}

function validateEntries(entries: Entry[], messages: [none: ReactNode, synonyms: ReactNode]) {
  if (entries.length < 2) {
    return messages[0];
  }

  const uniqueUrns = new Set<string>();
  for (const entry of entries) {
    uniqueUrns.add(entry.concept.urn);
  }
  if (uniqueUrns.size < 2) {
    return messages[1];
  }
}
