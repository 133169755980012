import { conceptTypes, relationEffects } from 'constants/concepts';
import type {
  Concept,
  FacetsResponse,
  PublicationFacetSavedData,
  RelationEffect,
  SavedFilters,
} from 'utils/models';
import { invertedFacetsKeyMap, isKeyOfFacetsKeyMap, type NetworkRelation } from './models';

// Order of facets in the filter panel.
export const networkFacets = [
  'effect',
  'relationType',
  'conceptType',
  'TermClass',
  'PrimaryCellLocalization',
  'Tissue',
  'years',
  'refNum',
] as const satisfies Array<keyof SavedFilters | 'conceptType'>;

export type NetworkFacet = (typeof networkFacets)[number];
export type NetworkFacets = Partial<Record<Exclude<NetworkFacet, 'years' | 'refNum'>, string[]>>;
export type NetworkFilters = NetworkFacets & PublicationFacetSavedData;

interface FacetOption {
  label?: string;
  description?: string;
}

type FacetOptions = Partial<Record<string, FacetOption>>;

/**
 * The list of relation type descriptions are taken from
 * https://elsevier.atlassian.net/wiki/spaces/CTNBS/pages/119601477949461/EmBiology+Relations+description
 */
export const networkFacetLabels: Record<NetworkFacet, { label: string; options: FacetOptions }> = {
  relationType: {
    label: 'Relationship type',
    options: {
      Binding: {
        description: 'Binding means there is a direct physical interaction between two molecules.',
      },
      Biomarker: {
        description:
          'Biomarker relation means that a molecule has been reported as a biomarker for a disease.',
      },
      CellExpression: {
        description:
          'Cell expression relation refers to the process by which genetic information is used to produce proteins within a cell or on the surface of a cell.',
      },
      ChemicalReaction: {
        description:
          'Chemical reaction relation means that an enzyme catalyses a reaction involving a small molecule.',
      },
      ClinicalTrial: {
        description:
          'Clinical trial relation means there is a Disease/cell process relation representing clinical trials conducted for a drug against a disease.',
      },
      DirectRegulation: {
        description:
          'Direct regulation relation means that a regulator influences target activity by direct physical interaction with another concept.',
      },
      Expression: {
        description:
          'Expression relation means that a regulator changes protein abundance by affecting levels of transcript or protein stability.',
      },
      FunctionalAssociation: {
        description:
          'Functional association relation exists when a disease is associated to cell process, clinical parameter or another disease. There is no effect or specific direction.',
      },
      GeneticChange: {
        description:
          'Genetic change relation exists when a disease is responsible for genetic changes.',
      },
      miRNAEffect: {
        description: 'miRNA effect is the inhibitory effect of a miRNA on its mRNA target.',
      },
      MolSynthesis: {
        description:
          'Molecular synthesis relations means that a regulator is able to change the concentrations of the target (usually being a small molecule).',
      },
      MolTransport: {
        description:
          'Molecular transport relations means that a regulator changes the localization of its target (via molecular translocation, export, import etc.).',
      },
      PromoterBinding: {
        description:
          'Promoter binding relations exists between a regulator that binds to the promoter of a gene.',
      },
      ProtModification: {
        description:
          'Protein modification relation is a process by which a regulator changes the structure or chemistry of the target molecule. It usually happens by a direct interaction.',
      },
      QuantitativeChange: {
        description:
          'Quantitative change relation happens when a disease is associated with changes in abundance/activity/expression of a gene/protein/small molecule.',
      },
      Regulation: {
        description:
          'Regulation relation exist when a regulator changes the activity of the target by an unknown mechanism (direct or indirect).',
      },
      StateChange: {
        description:
          'A state change relation is a process by which changes in a protein’s post translational modification status or alternative splicing events are associated with a disease.',
      },
    },
  },
  Tissue: { label: 'Tissue type', options: {} },
  years: { label: 'Publication years', options: {} },
  refNum: { label: 'Supporting references', options: {} },
  conceptType: {
    label: 'Concept type',
    options: Object.entries(conceptTypes).reduce<FacetOptions>((result, [name, label]) => {
      result[name] = { label };
      return result;
    }, {}),
  },
  TermClass: { label: 'Protein class', options: {} },
  PrimaryCellLocalization: { label: 'Protein localization', options: {} },
  effect: {
    label: 'Effect',
    options: {
      undefined: {
        label: relationEffects.undefined,
        description:
          'Non-applicable means there is no directionality to the relationship and therefore no effect.',
      },
      unknown: {
        label: relationEffects.unknown,
        description:
          'Unknown effect means that there is not enough clarity on the effect of a relation between two entities.',
      },
      positive: {
        label: relationEffects.positive,
        description:
          'Positive effect means that the relations enhance or promote a particular biological response on the target.',
      },
      negative: {
        label: relationEffects.negative,
        description:
          'Negative effect means that the relations inhibit or suppress a particular biological response on the target.',
      },
    } satisfies Record<RelationEffect, unknown>,
  },
};

/**
 * Check if network relation is maching to the filtered relations.
 */
export function checkFilteredNetworkRelation(
  relation: NetworkRelation,
  filteredRelations?: NetworkRelation[]
): boolean {
  if (!filteredRelations) {
    return true;
  }

  return filteredRelations.some(fr => fr.urn === relation.urn);
}

/**
 * Check if concept is fit to active facets.
 */
export function checkConcept(concept: Concept, activeFacets: NetworkFilters = {}): boolean {
  const { termClass, primaryCellLocalization } = concept;
  const {
    TermClass: activeTermClasses,
    PrimaryCellLocalization: activeLocalizations,
    conceptType: activeConceptTypes,
  } = activeFacets;

  if (activeConceptTypes && !activeConceptTypes.includes(concept.type)) {
    return false;
  } else if (
    activeTermClasses &&
    termClass &&
    !termClass.some(termClass => activeTermClasses.includes(termClass))
  ) {
    return false;
  } else if (
    activeLocalizations &&
    primaryCellLocalization &&
    !primaryCellLocalization.some(localization => activeLocalizations.includes(localization))
  ) {
    return false;
  }

  return true;
}

/**
 * Removes unsupported facets from the active facets.
 */
export function cleanupFacets(
  availableFacets: NetworkFacets = {},
  activeFacets: NetworkFilters = {}
): SavedFilters {
  const filters: SavedFilters = {};

  for (const [name, values] of Object.entries(activeFacets)) {
    const facet = name as NetworkFacet;
    // Special handling should be applied.
    if (facet === 'TermClass' || facet === 'PrimaryCellLocalization' || facet === 'conceptType') {
      continue;
    }
    if (facet === 'years' || facet === 'refNum') {
      filters[facet] = values;
    } else {
      const availableValues = availableFacets[facet];
      if (Array.isArray(values) && Array.isArray(availableValues)) {
        // Now it's safe to use includes as we've confirmed both are arrays
        filters[facet] = values.filter(value => availableValues.includes(value));
      } else {
        filters[facet] = values;
      }
    }
  }
  return filters;
}

export function filterCurrentFilters(
  currentFilters: NetworkFilters,
  availableFacets?: FacetsResponse
): NetworkFilters {
  const filteredFacets: NetworkFilters = {};
  if (!availableFacets) {
    return currentFilters;
  }
  for (const filterKey of Object.keys(currentFilters)) {
    const key = filterKey as keyof NetworkFilters;
    if (key === 'years' || key === 'refNum') {
      continue;
    }
    const value = currentFilters[key];
    const facet = (
      isKeyOfFacetsKeyMap(key, invertedFacetsKeyMap) ? invertedFacetsKeyMap[key] : key
    ) as keyof FacetsResponse;
    const availableValues = availableFacets[facet];

    if (facet === 'entityType') {
      filteredFacets[key] = value?.filter(v => availableFacets[facet]?.some(e => e.name === v));
    } else {
      if (Array.isArray(value) && Array.isArray(availableValues)) {
        filteredFacets[key] = value.filter(v => availableValues.includes(v));
      }
    }
  }

  return { ...currentFilters, ...filteredFacets };
}
