import { useEffect, useRef, useState } from 'react';
import type { AxiosProgressEvent, AxiosRequestConfig } from 'axios';
import { toast } from '@els/biomed-ui';

import type { UploadedFile } from 'utils/upload';

export type Uploader<T> = (file: File, options?: AxiosRequestConfig) => Promise<T>;

type UploadFile<T> = (file: File, callback?: (response: T) => void) => void;

interface UploadTracker<T> {
  uploadedFiles: UploadedFile<T>[];
  uploadFile: UploadFile<T>;
  cancelUpload: (file: File) => void;
  removeFile: (file: UploadedFile<T>) => void;
}

export function useFileUploadTracker<T>(
  uploader: Uploader<T>,
  successMsg = 'Upload successful.'
): UploadTracker<T> {
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile<T>[]>([]);

  const abortControllersRef = useRef<Map<string, AbortController>>(new Map());

  const uploadFile: UploadFile<T> = async (file, callback) => {
    const abortControllers = abortControllersRef.current;
    const fileName = file.name;
    const abortController = new AbortController();

    const existingController = abortControllers.get(fileName);
    if (existingController) {
      console.warn('Already has active upload with same file name. Aborting...');
      existingController.abort();
    }

    abortControllers.set(fileName, abortController);

    const progressHandler = (progressEvent: AxiosProgressEvent) => {
      const progress = progressEvent.total
        ? Math.round((progressEvent.loaded * 100) / progressEvent.total)
        : 0;
      setUploadedFiles(updateFileStatus({ file, status: { progress, completed: false } }));
    };

    try {
      const options: AxiosRequestConfig = {
        headers: { 'content-type': 'multipart/form-data' },
        onUploadProgress: progressHandler,
        signal: abortController.signal,
      };

      const data: T = await uploader(file, options);
      callback?.(data);

      setUploadedFiles(
        updateFileStatus({
          file,
          status: { progress: 100, completed: true },
          data,
        })
      );

      toast.success(successMsg);
    } catch (error) {
      console.error(`File ${fileName} upload failed:`, error);
      let errMsg;
      if (
        error &&
        typeof error === 'object' &&
        'message' in error &&
        error.message === 'canceled'
      ) {
        errMsg = 'File uploading cancelled.';
      } else {
        errMsg =
          'Unfortunately something went wrong on our side. Your upload has not been saved. Please upload the file again.';
      }
      setUploadedFiles(prevUploadedFiles =>
        prevUploadedFiles.filter(item => item.file.name !== fileName)
      );

      toast.error(errMsg);
    } finally {
      abortControllers.delete(fileName);
    }
  };

  function cancelUpload(file: File) {
    const abortControllers = abortControllersRef.current;
    const fileName = file.name;
    const ctrl = abortControllers.get(fileName);

    if (ctrl) {
      ctrl.abort();
      abortControllers.delete(fileName);
    }
  }

  function removeFile(file: UploadedFile<T>) {
    setUploadedFiles(uploadedFiles => uploadedFiles.filter(f => file !== f));
  }

  useEffect(() => {
    const abortControllers = abortControllersRef.current;
    // Abort uploads when the component unmounts
    return () => {
      for (const ctrl of abortControllers.values()) {
        ctrl.abort();
      }
      abortControllers.clear();
    };
  }, []);

  return { uploadedFiles, uploadFile, cancelUpload, removeFile };
}

function updateFileStatus<T>({ file, status, data }: UploadedFile<T>) {
  return (existingFiles: UploadedFile<T>[]) => {
    const existingFileIndex = existingFiles.findIndex(
      uploadedFile => uploadedFile.file.name === file.name
    );

    if (existingFileIndex !== -1) {
      // File exists, update the progress
      const updatedFiles = [...existingFiles];
      updatedFiles[existingFileIndex] = {
        ...updatedFiles[existingFileIndex],
        status,
        data,
      };
      return updatedFiles;
    } else {
      // File doesn't exist, create a new entry
      const newFile = {
        file,
        status,
        data,
      };
      return [newFile, ...existingFiles];
    }
  };
}
