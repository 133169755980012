import type { VFC } from 'react';
import { useEffect, useMemo } from 'react';
import { useInView } from 'react-intersection-observer';
import { Spinner } from '@els/biomed-ui';

import type { Entity, SavedFilters } from 'utils/models';
import RelationCollapsibleItem from '../RelationCollapsibleItem';
import { useResultFragmentContext } from '../ResultFragmentContext';
import useArticleRelations from './service/useArticleRelations';

interface Props {
  articleId: string;
  entity?: Entity[];
  facets: SavedFilters | null;
  primary?: boolean;
}

const RelationInfoPanel: VFC<Props> = ({ articleId, facets = null, entity, primary }) => {
  const { searchTerms = [], isHighlightingEnabled } = useResultFragmentContext();
  const [isLoading, relationList, relationListError, fetchMore] = useArticleRelations(articleId, {
    facets,
    entity,
    primary,
    searchTerms,
  });

  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView) {
      fetchMore();
    }
  }, [inView, fetchMore]);

  const relations = useMemo(() => {
    return relationList?.pages.map(page => page.relationSearchHits).flat(1);
  }, [relationList]);

  if (isLoading) {
    return (
      <Spinner
        className='es-move-middle es-move-center'
        contentSize='xs'
        size='sm'
        animationSpeed='moderate'
      >
        Loading...
      </Spinner>
    );
  }

  if (relations && !relations.length) {
    return <p>Relations are not available.</p>;
  } else if (!relations && relationListError) {
    return <p>Error: {relationListError}</p>;
  }

  return (
    <div data-testid='relations-panel'>
      {relations?.map((hit, index) => (
        <RelationCollapsibleItem
          ref={index === relations.length - 1 ? ref : null}
          key={`relationInfo-${index}`}
          hit={hit}
          index={index}
          isHighlightingEnabled={isHighlightingEnabled}
        />
      ))}
    </div>
  );
};

export default RelationInfoPanel;
