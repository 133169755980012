import type { FC } from 'react';
import type { ColProps } from 'react-bootstrap/Col';
import cn from 'classnames';
import { Col, Icon } from '@els/biomed-ui';
import { useId } from '@els/biomed-ui/utils/hooks';

import { NavigateLeft } from 'assets/icons';

import styles from './CollapsibleCol.module.scss';

interface Props extends ColProps {
  expanded: boolean;
  onToggle: () => void;
}

const CollapsibleCol: FC<Props> = ({ expanded, onToggle, children, className, ...props }) => {
  const id = useId(undefined, id => `collapsible-col-${id}`);
  return (
    <Col className={cn(styles.root, { [styles.collapsed]: !expanded }, className)} {...props}>
      <button
        className={styles.toggle}
        aria-controls={id}
        aria-expanded={expanded}
        onClick={onToggle}
        aria-label='toggle'
      >
        <div className={styles.arrow}>
          <Icon name={NavigateLeft} />
        </div>
      </button>
      <div id={id} className={styles.contentContainer} aria-hidden={!expanded}>
        <div className={styles.content}>{children}</div>
      </div>
    </Col>
  );
};

export default CollapsibleCol;
