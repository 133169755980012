import { type FunctionComponent, useEffect, useState } from 'react';
import classNames from 'classnames';
import { Button, Input } from '@els/biomed-ui';

import { NavigateRight } from 'assets/icons';
import FilterOption, { type Option, type OptionConfig } from 'components/FilterOption';
import { useResultFragmentContext } from '../ResultFragmentContext';

import styles from './FreeTextSearch.module.scss';

const optionDefaults: OptionConfig = {
  checked: true,
  remove: true,
};

interface Props {
  setClearSearchOptions?: (clearFn: () => void) => void;
  onClear?: () => void;
}

const FreeTextSearch: FunctionComponent<Props> = ({ setClearSearchOptions, onClear }) => {
  const [searchText, setSearchText] = useState('');
  const [searchOptions, setSearchOptions] = useState<Option[]>([]);
  const { freeTextSearchArticles } = useResultFragmentContext();

  const handleCheck = (item: Option, check: boolean) => {
    setSearchOptions(
      searchOptions.map(option =>
        option.label === item.label ? { ...option, checked: check } : option
      )
    );
  };

  const handleFind = () => {
    const trimmedSearchText = searchText.trim();
    if (trimmedSearchText) {
      setSearchOptions([...searchOptions, { ...optionDefaults, label: trimmedSearchText }]);
      setSearchText('');
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) handleFind();
  };

  useEffect(() => {
    freeTextSearchArticles?.(
      searchOptions.filter(option => option.checked).map(option => option.label)
    );
  }, [searchOptions, freeTextSearchArticles]);

  useEffect(() => {
    setClearSearchOptions?.(() => () => setSearchOptions([]));
  }, [setClearSearchOptions]);

  const handleRemove = (item: Option) => {
    const updatedOptions = searchOptions.filter(option => option.label !== item.label);
    setSearchOptions(updatedOptions);
    if (updatedOptions.length === 0) {
      onClear?.();
    }
  };

  return (
    <div className={styles.freeTextWrapper}>
      <h3 id='search-input-label' className='es-font-size-sm'>
        Search for specific terms within the article list
      </h3>
      <div className={styles.inputWrapper}>
        <div className={styles.searchInputContainer}>
          <Input
            className={classNames(styles.searchInput, 'pt-0')}
            data-testid='free-text-search-input'
            value={searchText}
            size='sm'
            aria-labelledby='search-input-label'
            aria-describedby='search-input-label'
            placeholder='Type a keyword'
            placeholderPosition='input'
            onChange={event => setSearchText(event.target.value)}
            onKeyDown={handleKeyDown}
          />
        </div>
        <Button
          iconRight={NavigateRight}
          disabled={!searchText.trim().length}
          data-testid='free-text-search-find-button'
          onClick={handleFind}
          size='sm'
        >
          Find
        </Button>
      </div>
      <ul className={styles.listWrapper}>
        {searchOptions.map((item, index) => (
          <FilterOption
            item={{ ...item, label: `${index + 1}. ${item.label}` }}
            id={`${item.label}-${index}`}
            key={`${item.label}-${index}`}
            onCheck={check => handleCheck(item, check)}
            onRemove={() => handleRemove(item)}
          />
        ))}
      </ul>
    </div>
  );
};

export default FreeTextSearch;
