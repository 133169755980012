import { createContext, type ReactNode } from 'react';

import { type MessageBoxConfig } from './MessageBoxProvider';

export interface Context {
  show: (message: ReactNode, config?: MessageBoxConfig) => void;
  hide: () => void;
}

export const MessageBoxContext = createContext<Context | undefined>(undefined);
