import type { VFC } from 'react';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';
import type { TestProps } from '@els/biomed-ui';

import styles from './NavItem.module.scss';

export interface Props extends TestProps {
  to: string;
  label: string;
}

const NavItem: VFC<Props> = ({ to, label, 'data-testid': testId }) => (
  <li>
    <NavLink
      to={to}
      className={({ isActive }) => cn(styles.link, { [styles.active]: isActive })}
      data-testid={testId}
    >
      {label}
    </NavLink>
  </li>
);

export default NavItem;
