import { useMemo } from 'react';
import type { FileError } from 'react-dropzone';
import { ErrorCode } from 'react-dropzone';

import { EXCEL_TEMPLATES } from 'constants/constant';
import type { Project, Workbook } from '../models';
import { UploadStatus } from '../models';

export interface UploadProgress {
  progress: number;
  completed: boolean;
}

export interface UploadedFile<T> {
  file: File;
  status: UploadProgress;
  data?: T;
}

export function getErrorMsgForSingleExcelFile({ code, message }: FileError) {
  switch (code) {
    case ErrorCode.TooManyFiles:
      return 'You can only upload one file at a time. To proceed, please select and upload only one file.';
    case ErrorCode.FileInvalidType: {
      const acceptedTypes = Object.values(EXCEL_TEMPLATES)
        .map(type => type[0].replace('.', ''))
        .join(' or ');
      return `The file selected is not supported. 
        Please retry uploading files in ${acceptedTypes} format only.`;
    }
    default:
      return message;
  }
}

export function createProject<T extends Project>(uploadedFile: UploadedFile<T>): Project;
export function createProject<T extends Project>(
  uploadedFile: UploadedFile<T>,
  factory: (base: Project) => T
): T;
export function createProject<T extends Project>(
  uploadedFile: UploadedFile<T>,
  factory?: (base: Project) => T
): T | Project {
  const {
    file,
    status: { progress },
    data,
  } = uploadedFile;
  const project: Project = {
    id: data?.id,
    title: file.name,
    description: null,
    progressValue: progress,
    updatedDate: data?.updatedDate || new Date().toDateString(),
    status: data?.status || UploadStatus.UPLOADING,
  };
  return factory ? factory(project) : project;
}

export function mergeProjects<T extends Project>(
  existingProjects: T[],
  uploadedProjects: T[]
): T[] {
  function isEqual(existingFile: T, uploadedFile: T) {
    if (existingFile.id) {
      return existingFile.id === uploadedFile.id;
    }
    return existingFile.title === uploadedFile.title;
  }

  const newProjects = uploadedProjects.filter(
    uploadedFile => !existingProjects.some(existingFile => isEqual(existingFile, uploadedFile))
  );

  const updatedExistingProjects = existingProjects.map(existingFile => {
    const uploadedFile = uploadedProjects.find(uploadedFile => isEqual(existingFile, uploadedFile));
    if (uploadedFile) {
      return {
        ...existingFile,
        ...uploadedFile,
        status: [UploadStatus.COMPLETED, UploadStatus.FAILED].includes(existingFile.status)
          ? existingFile.status
          : uploadedFile.status,
      };
    }
    return existingFile;
  });

  return [...newProjects, ...updatedExistingProjects];
}

export function useMergeProjects<T extends Project>(
  workbook?: Workbook<T>,
  uploadedFiles?: UploadedFile<T>[]
) {
  return useMemo(() => {
    if (workbook) {
      const existingProjects = workbook.experiments;
      const uploadedProjects = uploadedFiles?.map(fileStatus => createProject(fileStatus)) ?? [];
      return mergeProjects(existingProjects, uploadedProjects);
    }
    return [];
  }, [workbook, uploadedFiles]);
}
