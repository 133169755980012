import { maxSelectedArticles } from 'constants/constant';
import { urlConfig } from 'constants/UrlConfig';
import { post } from 'utils/axios';
import { convertFacetsToRequestedFacets } from 'utils/facets';
import type { Entity, SavedFilters } from 'utils/models';

export type ExportArticlesPayload = {
  selectedArticles: string[];
  fileType: string;
  listId?: string;
  query?: Entity | null;
  entity?: Entity[] | null;
  facets?: SavedFilters | null;
};

export const exportContent = ({
  selectedArticles,
  fileType,
  query,
  facets,
  listId,
  entity,
}: ExportArticlesPayload) => {
  if (!query && listId) {
    return exportSavedListContent({ listId, selectedArticles, fileType });
  } else {
    return exportSearchArticles({ selectedArticles, fileType, query, facets, entity });
  }
};

const exportSavedListContent = ({ listId, selectedArticles, fileType }: ExportArticlesPayload) => {
  const url = `${urlConfig.listContentUrl}/${listId}/export`;
  return post<Blob>(url, selectedArticles, {
    headers: { Accept: fileType },
    responseType: 'blob',
  });
};

const exportSearchArticles = ({
  selectedArticles,
  fileType,
  query,
  facets,
  entity,
}: ExportArticlesPayload) => {
  const params: Record<string, any> = {
    exportType: 'searchResultExport',
  };
  if (entity) params['entity'] = entity;
  if (query) params['query'] = query.name;

  if (facets) params['facets'] = convertFacetsToRequestedFacets(facets || null, null);
  if (selectedArticles && selectedArticles.length) {
    params['articleId'] =
      selectedArticles.length > maxSelectedArticles
        ? selectedArticles.slice(0, maxSelectedArticles)
        : selectedArticles;
  }

  return post<Blob>(urlConfig.exportUrl, params, {
    headers: { Accept: fileType },
    responseType: 'blob',
  });
};
