import { isFunction } from 'lodash';
import flatten from 'lodash/flatten';
import uniq from 'lodash/uniq';
import type { ColumnDef, RowData, Table } from '@tanstack/react-table';
import type { IconElement, IconSource, TestProps } from '@els/biomed-ui';
import { Flexbox, LinkButton } from '@els/biomed-ui';

import type { RenderFunction } from '../utils';
import { composeOverridableRenderer } from '../utils';
import { PredefinedColumnIds } from './PredefinedColumnIds';

export type RowActionProps<TData extends RowData> = TestProps & {
  label?: string;
  /**
   * Title of the action, does not rendered - used mostly for a11y
   */
  title?: string;
  /** Icon for the corresponding action */
  icon: IconSource | IconElement;
  /**
   * disabled can be boolean or function if we need to disable action if something wrong selected
   * if you need to disable global action when row action is specified - you need to specufy tags property
   */
  disabled?: boolean | ((item: TData) => boolean);
  /** onClick handler */
  onClick: (item: TData, table: Table<TData>) => void;
  /**
   * If you specify tags property, then when row action with at least specified tags is disabled
   * it will disable global action with the same tag specified
   */
  tags?: string[];
  data?: TData;
  /** You can override  default element  with this prop */
  render?: RenderFunction<Omit<RowActionProps<TData>, 'render'>>;
};

export function createRowActionsColumn<TData extends RowData, TValue>(
  rowActions: RowActionProps<TData>[],
  options?: Partial<ColumnDef<TData, TValue>>
): ColumnDef<TData, TValue> {
  return {
    header: () => <span className='sr-only'>row actions</span>,
    id: PredefinedColumnIds.RowActions,
    size: 70,
    cell: ({ row: { original }, table }) => (
      <Flexbox direction='row'>
        {rowActions.map((actionDefinition, i) => {
          const { render, disabled, ...rest } = actionDefinition;
          const actionDisabled = typeof disabled === 'boolean' ? disabled : disabled?.(original);

          return composeOverridableRenderer<Omit<RowActionProps<TData>, 'render'>>(
            { ...rest, disabled: actionDisabled },

            ({ icon, onClick, disabled, 'data-testid': testId }) => (
              <LinkButton
                key={i}
                iconLeft={icon}
                disabled={!!disabled}
                onClick={e => {
                  onClick?.(original, table);
                  e.stopPropagation();
                }}
                data-testid={testId}
              />
            ),
            render
          );
        })}
      </Flexbox>
    ),
    meta: {
      getDisabledRowActionTags: (item: TData) =>
        uniq(
          flatten(
            rowActions.map(({ disabled, tags = [] }) =>
              (isFunction(disabled) ? disabled(item) : disabled) ? tags : []
            )
          )
        ),
    },
    ...options,
  };
}
