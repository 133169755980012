import type { ColumnDef } from '@tanstack/react-table';
import { Icon, Tooltip, TooltipContent } from '@els/biomed-ui';

import { ConfirmationSolidCircle, Info } from 'assets/icons';
import type { Entry } from 'utils/models';
import { MappingStatus } from 'utils/models';

import styles from './ConceptsTableColumns.module.scss';

export function createMappingStatusColumn<T extends Entry>(
  matchedByNameCount: number,
  matchedCount: number,
  options?: Partial<ColumnDef<T>>
): ColumnDef<T> {
  return {
    header: `Database match (${matchedByNameCount}/${matchedCount})`,
    accessorKey: 'mappingStatus',
    meta: { className: 'text-center', 'data-testid': 'mapping-status' },
    cell: ({ row: { original: entry } }) => {
      const { mappingStatus, concept } = entry;
      return mappingStatus === MappingStatus.MATCHED_BY_SYNONYM ? (
        <Tooltip
          placement='top'
          content={
            <TooltipContent size='sm'>
              This protein is an alias to the preferred term in the databases
            </TooltipContent>
          }
        >
          <div data-testid='mappedBySynonym' className={styles.mappedBySynonym}>
            <Icon name={Info} />
            <p>Mapped as {concept.name}</p>
          </div>
        </Tooltip>
      ) : (
        <div data-testid='mappedByName' className={styles.mappedByName}>
          <Icon name={ConfirmationSolidCircle} />
        </div>
      );
    },
    size: 180,
    ...options,
  };
}
