import type { AnchorHTMLAttributes } from 'react';
import { forwardRef } from 'react';
import { type LinkProps, useHref, useLinkClickHandler } from 'react-router-dom';
import { ButtonLink, Link } from '@els/biomed-ui';

export const AppLink = createRouterLink(Link);
export const AppButtonLink = createRouterLink(ButtonLink);

function createRouterLink<P extends AnchorHTMLAttributes<HTMLAnchorElement>>(
  LinkComponent: React.ComponentType<P>
) {
  return forwardRef<HTMLAnchorElement, LinkProps & Omit<P, 'href'>>(
    ({ to, replace = false, onClick, state, target, ...props }, ref) => {
      const href = useHref(to);
      const handleClick = useLinkClickHandler(to, {
        replace,
        state,
        target,
      });

      return (
        <LinkComponent
          ref={ref}
          onClick={event => {
            onClick?.(event);
            if (!event.defaultPrevented) {
              handleClick(event);
            }
          }}
          href={href}
          target={target}
          {...(props as unknown as P)}
        />
      );
    }
  );
}
