import type { VFC } from 'react';
import { useEffect, useState } from 'react';
import { Placeholder, Tag } from '@els/biomed-ui';

import { NavigateDown } from 'assets/icons';
import InfoBanner from 'components/InfoBanner';
import SelectPopup from 'components/SelectPopup';
import { maxSelectedArticles } from 'constants/constant';
import { useMessageBox } from 'containers/MessageBox';
import type { Article, Operator, SavedFilters, SelectedArticles } from 'utils/models';
import { options } from '../ExportControl';
import FreeTextSearch from '../FreeTextSearch';
import ListHeaderActionControls from '../ListHeaderActionControls';
import { ListItem } from '../ListItem';
import { useResultFragmentContext } from '../ResultFragmentContext';

import styles from './ResultList.module.scss';
export interface Props {
  resultItems: Article[];
  total: number;
  showInfoBanner: boolean;
  facets: SavedFilters | null;
  searchQuery?: string;
  searchTerms?: string[];
  hideFreeTextSearch?: boolean;
  operator?: Operator;
  itemsOffset?: number;
  contentType?: string;
  onActionClick: (article: Article, tab: string) => void;
  forceResetSelection?: boolean;
  setClearSearchOptions?: (clearFn: () => void) => void;
  onSelectOperator: (value: string) => void;
  onClear?: () => void;
}

const ResultList: VFC<Props> = ({
  resultItems,
  total,
  onActionClick,
  searchQuery,
  facets,
  operator,
  searchTerms,
  hideFreeTextSearch = false,
  itemsOffset = 0,
  contentType,
  forceResetSelection = false,
  showInfoBanner,
  setClearSearchOptions,
  onSelectOperator,
  onClear,
}) => {
  const [selectedResultItems, setSelectedResultItems] = useState<SelectedArticles>({});
  const { showMessage } = useMessageBox();

  const { selectedArticleInfo, isHighlightingEnabled } = useResultFragmentContext();
  const { article: selectedArticle, tab: selectedArticleTab } = selectedArticleInfo ?? {};

  const notifyError = () => {
    showMessage?.(
      `The maximum of ${maxSelectedArticles} of selected ${contentType}s has been reached.`,
      {
        header: `Maximum selected ${contentType}s`,
      }
    );
  };

  useEffect(() => {
    setSelectedResultItems({});
  }, [searchQuery, facets, forceResetSelection]);

  return (
    <>
      <ListHeaderActionControls
        resultItems={resultItems}
        total={total}
        selectedArticles={selectedResultItems}
        onCheckAll={checked => {
          if (checked) {
            const newItems = resultItems
              .filter(item => !!item.content.title)
              .filter(item => !selectedResultItems[item.id])
              .reduce<SelectedArticles>((obj, item) => {
                obj[item.id] = item.pubInfo?.identifier;
                return obj;
              }, {});

            if (
              Object.keys(selectedResultItems).length + Object.keys(newItems).length >
              maxSelectedArticles
            ) {
              notifyError();
            } else {
              setSelectedResultItems({ ...selectedResultItems, ...newItems });
            }
          } else {
            const newItems = Object.keys(selectedResultItems)
              .filter(key => !resultItems.find(i => i.id === key))
              .reduce<SelectedArticles>((obj, key) => {
                obj[key] = selectedResultItems[key];
                return obj;
              }, {});

            setSelectedResultItems(newItems);
          }
        }}
        onClearAll={() => setSelectedResultItems({})}
      />
      {showInfoBanner && (
        <InfoBanner key={searchTerms?.length || 0} className='mt-1 mb-4'>
          No articles match your additional search keyword. We still show the initial set of
          results.
        </InfoBanner>
      )}
      {!hideFreeTextSearch && (
        <div className={styles.freeTextContentWrapper}>
          <SelectPopup
            options={options}
            offset={[0, -14]}
            trigger={
              <Tag
                variant='button'
                data-testid='free-text-search-drop-down-button'
                className={'mt-10'}
                disabled={!searchTerms?.length}
                size='sm'
                iconLeft={NavigateDown}
              >
                {operator}
              </Tag>
            }
            onClick={onSelectOperator}
          />
          <FreeTextSearch setClearSearchOptions={setClearSearchOptions} onClear={onClear} />
        </div>
      )}

      <ul className='mt-5' data-testid='list-items'>
        {resultItems?.length ? (
          resultItems.map((item, index) => {
            return (
              // Not sure if ID really can be empty. But I see the check for empty title, so probably...
              // Would be nice to check with back-end.
              <li key={item.id || `etm-item-${index}`} data-testid={`listItem-${index}`}>
                <ListItem
                  offset={itemsOffset}
                  index={index}
                  item={item}
                  checked={!!selectedResultItems[item.id]}
                  selected={selectedArticle?.id === item.id}
                  selectedTab={selectedArticleTab}
                  isHighlightingEnabled={isHighlightingEnabled}
                  onCheckItem={(itemId, checked) => {
                    if (checked) {
                      if (Object.keys(selectedResultItems).length >= maxSelectedArticles) {
                        notifyError();
                      } else {
                        const checkedArticle = resultItems.find(i => i.id === itemId);
                        if (checkedArticle) {
                          const identifier = checkedArticle.pubInfo?.identifier;
                          setSelectedResultItems({
                            ...selectedResultItems,
                            ...{ [itemId]: identifier },
                          });
                        }
                      }
                    } else {
                      const nextItems = { ...selectedResultItems };
                      delete nextItems[itemId];

                      setSelectedResultItems(nextItems);
                    }
                  }}
                  onActionClick={onActionClick}
                />
              </li>
            );
          })
        ) : (
          <Placeholder className='mt-4' data-testid='error-message'>
            {
              'Unfortunately, we could not find any articles associated with your last search term. To help refine the search results, please consider removing the last term from your search.'
            }
          </Placeholder>
        )}
      </ul>
    </>
  );
};

export default ResultList;
