import type { VFC } from 'react';
import { useMemo } from 'react';
import type { ColumnDef, RowSelectionState } from '@tanstack/react-table';

import {
  createConceptTypeColumn,
  createDescriptionColumn,
  createMappingStatusColumn,
  createNameColumn,
  createRelationsColumn,
} from 'components/Projects/ConceptsTableColumns';
import Sidebar from 'components/Sidebar';
import { Table } from 'components/Table';
import ConceptDetailsContainer from 'containers/ConceptDetailsContainer';
import { useAnimatedContainer } from 'hooks/useAnimatedContainer';
import type { Concept, Entry, NetworkInfo } from 'utils/models';
import { ConceptActions } from 'utils/models';

interface Props {
  data: NetworkInfo;
  selection: RowSelectionState;
  onSelectionChange?: (selection: RowSelectionState) => void;
}

export const ConceptList: VFC<Props> = ({ data, selection, onSelectionChange }) => {
  const { entries, countMatchedByName, countMatchedBySynonym } = data;
  const matchedCount = countMatchedByName + countMatchedBySynonym;

  const {
    data: selectedConcept,
    open: openConceptDetails,
    containerProps: conceptDetailsSidebar,
  } = useAnimatedContainer<Concept>();

  const selectedCount = Object.keys(selection).length;

  const columns: readonly ColumnDef<Entry>[] = useMemo(
    () => [
      createNameColumn(selectedCount, matchedCount, {
        header: `Biological concepts (${[selectedCount, matchedCount].filter(Boolean).join('/')})`,
      }),
      createMappingStatusColumn(countMatchedByName, matchedCount),
      createRelationsColumn(),
      createConceptTypeColumn(),
      createDescriptionColumn(),
    ],
    [selectedCount, countMatchedByName, matchedCount]
  );

  function performAction({ concept }: Entry, actionType: string) {
    switch (actionType) {
      case ConceptActions.OPEN_DETAILS: {
        openConceptDetails(concept);
        break;
      }
    }
  }

  return (
    <>
      <div className='pt-4'>
        <Table
          index
          selection
          columns={columns}
          items={entries}
          pageSize={entries.length}
          selectionState={selection}
          onSelectionChange={onSelectionChange}
          onAction={performAction}
          getRowId={entry => String(entry.rowNum)}
          stickyHeader
        />
      </div>
      <Sidebar data-testid={`${selectedConcept?.urn}-side-panel`} {...conceptDetailsSidebar}>
        {selectedConcept?.urn && <ConceptDetailsContainer urn={selectedConcept.urn} />}
      </Sidebar>
    </>
  );
};
